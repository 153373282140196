import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import userAppealService from '../../../Service/UserMgr/userAppealService';
import userAppealTypeService from '../../../Service/UserMgr/userAppealTypeService';
import UpdateAppeal from './UpdateAppeal';
import AddAppealType from './AddAppealType';
import UpdateAppealType from './UpdateAppealType';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class UserReports extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 举报列表 ————————————————————————————
    userAppealColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'superiorId',
      title: '上级ID',
      dataIndex: 'superiorId'
    },{
      key: 'userAppealTypeName',
      title: '举报类型名称',
      dataIndex: 'userAppealTypeName',
    },{
      key: 'userAppealImages',
      title: '举报图片',
      dataIndex: 'userAppealImages',
      render: (userAppealImages, row) => {
        if(userAppealImages != null && userAppealImages != "") {
          return <Image
              width={80}
              height={80}
              src={userAppealImages}
            />
        }
      }
    },{
      key: 'fromSouuserAppealDescriptionrce',
      title: '举报描述',
      dataIndex: 'userAppealDescription',
    },{
      key: 'toAvatar',
      title: '被举报用户信息',
      dataIndex: 'toAvatar',
      align: 'center',
      render: (toAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.toUserId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={toAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.toUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.toMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'addTime',
      title: '创建时间',
      dataIndex: 'addTime',
    },{
      key: 'isProcess',
      title: '是否处理',
      dataIndex: 'isProcess',
      render: (isProcess, row) => {
        if(isProcess === 1) {
          return "处理完成"
        } else if(isProcess === 0) {
          return "未处理";
        } 
      }
    },{
      key: 'processTime',
      title: '处理时间',
      dataIndex: 'processTime',
    },{
      key: 'remark',
      title: '备注',
      dataIndex: 'remark',
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateAppealDialog:true, updateAppealRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    userAppealTotal: 0,
    userAppealList: null,
    updateAppealRow: null,
    showUpdateAppealDialog: false,
    userAppealPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 举报列表 ————————————————————————————

    userAppealTypeColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'appealTypeName',
      title: '举报类型名称',
      dataIndex: 'appealTypeName'
    },{
      key: 'isEnable',
      title: '是否启用',
      dataIndex: 'isEnable',
      render: (isEnable, row) => {
        if(isEnable === 1) {
          return "启用"
        } else if(isEnable === 0) {
          return "未启用";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateAppealTypeDialog:true, updateAppealTypeRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    userAppealTypeTotal: 0,
    userAppealTypeList: null,
    updateAppealTypeRow: null,
    addAppealTypeRow: null,
    showUpdateAppealTypeDialog: false,
    showAddAppealTypeDialog: false,
    userAppealTypePageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 举报列表 ————————————————————————————
    this.getUserAppeal();

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 举报列表 ————————————————————————————

  // 获取主播分成方案
  handleGetUserAppealPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var userAppealParam = {
        userId : values.userId,
        toUserId : values.toUserId,
        isProcess : values.isProcess,
        pageParam : pageParam,
      }
      userAppealService.getUserAppealPage(userAppealParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userAppealList: res.retValue.list, userAppealTotal: res.retValue.total});
          }                            
        }
      )
      
    })
  }


  // 用户分页
  userAppealChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          userAppealPageParam: pageParam,
        });

        var userAppealParam = {
          userId : values.userId,
          toUserId : values.toUserId,
          isProcess : values.isProcess,
          pageParam : pageParam,
        }
        userAppealService.getUserAppealPage(userAppealParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({userAppealList: res.retValue.list, userAppealTotal: res.retValue.total});
            }                            
          }
        )
    })
  }

  // 刷新数据
  refreshAppealData=() => {
    //console.log("refreshAppealData");
    let { history } = this.props;

    var userAppealParam = {
      pageParam : this.state.userAppealPageParam,
    }
    userAppealService.getUserAppealPage(userAppealParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userAppealList: res.retValue.list, userAppealTotal: res.retValue.total});
        }                            
      }
    )
  }

  // 隐藏分成方案弹窗
  hideUpdateAppealDialog=() => {
    this.setState({showUpdateAppealDialog: false});
  }


  // ———————————————————— 举报类型配置 ————————————————————————————

  // 用户分页
  userAppealTypeChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          userAppealTypePageParam: pageParam,
        });

        userAppealTypeService.getUserAppealTypePage(pageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({userAppealTypeList: res.retValue.list, userAppealTypeTotal: res.retValue.total});
            }                            
          }
        )
    })
  }

  // 刷新数据
  refreshAppealTypeData=() => {
    //console.log("refreshAppealTypeData");
    let { history } = this.props;
    var pageParam = this.state.userAppealTypePageParam;

    userAppealTypeService.getUserAppealTypePage(pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userAppealTypeList: res.retValue.list, userAppealTypeTotal: res.retValue.total});
        }                            
      }
    )
  }

  // 隐藏添加举报类型
  hideAddAppealTypeDialog=() => {
    this.setState({showAddAppealTypeDialog: false});
  }

  // 隐藏添加举报类型
  hideUpdateAppealTypeDialog=() => {
    this.setState({showUpdateAppealTypeDialog: false});
  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getUserAppeal();
    } else if(key == "2") {
      this.getUserAppealType();
    } 
  }

  /**
   * 举报列表
   */
  getUserAppeal = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var userAppealParam = {
      pageParam : pageParam,
    }
    userAppealService.getUserAppealPage(userAppealParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userAppealList: res.retValue.list, userAppealTotal: res.retValue.total});
        }                            
      }
    )
  }

  /**
   * 举报类型配置
   */
  getUserAppealType = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    userAppealTypeService.getUserAppealTypePage(pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userAppealTypeList: res.retValue.list, userAppealTypeTotal: res.retValue.total});
        }                            
      }
    )
  }



 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/user_reports">用户举报</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 举报列表 ————————————————————————————
            <TabPane tab="举报列表" key="1">

              <Form
                name="recordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="用户UID" name="userId">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="被举报用户UID" name="toUserId">
                          <Input placeholder="被举报用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="是否处理" name="isProcess">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>处理完成</Option>
                              <Option value={0}>未处理</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={14} key={4} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetUserAppealPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userAppealList}
                columns={this.state.userAppealColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userAppealTotal, defaultCurrent: 1, onChange: this.userAppealChangePage}}
              >
              </Table>
              <UpdateAppeal rowData={this.state.updateAppealRow} close={this.hideUpdateAppealDialog} visible={this.state.showUpdateAppealDialog} refreshData={this.refreshAppealData}></UpdateAppeal>

            </TabPane>  

            // ———————————————————— 举报类型配置 ————————————————————————————
            <TabPane tab="举报类型配置" key="2">
              <Form
                name="userAppealTypeListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddAppealTypeDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userAppealTypeList}
                columns={this.state.userAppealTypeColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userAppealTypeTotal, defaultCurrent: 1, onChange: this.userAppealTypeChangePage}}
              >
              </Table>
              <AddAppealType rowData={this.state.addAppealTypeRow} close={this.hideAddAppealTypeDialog} visible={this.state.showAddAppealTypeDialog} refreshData={this.refreshAppealTypeData}></AddAppealType>
              <UpdateAppealType rowData={this.state.updateAppealTypeRow} close={this.hideUpdateAppealTypeDialog} visible={this.state.showUpdateAppealTypeDialog} refreshData={this.refreshAppealTypeData}></UpdateAppealType>

            </TabPane>  
          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default UserReports;