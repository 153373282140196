import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import Service from '../../../Service/index';
import payWayService from '../../../Service/SystemSetting/payWayService';
import aliCashOutService from '../../../Service/SystemSetting/aliCashOutService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class BasicSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={

    // ———————————————————— 支付宝配置 ————————————————————————————
    alipayConfig: null,

    // ———————————————————— 支付宝提现配置 ————————————————————————————
    aliCashOut: null,


    // ———————————————————— 微信配置 ————————————————————————————
    weChatConfig: null,

      
    // ———————————————————— IOS支付配置 ————————————————————————————
    iosConfig: null,

  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 支付宝配置 ————————————————————————————
    this.getPayWay1();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 支付宝配置 ————————————————————————————
  handleAlipayConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var payEquipmentList = values.payEquipment;
        var payEquipmentString = "";
        if(payEquipmentList != null) {
          payEquipmentList.forEach((item, index)=>{
            //console.log("length="+loginTypeList.length + ", index="+ index)
            if(index >= payEquipmentList.length-1) {
              payEquipmentString = payEquipmentString + item;
            } else {
              payEquipmentString = payEquipmentString + item + ",";
            }
          })
        }
        var alipayConfigParam = {
          id: values.id,
          isTip: values.isTip,
          payEquipment: payEquipmentString,
          payChannel: values.payChannel,
          name: values.name,
          sort: values.sort,
        };
        payWayService.updateConfig(alipayConfigParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

 


  // ———————————————————— 支付宝提现配置 ————————————————————————————

  handleAliCashOutSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var cfgAliCashOut = values;
        aliCashOutService.updateConfig(cfgAliCashOut)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


  // ———————————————————— 微信配置 ————————————————————————————
  handleWeChatConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
        var payEquipmentList = values.payEquipment;
        var payEquipmentString = "";
        if(payEquipmentList != null) {
          payEquipmentList.forEach((item, index)=>{
            //console.log("length="+loginTypeList.length + ", index="+ index)
            if(index >= payEquipmentList.length-1) {
              payEquipmentString = payEquipmentString + item;
            } else {
              payEquipmentString = payEquipmentString + item + ",";
            }
          })
        }
        var weChatConfigParam = {
          id: values.id,
          isTip: values.isTip,
          payEquipment: payEquipmentString,
          payChannel: values.payChannel,
          name: values.name,
          appId: values.appId,
          appSecret: values.appSecret,
          partner: values.partner,
          apiPayKey: values.apiPayKey,
          otherParameter: values.otherParameter,          
          sort: values.sort,
        };
        payWayService.updateConfig(weChatConfigParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }
      
  // ———————————————————— IOS支付配置 ————————————————————————————
  
  handleIosConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {

        var cfgPayWay = values;
        payWayService.updateConfig(cfgPayWay)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }
  

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getPayWay1();
    } else if(key == "2") {
      this.getAliCashOut();
    } else if(key == "3") {
      this.getPayWay2();
    } else if(key == "4") {
      this.getPayWay3();
    }
  }

  /**
   * 支付宝配置
   */
  getPayWay1 = () => {
    let { history } = this.props;
    var alipayConfigParam = {
      id: 1
    }
    payWayService.getConfig(alipayConfigParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            var payEquipmentList = res.retValue.payEquipment;
            var payEquipment = payEquipmentList.split(',');
            //console.log("payEquipment=" + JSON.stringify(payEquipment));
  
            this.formRef.current.setFieldsValue({
              isTip: res.retValue.isTip,
              payEquipment: payEquipment,
              payChannel: res.retValue.payChannel,
              name: res.retValue.name,
              sort: res.retValue.sort,
            });        
          }                  
          
        }
      ) 
  }

  /**
   * 支付宝提现配置
   */
  getAliCashOut = () => {
    let { history } = this.props;
    aliCashOutService.getConfig()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            isOpen: res.retValue.isOpen,
            payerName: res.retValue.payerName,
            appId: res.retValue.appId,
            appPrivateKey: res.retValue.appPrivateKey,
            certPath: res.retValue.certPath,
            alipayPublicCertPath: res.retValue.alipayPublicCertPath,
            rootCertPath: res.retValue.rootCertPath,
          });  
          this.setState({aliCashOut: res.retValue})
        }                  
      }
    )  
  }

  /**
   * 微信配置
   */
  getPayWay2 = () => {
    let { history } = this.props;
    var weChatConfigParam = {
      id: 2
    }
    payWayService.getConfig(weChatConfigParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            var payEquipmentList = res.retValue.payEquipment;
            var payEquipment = payEquipmentList.split(',');
            //console.log("payEquipment=" + JSON.stringify(payEquipment));
  
            this.formRef3.current.setFieldsValue({
              isTip: res.retValue.isTip,
              payEquipment: payEquipment,
              payChannel: res.retValue.payChannel,
              name: res.retValue.name,
              appId: res.retValue.appId,
              appSecret: res.retValue.appSecret,
              partner: res.retValue.partner,
              apiPayKey: res.retValue.apiPayKey,
              otherParameter: res.retValue.otherParameter,
              sort: res.retValue.sort,
            });  

            var weChatConfig = {
              isTip: res.retValue.isTip,
              payEquipment: payEquipment,
              payChannel: res.retValue.payChannel,
              name: res.retValue.name,
              appId: res.retValue.appId,
              appSecret: res.retValue.appSecret,
              partner: res.retValue.partner,
              apiPayKey: res.retValue.apiPayKey,
              otherParameter: res.retValue.otherParameter,
              sort: res.retValue.sort,
            }
            this.setState({weChatConfig: weChatConfig})
          }                  
      
        }
      )  
  }

  /**
   * IOS支付配置
   */
  getPayWay3 = () => {
    let { history } = this.props;
    var alipayConfigParam = {
      id: 3
    }
    payWayService.getConfig(alipayConfigParam)
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            isTip: res.retValue.isTip,
            payChannel: res.retValue.payChannel,
            name: res.retValue.name,
          });
          this.setState({iosConfig: res.retValue})
        }                  
      }
    ) 
  }

 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/payment_settings">支付设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // —————————————————————— 支付宝配置 ————————————————————————
            <TabPane tab="支付宝配置" key="1">
              <Form
                  name="AlipayConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.AlipayConfig}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="是否开启支付" name="isTip" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="isTip" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="支付设备" name="payEquipment" labelCol={{span:5}}  style={{ width: 840 }}>
                        <Checkbox.Group>
                          <Row gutter={10}>
                            <Col span={5}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 190 }}>
                                安卓
                              </Checkbox>
                            </Col>
                            <Col span={5}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 190 }}>
                                IOS
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>

                      <Form.Item label="显示图标" name="payChannel" labelCol={{span:10}} style={{ width: 420 }} initialValue={1}>
                          <Radio.Group name="payChannel" defaultValue={1}>
                            <Radio value={1}>支付宝</Radio>
                            <Radio value={2}>微信</Radio>
                            <Radio value={3}>ios内购</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="渠道名称" labelCol={{span:10}} name="name" style={{ width: 420 }}>
                          <Input placeholder="渠道名称" />
                      </Form.Item>

                      <Form.Item label="排序" labelCol={{span:10}} name="sort" style={{ width: 420 }}>
                          <Input placeholder="排序" />
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAlipayConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 支付宝提现配置 ————————————————————————
            <TabPane tab="支付宝提现配置" key="2">
              <Form
                  name="AliCashOutForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.aliCashOut}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="是否开启" name="isOpen" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="isOpen" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Row>
                        <Form.Item label="付款方" labelCol={{span:10}} name="payerName" style={{ width: 420 }}>
                            <Input placeholder="付款方" />
                        </Form.Item>
                        填写您的公司简称或APP名称
                      </Row>

                      <Form.Item label="APPID" labelCol={{span:10}} name="appId" style={{ width: 420 }}>
                          <Input placeholder="APPID" />
                      </Form.Item>

                      <Form.Item label="开发者应用私钥" labelCol={{span:5}} name="appPrivateKey" style={{ width: 840 }}>
                          <TextArea style={{ height: 200 }} />
                      </Form.Item>

                      <Form.Item label="开发者应用公钥证书路径" labelCol={{span:5}} name="certPath" style={{ width: 840 }}>
                          <Input placeholder="开发者应用公钥证书路径" />
                      </Form.Item>

                      <Form.Item label="支付宝公钥证书文件路径" labelCol={{span:5}} name="alipayPublicCertPath" style={{ width: 840 }}>
                          <Input placeholder="支付宝公钥证书文件路径" />
                      </Form.Item>

                      <Form.Item label="支付宝CA根证书文件路径" labelCol={{span:5}} name="rootCertPath" style={{ width: 840 }}>
                          <Input placeholder="支付宝CA根证书文件路径" />
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAliCashOutSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 微信配置 ————————————————————————
            <TabPane tab="微信配置" key="3">
              <Form
                  name="WeChatConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.weChatConfig}
                >
                      <Form.Item label="id" name="id" initialValue={2} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="是否开启支付" name="isTip" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="isTip" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="支付设备" name="payEquipment" labelCol={{span:5}}  style={{ width: 840 }}>
                        <Checkbox.Group>
                          <Row gutter={10}>
                            <Col span={5}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 190 }}>
                                安卓
                              </Checkbox>
                            </Col>
                            <Col span={5}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 190 }}>
                                IOS
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>

                      <Form.Item label="显示图标" name="payChannel" labelCol={{span:10}} style={{ width: 420 }} initialValue={2}>
                          <Radio.Group name="payChannel" defaultValue={2}>
                            <Radio value={1}>支付宝</Radio>
                            <Radio value={2}>微信</Radio>
                            <Radio value={3}>ios内购</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="渠道名称" labelCol={{span:10}} name="name" style={{ width: 420 }}>
                          <Input placeholder="渠道名称" />
                      </Form.Item>

                      <Form.Item label="APPID（开放平台）" labelCol={{span:10}} name="appId" style={{ width: 420 }}>
                          <Input placeholder="APPID（开放平台）" />
                      </Form.Item>

                      <Form.Item label="appSecret（开放平台）" labelCol={{span:5}} name="appSecret" style={{ width: 840 }}>
                          <Input placeholder="appSecret（开放平台）" />
                      </Form.Item>

                      <Form.Item label="商户号（商户平台）" labelCol={{span:5}} name="partner" style={{ width: 840 }}>
                          <Input placeholder="商户号（商户平台）" />
                      </Form.Item>

                      <Form.Item label="API密钥（商户平台）" labelCol={{span:5}} name="apiPayKey" style={{ width: 840 }}>
                          <Input placeholder="API密钥（商户平台）" />
                      </Form.Item>

                      <Form.Item label="p12证书地址" labelCol={{span:5}} name="otherParameter" style={{ width: 840 }}>
                          <Input placeholder="p12证书地址" />
                      </Form.Item>

                      <Form.Item label="排序" labelCol={{span:10}} name="sort" style={{ width: 420 }}>
                          <Input placeholder="排序" />
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleWeChatConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— IOS支付配置 ————————————————————————
            <TabPane tab="IOS支付配置" key="4">
              <Form
                  name="IosConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.iosConfig}
                >
                      <Form.Item label="id" name="id" initialValue={3} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="是否开启支付" name="isTip" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="isTip" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="显示图标" name="payChannel" labelCol={{span:10}} style={{ width: 420 }} initialValue={3}>
                          <Radio.Group name="payChannel" defaultValue={3}>
                            <Radio value={1}>支付宝</Radio>
                            <Radio value={2}>微信</Radio>
                            <Radio value={3}>ios内购</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="渠道名称" labelCol={{span:10}} name="name" style={{ width: 420 }}>
                          <Input placeholder="渠道名称" />
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleIosConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default BasicSettings;