import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class MessageSettings extends Component {
  formRef = React.createRef();
  state={

    // ———————————————————— 通话设置 ————————————————————————————
    dynamicSettings: null,
  

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 通话设置 ————————————————————————————

    adminLiveConfigService.getPrivateletter()
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          //console.log("retValue=" + JSON.stringify(res.retValue));
          this.formRef.current.setFieldsValue({
            analogMessage: res.retValue.analogMessage,
            analogMessageCount: res.retValue.analogMessageCount,
            analogMessageInterval: res.retValue.analogMessageInterval,
            isNobleChat: res.retValue.isNobleChat,
            userTalkUser: res.retValue.userTalkUser,
            anchorTalkAnchor: res.retValue.anchorTalkAnchor,
            anchorTalkUser: res.retValue.anchorTalkUser,
            manTalkMan: res.retValue.manTalkMan,
            femaleTalkFemale: res.retValue.femaleTalkFemale,
            femaleTalkFree: res.retValue.femaleTalkFree,
            anchorTalkFree: res.retValue.anchorTalkFree,
            privateCoin: res.retValue.privateCoin,
            privateChatDeductionTips: res.retValue.privateChatDeductionTips,
            privateShowRedPack: res.retValue.privateShowRedPack,
            groupShowRedPack: res.retValue.groupShowRedPack,
            femaleAnchorLookCity: res.retValue.femaleAnchorLookCity,
          })
        }
      )

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 通话设置 ————————————————————————————



  /**
   * 一对一
   */
   handlePrivateletterSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var privateletterParam = values;

        adminLiveConfigService.updatePrivateletter(privateletterParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/message_settings">私信设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
            <Form
              name="messageSettingsForm"
              layout="horizontal"
              labelCol={{span:4}}
              wrapperCol={{span: 20}}
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 100px'}}
            >

                  <Form.Item label="模拟消息" name="analogMessage" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="analogMessage" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="模拟消息每天发送次数" name="analogMessageCount" labelCol={{span:10}}  style={{ width: 450 }}>
                    <InputNumber />
                  </Form.Item>

                  <Form.Item label="模拟消息间隔(秒)" name="analogMessageInterval" labelCol={{span:10}}  style={{ width: 450 }}>
                    <InputNumber />
                  </Form.Item>

                  <Form.Item label="贵族才能私聊" name="isNobleChat" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="isNobleChat" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="用户和用户可以私信" name="userTalkUser" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="userTalkUser" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="主播和主播可以私信" name="anchorTalkAnchor" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="anchorTalkAnchor" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="主播和用户可以私信" name="anchorTalkUser" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="anchorTalkUser" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="男性和男性可以私信" name="manTalkMan" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="manTalkMan" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="女性和女性可以私信" name="femaleTalkFemale" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="femaleTalkFemale" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="女性发私信免费" name="femaleTalkFree" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="femaleTalkFree" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="主播发私信免费" name="anchorTalkFree" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="anchorTalkFree" defaultValue={0}>
                      <Radio value={0}>开启</Radio>
                      <Radio value={1}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="私聊扣费金额" name="privateCoin" labelCol={{span:10}}  style={{ width: 450 }}>
                    <InputNumber />
                  </Form.Item>

                  <Form.Item label="私聊扣费提示" name="privateChatDeductionTips" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="私聊扣费提示" />
                  </Form.Item>

                  <Form.Item label="私聊红包" name="privateShowRedPack" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="privateShowRedPack" defaultValue={0}>
                      <Radio value={0}>显示</Radio>
                      <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="群聊红包" name="groupShowRedPack" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="groupShowRedPack" defaultValue={0}>
                      <Radio value={0}>显示</Radio>
                      <Radio value={1}>隐藏</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="女主播显示用户位置" name="femaleAnchorLookCity" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Radio.Group name="femaleAnchorLookCity" defaultValue={0}>
                      <Radio value={0}>关闭</Radio>
                      <Radio value={1}>开启</Radio>
                    </Radio.Group>
                  </Form.Item>

            </Form>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.handlePrivateletterSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
              <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
            </div>

            
        </StickyContainer>
        
      </div>
    );
  }
}
export default MessageSettings;