import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import appUserService from '../../../Service/UserMgr/appUserService';
import appLogOffUserService from '../../../Service/UserMgr/appLogOffUserService';
import appTabInfoService from '../../../Service/UserMgr/appTabInfoService';
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';
import anchorCheckConfigService from '../../../Service/UserMgr/anchorCheckConfigService';
import userDataReviewService from '../../../Service/UserMgr/userDataReviewService';
import AddUser from './AddUser';
import EditUser from './EditUser';
import ResetPwd from './ResetPwd';
import DisableUser from './DisableUser';
import EnableUser from './EnableUser';
import AddTag from './AddTag';
import EditTag from './EditTag';
import EditUserDataReview from './EditUserDataReview';
import moment from 'moment'



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class OrdinaryUsers extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  formRef7 = React.createRef();
  state={
    /*
    * 用户列表
    */
    editUserRow: null,             // 当前编辑产品信息 
    showEditUserDialog: false,     // 修改产品对话框
    userSelectRowKeys:[],
    showAddUserDialog: false,      // 添加产品对话框
    usertotal: 0,
    userlist: null,
    params: {pageNum:1, pageSize: 10},
    userColumns: [{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
		  align: 'center',
      render: (avatar, row) => {
        var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
                <div>{row.userid}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
                <Image width={55} height={55} src={avatar} />
              </div>
              <div style={{ float: 'left' }}>
                <div style={{ marginTop: '3px'}}>{row.username}</div>
                <div style={{ marginTop: '3px'}}>{row.mobile}</div>
              </div>
            </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '用户性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'totalCharge',
      title: '累计充值金额',
      dataIndex: 'totalCharge'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'pid',
      title: '上级推荐人',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'source',
      title: '注册来源',
      dataIndex: 'source'
    },{
      key: 'phoneFirm',
      title: '手机厂商',
      dataIndex: 'phoneFirm'
    },{
      key: 'phoneModel',
      title: '手机型号',
      dataIndex: 'phoneModel'
    },{
      key: 'appVersion',
      title: 'APP版本',
      dataIndex: 'appVersion'
    },{
      key: 'appVersionCode',
      title: 'APP版本code',
      dataIndex: 'appVersionCode'
    },{
      key: 'ipaddr',
      title: '最后登录IP',
      dataIndex: 'ipaddr'
    },{
      key: 'lastLoginTime',
      title: '最后登录时间',
      dataIndex: 'lastLoginTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus',
      render: (onlineStatus, row) => {
        if(onlineStatus === 0) {
          return "离线"
        } else if(onlineStatus === 1) {
          return "在线";
        }
      }
    },{
      key: 'status',
      title: '账号状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "正常"
        } else if(status === 1) {
          return "禁用";
        }
      }
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status == 0) {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showEditUserDialog:true, editUserRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteUser(row.userid);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要注销吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >注销用户</Button>
              </Popconfirm>
              <Button 
                onClick={() => this.setState({showResetPwdDialog:true, resetPwdRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">重置密码 
              </Button>
              <Button 
                onClick={() => this.setState({showDisableUserDialog:true, disableUserRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">禁用 
              </Button>
            </div>
          )
        } else {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showEditUserDialog:true, editUserRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteUser(row.userid);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要注销吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >注销用户</Button>
              </Popconfirm>
              <Button 
                onClick={() => this.setState({showResetPwdDialog:true, resetPwdRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">重置密码 
              </Button>
              <Button 
                onClick={() => this.getDisableUserInfo(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">启用
              </Button>
            </div>
          )
        }
        
      }
    }],
    resetPwdRow: null,
    showResetPwdDialog: false,
    disableUserRow: null,
    showDisableUserDialog: false,
    enableUserRow: null,
    showEnableUserDialog: false,

    // ———————————————————— 用户注销列表 ————————————————————————————
    logOffColumns: [{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'userOldAvatar',
      title: '用户以前信息',
      dataIndex: 'userOldAvatar',
      align: 'center',
      render: (userOldAvatar, row) => {
        var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
                <div>{row.userOldId}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
                <Image width={55} height={55} src={userOldAvatar} />
              </div>
              <div style={{ float: 'left' }}>
                <div style={{ marginTop: '3px'}}>{row.userOldName}</div>
                <div style={{ marginTop: '3px'}}>{row.mobile}</div>
              </div>
            </>
        );
        return info;
      }
    },{
      key: 'regType',
      title: '注册类型',
      dataIndex: 'regType'
    },{
      key: 'openid',
      title: '三方绑定ID',
      dataIndex: 'openid'
    },{
      key: 'role',
      title: '角色	',
      dataIndex: 'role',
      render: (role, row) => {
        if(role === 0) {
          return "普通用户"
        } else if(role === 1) {
          return "主播";
        }
      }
    },{
      key: 'pid',
      title: '推荐人ID',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '代理人ID',
      dataIndex: 'agentId'
    },{
      key: 'guildId',
      title: '所属公会ID',
      dataIndex: 'guildId'
    },{
      key: 'createTime',
      title: '注册时间',
      dataIndex: 'createTime'
    },{
      key: 'addTime',
      title: '注销时间',
      dataIndex: 'addTime'
    }],
    logOfftotal: 0,
    logOfflist: null,

     // ———————————————————— 禁用列表 ————————————————————————————
     disableUserColumns: [{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
                <div>{row.userid}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
                <Image width={55} height={55} src={avatar} />
              </div>
              <div style={{ float: 'left' }}>
                <div style={{ marginTop: '3px'}}>{row.username}</div>
                <div style={{ marginTop: '3px'}}>{row.mobile}</div>
              </div>
            </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '用户性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'role',
      title: '角色',
      dataIndex: 'role',
      render: (role, row) => {
        if(role === 0) {
          return "普通用户"
        } else if(role === 1) {
          return "主播";
        }
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'totalCharge',
      title: '累计充值金额',
      dataIndex: 'totalCharge'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'pid',
      title: '上级推荐人',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'source',
      title: '注册来源',
      dataIndex: 'source'
    },{
      key: 'phoneFirm',
      title: '手机厂商',
      dataIndex: 'phoneFirm'
    },{
      key: 'phoneModel',
      title: '手机型号',
      dataIndex: 'phoneModel'
    },{
      key: 'appVersion',
      title: 'APP版本',
      dataIndex: 'appVersion'
    },{
      key: 'appVersionCode',
      title: 'APP版本code',
      dataIndex: 'appVersionCode'
    },{
      key: 'ipaddr',
      title: '最后登录IP',
      dataIndex: 'ipaddr'
    },{
      key: 'lastLoginTime',
      title: '最后登录时间',
      dataIndex: 'lastLoginTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus'
    },{
      key: 'status',
      title: '账号状态',
      dataIndex: 'status'
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getDisableUserInfo(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">启用
              </Button>
            </div>
          )        
      }
    }],
    disableUserTotal: 0,
    disableUserList: null,

    // ———————————————————— 兴趣标签 ————————————————————————————
    editTagRow: null,
    tagColumns: [{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '名称',
      dataIndex: 'name'
    },{
      key: 'fontColor',
      title: '字体颜色',
      dataIndex: 'fontColor',
      render: (fontColor, row) => {
         return <span style={{color: fontColor}}>{fontColor}</span>
      }
    },{
      key: 'tabTypeId',
      title: '类型',
      dataIndex: 'tabTypeId',
      render: (tabTypeId, row) => {
        if(tabTypeId === 3) {
          return "自我描述"
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showEditTagDialog:true, editTagRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteTag(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    tagTotal: 0,
    tagList: null,
    showAddTagDialog: false,
    showEditTagDialog: false,

    // ———————————————————— 用户距离 ———————————————————————————— 
    userDistance: null,

    // ———————————————————— 用户资料审核开关 ———————————————————————————— 
    userCheckConfig: null,

    // ———————————————————— 用户资料审核列表 ———————————————————————————— 
    editUserDataReviewRow: null,
    showEditUserDataReviewDialog: false,
    userDataReviewColumns: [{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '用户头像',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'dataType',
      title: '资料类型',
      dataIndex: 'dataType',
      render: (dataType, row) => {
        if(dataType === 1) {
          return "用户头像"
        } else if(dataType === 2) {
          return "用户资料图片";
        } else if(dataType === 3) {
          return "用户名称";
        } else if(dataType === 4) {
          return "个性签名";
        }
      }
    },{
      key: 'oldUserContent',
      title: '	旧的用户资料',
      dataIndex: 'oldUserContent',
      render: (oldUserContent, row) => {
        if(row.dataType === 1 || row.dataType === 2) {
          if(oldUserContent != null && oldUserContent != "") {
            return <Avatar shape="square" size={55} src={oldUserContent}></Avatar>
          }
        } else if(row.dataType === 3 || row.dataType === 4) {
          return oldUserContent;
        }
      }
    },{
      key: 'approvalStatus',
      title: '审核状态',
      dataIndex: 'approvalStatus',
      render: (approvalStatus, row) => {
        if(approvalStatus === -1) {
          return "审核拒绝"
        } else if(approvalStatus === 0) {
          return "审核中";
        } else if(approvalStatus === 1) {
          return "审核通过";
        }
      }
    },{
      key: 'upUserContent',
      title: '更新的用户资料',
      dataIndex: 'upUserContent',
      render: (upUserContent, row) => {
        if(row.dataType === 1 || row.dataType === 2) {
          if(upUserContent != null && upUserContent != "") {
            return <Avatar shape="square" size={55} src={upUserContent}></Avatar>
          }
        } else if(row.dataType === 3 || row.dataType === 4) {
          return upUserContent;
        }
      }
    },{
      key: 'addTime',
      title: '添加时间',
      dataIndex: 'addTime',
    },{
      key: 'upTime',
      title: '更新时间',
      dataIndex: 'upTime',
    },{
      key: 'checkTime',
      title: '审核时间',
      dataIndex: 'checkTime',
    },{
      key: 'adminCheckName',
      title: '审核人',
      dataIndex: 'adminCheckName',
    },{
      key: 'checkRemark',
      title: '审核备注',
      dataIndex: 'checkRemark',
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showEditUserDataReviewDialog:true, editUserDataReviewRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">审核
              </Button>
            </div>
          )        
      }
    }],
    userDataReviewTotal: 0,
    userDataReviewList: null,
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // 用户列表
    this.getAppUser1();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  /**
   * 行选择操作
   */
  productRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows);
      this.setState({userSelectRowKeys: selectedRowKeys});
    }
  }

  // 获取普通用户
  handleGetOrdinaryUser=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      var userid = values.userid;
      var mobile = values.mobile;
      var username = values.username;
      var role = values.role;
      var onlineStatus = values.onlineStatus;
      var status = values.state;
      var pid = values.pid;
      var coinStart = values.coinStart;
      var coinEnd = values.coinEnd;
      var ipaddr = values.ipaddr;
      appUserService.getOrdinaryUserPage(userid, mobile, username, role, 
        onlineStatus, status, pid, coinStart, coinEnd, ipaddr, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
          }                            
        }
      )
       
    })
  }

  // 用户分页
  userChangePage=(page, pageSize) => {
      let { history } = this.props;
      //console.log("changePage 进入");
      this.formRef.current.validateFields()
      .then(values => {
          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }
          appUserService.getOrdinaryUserPage(values.userid, values.mobile, values.username, values.role, 
            values.onlineStatus, values.status, values.pid, values.coinStart, values.coinEnd, values.ipaddr, pageParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("查询失败,"+res.message);  
              } else {
                this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
              }                            
            }
          )
      });

    }

  // 关闭添加用户弹框
  hideAddUserDialog=() => {
    this.setState({showAddUserDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditUserDialog=() => {
    this.setState({showEditUserDialog: false});
  }

  // 关闭密码重置弹框
  hideResetPwdDialog=() => {
    this.setState({showResetPwdDialog: false});
  }

  // 关闭禁用弹窗
  hideDisableUserDialog=() => {
    this.setState({showDisableUserDialog: false});
  }

  // 启动禁用弹窗
  hideEnableUserDialog=() => {
    this.setState({showEnableUserDialog: false});
  }

  // 刷新数据
  refreshUserData=() => {
    //console.log("refreshUserData进入");
    let { history } = this.props;
      var pageParam = {
        pageNum: 1,
        pageSize: 10,
      }
      appUserService.getOrdinaryUserPage(null, null, null, null, 
        null, null, null, null, null, null, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
          }                            
        }
      )
  }

  // 根据Id删除用户
  deleteUser=(userid) => {
      let { history } = this.props;
      let admin = GetLoginUserInfo();
      let appUser = {
         userid : userid,
         adminid : admin.userId,
      };
      appUserService.deleteUser(appUser)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshUserData();
          message.success('删除成功！');
        }                            
        
      })
  }

  getDisableUserInfo=(userid) => {
   //console.log("getDisableUserInfo 进入，userid="+userid);
   let { history } = this.props;
    var appUser = {
      userid : userid
    }
    appUserService.getDisableUserInfo(appUser)
    .then(res => {
      //console.log("getDisableUserInfo res="+JSON.stringify(res));
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({showEnableUserDialog:true, enableUserRow: res.retValue})
      }                            
      
    });
  }

  // ———————————————————————————— 用户注销 ——————————————————————————————————

  // 获取普通用户
  handleGetLogOffUserPage=() =>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      appLogOffUserService.getLogOffUserPage(values.userOldId, values.mobile, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({logOfflist: res.retValue.list, logOfftotal: res.retValue.total});
          }                            
        }
      )
       
    })
  }


  // 用户分页
  logOffUserChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }
        appLogOffUserService.getLogOffUserPage(values.userOldId, values.mobile, pageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({logOfflist: res.retValue.list, logOfftotal: res.retValue.total});
            }                            
          }
        )
    });

  }


  // ———————————————————————————— 禁用列表 ——————————————————————————————————

  // 获取禁用用户
  handleGetDisableUserPage=() =>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      appUserService.getOrdinaryUserPage(values.userid, values.mobile, values.username, values.role, 
        null, 1, values.pid, null, null, null, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
          }                            
        }
      )
       
    })
  }


  // 用户分页
  disableUserChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }
        appUserService.getOrdinaryUserPage(values.userid, values.mobile, values.username, values.role, 
          null, 1, values.pid, null, null, null, pageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
            }                            
          }
        )
    });


  }


  // ———————————————————————————— 兴趣标签 ——————————————————————————————————

  // 获取普通用户
  handleGetTagPage=() =>{
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      var tabInfoParam = {
        tabTypeId: values.tabTypeId,
        name: values.name,
        pageParam: pageParam,
      }
      appTabInfoService.getTagPage(tabInfoParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({tagList: res.retValue.list, tagTotal: res.retValue.total});
          }                            
        }
      )
       
    })
  }


  // 兴趣标签分页
  tagChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {

        var pageParam = {
            pageNum: page,
            pageSize: pageSize,
        }
        var tabInfoParam = {
          pageParam: pageParam
        }
        appTabInfoService.getTagPage(tabInfoParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({tagList: res.retValue.list, tagTotal: res.retValue.total});
            }                            
          }
        )

    });

  }


  // 根据Id删除标签
  deleteTag=(id) => {
      let { history } = this.props;
      let tabInfoParam = {
        id : id
      };
      appTabInfoService.deleteTab(tabInfoParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshTagData();
          message.success('删除成功！');
        }                            
        
      })
  }

  // 刷新数据
  refreshTagData=() => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var tabInfoParam = {
      pageParam: pageParam,
    }
    appTabInfoService.getTagPage(tabInfoParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({tagList: res.retValue.list, tagTotal: res.retValue.total});
        }                            
      }
    )
  }

  // 关闭添加用户弹框
  hideAddTagDialog=() => {
    this.setState({showAddTagDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditTagDialog=() => {
    this.setState({showEditTagDialog: false});
  }

  // ———————————————————————————— 距离配置 ——————————————————————————————————

    // 提交操作
    handleUserDistanceSubmit =() => {
      let { history } = this.props;
      this.formRef5.current.validateFields()
      .then(values => {
          let userDistanceParam = values;

          adminLiveConfigService.updateUserDistance(userDistanceParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                //this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
              }
          })
      })
  }

  // ———————————————————————————— 用户资料审核开关 ——————————————————————————————————

  // 提交操作
  handleUserCheckConfigSubmit =() => {
      let { history } = this.props;
      this.formRef6.current.validateFields()
      .then(values => {
          let userCheckConfigParam = values;
          console.log(JSON.stringify(userCheckConfigParam));

          anchorCheckConfigService.updateUserCheckConfig(userCheckConfigParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                //this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                //this.hanldeCloseModal();
              }
          })
      })
  }



  // ———————————————————————————— 用户资料审核列表 ——————————————————————————————————

  // 获取用户审核列表
  handleGetUserDataReviewList=() =>{
    let { history } = this.props;
    this.formRef7.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      var upTimeStart = null;
      var upTimeEnd = null;
      if(values.upTime != null) {
        upTimeStart = values.upTime[0].format('YYYY-MM-DD') + " 00:00:00";
        upTimeEnd = values.upTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }
      var userDataReviewParam ={
        userId: values.userId,
        dataType: values.dataType,
        approvalStatus: values.approvalStatus,
        upTimeStart: upTimeStart,
        upTimeEnd: upTimeEnd,
        pageParam: pageParam,
      }
      userDataReviewService.getUserDataReviewList(userDataReviewParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userDataReviewList: res.retValue.list, userDataReviewTotal: res.retValue.total});
          }                            
        }
      )
       
    })
  }

  // 用户分页
  userDataReviewChangePage=(page, pageSize) => {
      //console.log("changePage 进入");
      let { history } = this.props;
      let key = this.state.searchKey;
      this.formRef7.current.validateFields()
      .then(values => {
          //console.log("userDataReviewChangePage values="+JSON.stringify(values));

          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }
          var upTimeStart = null;
          var upTimeEnd = null;
          if(values.upTime != null) {
            upTimeStart = values.upTime[0].format('YYYY-MM-DD') + " 00:00:00";
            upTimeEnd = values.upTime[1].format('YYYY-MM-DD' + " 23:59:59");
          }
          
          var userDataReviewParam ={
            userId: values.userId,
            dataType: values.dataType,
            approvalStatus: values.approvalStatus,
            upTimeStart: upTimeStart,
            upTimeEnd: upTimeEnd,
            pageParam: pageParam,
          }
          userDataReviewService.getUserDataReviewList(userDataReviewParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("查询失败,"+res.message);  
              } else {
                this.setState({userDataReviewList: res.retValue.list, userDataReviewTotal: res.retValue.total});
              }                            
            }
          )
      });
    }

  // 关闭编辑用户弹框
  hideEditUserDataReviewDialog=() => {
    this.setState({showEditUserDataReviewDialog: false});
  }

  // 刷新数据
  refreshUserDataReviewData=() => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    }
    var userDataReviewParam ={
      pageParam: pageParam,
    }
    userDataReviewService.getUserDataReviewList(userDataReviewParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userDataReviewList: res.retValue.list, userDataReviewTotal: res.retValue.total});
        }                            
      }
    )
  }


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getAppUser1();
    } else if(key == "2") {
      this.getAppLogOffUser();
    } else if(key == "3") {
      this.getAppUser2();
    } else if(key == "4") {
      this.getAppTabInfo();
    } else if(key == "5") {
      this.getAdminLiveConfig();
    } else if(key == "6") {
      this.getAnchorCheckConfig();
    } else if(key == "7") {
      this.getUserDataReview();
    }
  }

  /**
   * 用户列表
   */
  getAppUser1 = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var userid = null;
    var mobile = null;
    var username = null;
    var role = null;
    var onlineStatus = null;
    var status = null;
    var pid = null;
    var coinStart = null;
    var coinEnd = null;
    var ipaddr = null;
    appUserService.getOrdinaryUserPage(userid, mobile, username, role, 
      onlineStatus, status, pid, coinStart, coinEnd, ipaddr, pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
        }                            
      }
    )
  }

  /**
   * 用户注销
   */
  getAppLogOffUser = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    appLogOffUserService.getLogOffUserPage(null, null, pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({logOfflist: res.retValue.list, logOfftotal: res.retValue.total});
        }                            
      }
    )
  }

  /**
   * 禁用列表
   */
  getAppUser2 = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    appUserService.getOrdinaryUserPage(null, null, null, null, 
      null, 1, null, null, null, null, pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
        }                            
      }
    )
  }

  /**
   * 兴趣标签列表
   */
  getAppTabInfo = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var tabInfoParam = {
      pageParam: pageParam,
    }
    appTabInfoService.getTagPage(tabInfoParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({tagList: res.retValue.list, tagTotal: res.retValue.total});
        }                            
      }
    )
  }

  /**
   * 距离配置
   */
  getAdminLiveConfig = () => {
    let { history } = this.props;
    adminLiveConfigService.getUserDistance()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            maleUserShowAddress: res.retValue.maleUserShowAddress,
            femaleUserShowAddress: res.retValue.femaleUserShowAddress,
            maleAnchorShowAddress: res.retValue.maleAnchorShowAddress,
            femaleAnchorShowAddress: res.retValue.femaleAnchorShowAddress,
            showAddressStart: res.retValue.showAddressStart,
            showAddressEnd: res.retValue.showAddressEnd,
          });
          this.setState({userDistance: res.retValue});
        }                            
      }
    )
  }

  /**
   * 用户资料审核开关
   */
  getAnchorCheckConfig = () => {
    let { history } = this.props;
    anchorCheckConfigService.getUserCheckConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef6.current.setFieldsValue({
            userAvatarReview: res.retValue.userAvatarReview,
            userPortraitReview: res.retValue.userPortraitReview,
            userNameReview: res.retValue.userNameReview,
            userSignatureReview: res.retValue.userSignatureReview,
          });
          this.setState({userCheckConfig: res.retValue});
        }                            
      }
    )
  }

  /**
   * 用户资料审核列表
   */
  getUserDataReview = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var userDataReviewParam ={
      pageParam: pageParam,
    }
    userDataReviewService.getUserDataReviewList(userDataReviewParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userDataReviewList: res.retValue.list, userDataReviewTotal: res.retValue.total});
        }                            
      }
    )
  }


  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/ordinary_users">普通用户</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 用户列表 ——————————————————————
            <TabPane tab="用户列表" key="1">
              
              <Form
                name="userListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="手机号码" name="mobile">
                          <Input placeholder="手机号码" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="昵称" name="username">
                          <Input placeholder="昵称" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="角色" name="role">
                          <Select style={{ width: 220 }} defaultValue="全部">
                            <Option >全部</Option>
                            <Option value={0}>普通用户</Option>
                            <Option value={1}>主播用户</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={5}>
                      <Form.Item label="账号状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>正常</Option>
                              <Option value={1}>禁用</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                      <Form.Item label="在线状态" name="onlineStatus">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>离线</Option>
                              <Option value={1}>在线</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={16} key={7}>
                      <Row >
                        <Form.Item label="金币余额" name="coinStart">
                            <Input placeholder="金币开始" />
                        </Form.Item>
                        -
                        <Form.Item name="coinEnd">
                            <Input placeholder="金币结束" />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col span={8} key={8}>
                      <Form.Item label="上级id" name="pid">
                          <Input placeholder="上级id" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={9}>
                      <Form.Item label="最后登录ip" name="ipaddr">
                          <Input placeholder="最后登录ip" />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetOrdinaryUser} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button type="primary" onClick={() => this.setState({showAddUserDialog:true})}  >
                      + 新增用户
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.state.userlist}
                columns={this.state.userColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.usertotal, defaultCurrent: 1, onChange: this.userChangePage}}
              >
              </Table>
              <AddUser close={this.hideAddUserDialog} visible={this.state.showAddUserDialog} refreshData={this.refreshUserData}></AddUser>
              <EditUser rowData={this.state.editUserRow} close={this.hideEditUserDialog} visible={this.state.showEditUserDialog} refreshData={this.refreshUserData}></EditUser>
              <ResetPwd rowData={this.state.resetPwdRow} close={this.hideResetPwdDialog} visible={this.state.showResetPwdDialog} ></ResetPwd>
              <DisableUser rowData={this.state.disableUserRow} close={this.hideDisableUserDialog} visible={this.state.showDisableUserDialog} refreshData={this.refreshUserData}></DisableUser>
              <EnableUser rowData={this.state.enableUserRow} close={this.hideEnableUserDialog} visible={this.state.showEnableUserDialog} refreshData={this.refreshUserData}></EnableUser>

            </TabPane>


            // ———————————————————— 用户注销 ————————————————————————————
            <TabPane tab="用户注销" key="2">
              <Form
                name="LogOffUserForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="以前用户UID" name="userOldId">
                          <Input placeholder="以前用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="手机号码" name="mobile">
                          <Input placeholder="手机号码" />
                      </Form.Item>
                    </Col>
                    
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetLogOffUserPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.state.logOfflist}
                columns={this.state.logOffColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.logOfftotal, defaultCurrent: 1, onChange: this.logOffUserChangePage}}
              >
              </Table>
              
            </TabPane>

            // —————————————————————— 禁用列表 ————————————————————————
            <TabPane tab="禁用列表" key="3">
              <Form
                name="disableUserListForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="手机号码" name="mobile">
                          <Input placeholder="手机号码" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="用户名" name="username">
                          <Input placeholder="用户名" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="角色" name="role">
                          <Select style={{ width: 220 }} defaultValue="全部">
                            <Option value="" >全部</Option>
                            <Option value="0">普通用户</Option>
                            <Option value="1">主播用户</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={8}>
                      <Form.Item label="上级id" name="pid">
                          <Input placeholder="上级id" />
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetDisableUserPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.state.disableUserList}
                columns={this.state.disableUserColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.disableUserTotal, defaultCurrent: 1, onChange: this.disableUserChangePage}}
              >
              </Table>
            </TabPane>

            // ———————————————— 兴趣标签 ————————————————
            <TabPane tab="兴趣标签" key="4">

              <Form
                name="TabForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="类型" name="tabTypeId">
                          <Select style={{ width: 220 }} defaultValue="全部">
                            <Option value="" >全部</Option>
                            <Option value="3">自我描述</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="名称" name="name">
                          <Input placeholder="名称" />
                      </Form.Item>
                    </Col>
                    
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetTagPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddTagDialog:true})}  >
                        + 新增标签
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.state.tagList}
                columns={this.state.tagColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.tagTotal, defaultCurrent: 1, onChange: this.tagChangePage}}
              >
              </Table> 
              <AddTag close={this.hideAddTagDialog} visible={this.state.showAddTagDialog} refreshData={this.refreshTagData}></AddTag>
              <EditTag rowData={this.state.editTagRow} close={this.hideEditTagDialog} visible={this.state.showEditTagDialog} refreshData={this.refreshTagData}></EditTag>
            </TabPane>

            // —————————————————— 距离配置 ————————————————————
            <TabPane tab="距离配置" key="5">
              
                <Form
                  name="DistanceForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef5}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.userDistance}
                >
                      <Form.Item label="男用户显示地址" name="maleUserShowAddress" >
                        <Radio.Group name="maleUserShowAddress" defaultValue={0}>
                          <Radio value={0}>显示真实地址</Radio>
                          <Radio value={1}>显示虚拟地址</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="女用户显示地址" name="femaleUserShowAddress" >
                        <Radio.Group name="femaleUserShowAddress" defaultValue={0}>
                          <Radio value={0}>显示真实地址</Radio>
                          <Radio value={1}>显示虚拟地址</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男主播显示地址" name="maleAnchorShowAddress" >
                        <Radio.Group name="maleAnchorShowAddress" defaultValue={0}>
                          <Radio value={0}>显示真实地址</Radio>
                          <Radio value={1}>显示虚拟地址</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="女主播显示地址" name="femaleAnchorShowAddress" >
                        <Radio.Group name="femaleAnchorShowAddress" defaultValue={0}>
                          <Radio value={0}>显示真实地址</Radio>
                          <Radio value={1}>显示虚拟地址</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="虚拟地址显示范围(千米)">
                        <Row gutter={24}>
                            <Col span={4} key={1}>
                                <Form.Item name="showAddressStart">
                                    <Input placeholder="0" />
                                </Form.Item>
                            </Col>
                            <Col span={1} key={2}>
                                —
                            </Col>
                            <Col span={4} key={3}>
                                <Form.Item name="showAddressEnd">
                                    <Input placeholder="0" />
                                </Form.Item>
                            </Col>
                        </Row>
                      </Form.Item>
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleUserDistanceSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————— 用户资料审核开关 —————————————————— 
            <TabPane tab="用户资料审核开关" key="6">
                <Form
                  name="userCheckForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef6}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.userCheckConfig}
                >
                      <Form.Item label="用户头像审核" name="userAvatarReview" >
                        <Radio.Group name="userAvatarReview" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>打开</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="用户资料审核" name="userPortraitReview" >
                        <Radio.Group name="userPortraitReview" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>打开</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="用户名称审核" name="userNameReview" >
                        <Radio.Group name="userNameReview" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>打开</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="用户签名审核" name="userSignatureReview" >
                        <Radio.Group name="userSignatureReview" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>打开</Radio>
                        </Radio.Group>
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleUserCheckConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————— 用户资料审核 ——————————————————
            <TabPane tab="用户资料审核列表" key="7">

                <Form
                  name="UserDataReviewListForm"
                  className="ant-advanced-search-form"
                  ref={this.formRef7}
                >
                  <Row gutter={24}>
                      <Col span={8} key={1}>
                        <Form.Item label="用户UID" name="userId">
                            <Input placeholder="用户UID" />
                        </Form.Item>
                      </Col>
                      <Col span={8} key={2}>
                        <Form.Item label="资料类型" name="dataType">
                            <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>用户头像</Option>
                              <Option value={2}>用户资料图片</Option>
                            </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} key={3}>
                        <Form.Item label="审核状态" name="approvalStatus">
                            <Select style={{ width: 220 }} defaultValue="全部">
                                <Option >全部</Option>
                                <Option value={-1}>审核拒绝</Option>
                                <Option value={0}>审核中</Option>
                                <Option value={1}>审核通过</Option>
                            </Select>
                        </Form.Item>
                      </Col>
                      <Col span={14} key={4}>
                        <Row >
                          <Form.Item label="更新时间" name="upTime">
                            <RangePicker />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={this.handleGetUserDataReviewList} style={{ marginRight: '15px' }}>
                          查询
                        </Button>
                      </Col>
                  </Row>
                </Form>

                <Table 
                  bordered
                  style={{backgroundColor: '#FEFEFE'}}
                  dataSource={this.state.userDataReviewList}
                  columns={this.state.userDataReviewColumns}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  pagination = {{ total: this.state.userDataReviewTotal, defaultCurrent: 1, onChange: this.userDataReviewChangePage}}
                >
                </Table>
                <EditUserDataReview rowData={this.state.editUserDataReviewRow} close={this.hideEditUserDataReviewDialog} visible={this.state.showEditUserDataReviewDialog} refreshData={this.refreshUserDataReviewData}></EditUserDataReview>


            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default OrdinaryUsers;