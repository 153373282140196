import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
    /**
     * 更新用户地址
     * @param {*} userDistanceParam 
     * @returns 
     */
    updateUserDistance(userDistanceParam) {
        //console.log("data=" + JSON.stringify(data));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminLiveConfig/updateUserDistance",
        data: userDistanceParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


    /**
     * 获取用户地址
     * @returns 
     */
    getUserDistance() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminLiveConfig/getUserDistance",
        data: null,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


    /**
     * 更新私信配置
     * @param {*} privateletterParam 
     * @returns 
     */
     updatePrivateletter(privateletterParam) {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updatePrivateletter",
            data: privateletterParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 获取私信配置
     * @returns 
     */
     getPrivateletter() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminLiveConfig/getPrivateletter",
        data: null,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 更新幸运礼物设置
     * @param {*} luckyGiftParam 
     * @returns 
     */
    updateLuckyGift(luckyGiftParam) {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateLuckyGift",
            data: luckyGiftParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询幸运礼物
     * @returns 
     */
     getLuckyGift() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminLiveConfig/getLuckyGift",
        data: null,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


    /**
     * 更新用户注销内容
     * @param {*} userCancelParam 
     * @returns 
     */
    updateUserCancel(userCancelParam) {
        //console.log("userCancelParam=" + JSON.stringify(userCancelParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateUserCancel",
            data: userCancelParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询用户注销内容
     * @returns 
     */
    getUserCancel() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getUserCancel",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 更新隐私协议提示
     * @param {*} xieyiRuleParam 
     * @returns 
     */
    updateXieyiRule(xieyiRuleParam) {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateXieyiRule",
            data: xieyiRuleParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询隐私协议提示
     * @returns 
     */
    getXieyiRule() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getXieyiRule",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 更新认证开关
     * @param {*} certificationSwitchParam 
     * @returns 
     */
    updateCertificationSwitch(certificationSwitchParam) {
        //console.log("certificationSwitchParam=" + JSON.stringify(certificationSwitchParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateCertificationSwitch",
            data: certificationSwitchParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询认证开关
     * @returns 
     */
    getCertificationSwitch() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getCertificationSwitch",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 更新个人中心功能配置
     * @param {*} personalCenterParam 
     * @returns 
     */
    updatePersonalCenter(personalCenterParam) {
        //console.log("personalCenterParam=" + JSON.stringify(personalCenterParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updatePersonalCenter",
            data: personalCenterParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询个人中心功能配置
     * @returns 
     */
     getPersonalCenter() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getPersonalCenter",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 更新安卓上架开关
     * @param {*} androidShelfSwitchParam 
     * @returns 
     */
    updateAndroidShelfSwitch(androidShelfSwitchParam) {
        //console.log("androidShelfSwitchParam=" + JSON.stringify(androidShelfSwitchParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateAndroidShelfSwitch",
            data: androidShelfSwitchParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询安卓上架开关
     * @returns 
     */
    getAndroidShelfSwitch() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getAndroidShelfSwitch",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
     * 更新注销开关
     * @param {*} logoffSwitchParam 
     * @returns 
     */
    updateLogoffSwitch(logoffSwitchParam) {
        //console.log("logoffSwitchParam=" + JSON.stringify(logoffSwitchParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateLogoffSwitch",
            data: logoffSwitchParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询注销开关
     * @returns 
     */
    getLogoffSwitch() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getLogoffSwitch",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 更新世界礼物价格
     * @param {*} worldGiftParam 
     * @returns 
     */
     updateWorldGift(worldGiftParam) {
        //console.log("logoffSwitchParam=" + JSON.stringify(worldGiftParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateWorldGift",
            data: worldGiftParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询世界礼物价格
     * @returns 
     */
     getWorldGift() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getWorldGift",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 更新短视频配置
     * @param {*} videoSettingParam 
     * @returns 
     */
      updateVideoSetting(videoSettingParam) {
        //console.log("videoSettingParam=" + JSON.stringify(videoSettingParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/updateVideoSetting",
            data: videoSettingParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询短视频配置
     * @returns 
     */
    getVideoSetting() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/adminLiveConfig/getVideoSetting",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}