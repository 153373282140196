import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import usersCashrecordService from "../../../Service/FinanceMgr/usersCashrecordService";

import UpdateWithdrawalRecord from './UpdateWithdrawalRecord'


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class WithdrawalRecord extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 提现记录 ————————————————————————————

    withdrawalRecordColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "订单号",
        dataIndex: "orderno",
      },
      {
        key: "addtime",
        title: "申请时间",
        dataIndex: "addtime",
      },
      {
        key: "uidAvatar",
        title: "用户信息",
        dataIndex: "uidAvatar",
        align: 'center',
        render: (uidAvatar, row) => {
          var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.uid}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={uidAvatar} />
              </div>
              <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
              <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
              </div>
            </>
          );
          return info;
        }
      },
      {
        key: "money",
        title: "交易金额",
        dataIndex: "money",
      },
      {
        key: "votes",
        title: "交易映票",
        dataIndex: "votes",
      },
      {
        key: "service",
        title: "手续费",
        dataIndex: "service",
      },
      {
        key: "actualMoney",
        title: "到账金额",
        dataIndex: "actualMoney",
      },
      {
        key: "type",
        title: "账户类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "支付宝"
          } else if(type === 2) {
            return "微信";
          } else if(type === 3) {
            return "银行卡";
          }
        }
      },
      {
        key: "name",
        title: "真实姓名",
        dataIndex: "name"
      },
      {
        key: "account",
        title: "提现账号",
        dataIndex: "account"
      },
      {
        key: "status",
        title: "状态",
        dataIndex: "status",
        render: (status, row) => {
          if(status === 0) {
            return "审核中"
          } else if(status === 1) {
            return "审核通过";
          } else if(status === 2) {
            return "审核拒绝";
          }
        }
      },
      {
        key: "cashOutStatus",
        title: "转出状态",
        dataIndex: "cashOutStatus",
        render: (cashOutStatus, row) => {
          if(cashOutStatus === 0) {
            return "无转出"
          } else if(cashOutStatus === 1) {
            return "转出成功";
          } else if(cashOutStatus === 2) {
            return "转出失败";
          } else if(cashOutStatus === 3) {
            return "转出中";
          }
        }
      },
      {
        key: "reason",
        title: "原因",
        dataIndex: "reason"
      },
      {
        key: "auditby",
        title: "审核人",
        dataIndex: "auditby"
      },
      {
        key: "uptime",
        title: "审核时间",
        dataIndex: "uptime"
      },
      {
        key: "remarks",
        title: "备注",
        dataIndex: "remarks",
      },{
        key: 'del',
        title: '操作',
        dataIndex: 'del',
        render: (del, row) => {

          if(row.status === 0) {
            return (
              <div>
                <Button 
                  onClick={() => this.setState({showUpdateWithdrawalRecordDialog:true, updateWithdrawalRecordRow: row })}
                  style={{marginRight:'5px'}} 
                  type="primary">审核 
                </Button>
              </div>
            )
          } 
        }
      }
    ],
    withdrawalRecordTotal: 0,
    withdrawalRecordList: null,
    updateWithdrawalRecordRow: null,
    showUpdateWithdrawalRecordDialog: false,
    withdrawalRecordPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 提现记录 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var usersCashrecordParam = {
      pageParam: pageParam,
    };
    usersCashrecordService.getUsersCashrecordPage(usersCashrecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          withdrawalRecordList: res.retValue.list,
          withdrawalRecordTotal: res.retValue.total,
        });
      }
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 充值记录 ————————————————————————————
  // 获取分页数据
  handleGetWithdrawalRecordPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var usersCashrecordParam = {
        id: values.id,
        uid: values.uid,
        status: values.status,
        tradeNo: values.tradeNo,
        startTime: timeStart,
        endTime: timeEnd,
        cashOutStatus: values.cashOutStatus,
        pageParam: pageParam,
      };
      usersCashrecordService.getUsersCashrecordPage(usersCashrecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            withdrawalRecordList: res.retValue.list,
            withdrawalRecordTotal: res.retValue.total,
          });
        }    
        
      });
      
    })

  }



  // 分页
  withdrawalRecordChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        withdrawalRecordPageParam: pageParam,
      });

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var usersCashrecordParam = {
        id: values.id,
        uid: values.uid,
        status: values.status,
        tradeNo: values.tradeNo,
        startTime: timeStart,
        endTime: timeEnd,
        cashOutStatus: values.cashOutStatus,
        pageParam: pageParam,
      };

      usersCashrecordService.getUsersCashrecordPage(usersCashrecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            withdrawalRecordList: res.retValue.list,
            withdrawalRecordTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshWithdrawalRecordData = () => {
    //console.log("refreshWithdrawalRecordData");
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };

    var usersCashrecordParam = {
      pageParam: this.state.withdrawalRecordPageParam,
    };
    usersCashrecordService.getUsersCashrecordPage(usersCashrecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          withdrawalRecordList: res.retValue.list,
          withdrawalRecordTotal: res.retValue.total,
        });
      }    
      
    });
  };


  // 关闭更新
  hideUpdateWithdrawalRecordDialog=() => {
    this.setState({showUpdateWithdrawalRecordDialog: false});
  }

  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/withdrawal_record">提现记录</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>

            // ———————————————————— 用户提现记录 ————————————————————————————
            <TabPane tab="用户提现记录" key="1">
              <Form
                name="WithdrawalRecordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                        <Form.Item label="审核状态" name="status">
                            <Select style={{ width: 220 }} defaultValue="全部">
                                <Option >全部</Option>
                                <Option value={0}>审核中</Option>
                                <Option value={1}>审核通过</Option>
                                <Option value={2}>审核拒绝</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="第三方订单号" name="tradeNo">
                          <Input placeholder="第三方订单号"/>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                        <Form.Item label="申请时间" name="sendTime">
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID"/>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={5}>
                      <Form.Item label="ID" name="id">
                          <Input placeholder="ID"/>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                        <Form.Item label="转出状态" name="cashOutStatus">
                            <Select style={{ width: 220 }} defaultValue="全部">
                                <Option >全部</Option>
                                <Option value={0}>无转出</Option>
                                <Option value={1}>转出成功</Option>
                                <Option value={2}>转出失败</Option>
                                <Option value={3}>转出中</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} key={7} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetWithdrawalRecordPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.withdrawalRecordList}
                columns={this.state.withdrawalRecordColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.withdrawalRecordTotal,
                  defaultCurrent: 1,
                  onChange: this.withdrawalRecordChangePage,
                }}
              ></Table>
              <UpdateWithdrawalRecord rowData={this.state.updateWithdrawalRecordRow} close={this.hideUpdateWithdrawalRecordDialog} visible={this.state.showUpdateWithdrawalRecordDialog} refreshData={this.refreshWithdrawalRecordData}></UpdateWithdrawalRecord>

            </TabPane>

         
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default WithdrawalRecord;
