import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,
   Row, Col, Checkbox, Radio} from 'antd';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import userDataReviewService from '../../../Service/UserMgr/userDataReviewService';
import { ChromePicker } from 'react-color';

const { Option } = Select;

class EditUserDataReview extends Component {
  formRef = React.createRef();

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var userData = GetLoginUserInfo();
          let userDataReviewParam = {
            id : values.id,
            approvalStatus : values.approvalStatus,
            adminCheckId: userData.userId,
            adminCheckName: userData.userName,
          };
          //console.log(JSON.stringify(userDataReviewParam));

          userDataReviewService.updateUserDataReview(userDataReviewParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("保存失败")
                message.info("保存失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              } else {
                message.info('保存成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }


  render () {
    return (
      <Modal
        title="保存"
        destroyOnClose
        okText="确认"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="资料类型" name="dataType" >
                <Select style={{ width: 220 }} initialValue="全部" disabled>
                  <Option >全部</Option>
                  <Option value={1}>用户头像</Option>
                  <Option value={2}>用户资料图片</Option>
                  <Option value={3}>用户名称</Option>
                  <Option value={4}>个性签名</Option>
                </Select>
            </Form.Item>

            <Form.Item label="审核状态" name="approvalStatus" >
                <Radio.Group name="approvalStatus" initialValue={0}>
                  <Radio value={-1}>审核拒绝</Radio>
                  <Radio value={0}>审核中</Radio>
                  <Radio value={1}>审核通过</Radio>
                </Radio.Group>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default EditUserDataReview