import React, {Component, PropTypes} from 'react';
import { Form, Input, Select, Modal, Upload, Button, message} from 'antd';
import { UserOutlined,CodepenCircleFilled,EnvironmentOutlined,OrderedListOutlined } from '@ant-design/icons';
import AddPerFrm from './AddPerFrm';
import privilegeService from '../../../Service/Admin/privilegeService';
import { Logout } from '../../../Common/Auth';


const { Option } = Select;

/**
 * 添加权限
 */
class AddPer extends Component {
    // 取消操作
    hanldeCloseModal = () => {
      this.props.close();
      // 重置表单
      this.refs.addPerFrm.formRef.current.resetFields();
  }

   // 提交操作
   handleSubmit =() => {
      let { history } = this.props;
      this.refs.addPerFrm.formRef.current.validateFields()
      .then(values => {
         //console.log(JSON.stringify(values));
         let privilegeParam = values;
         privilegeService.insertOrUpdatePrivilege(privilegeParam)
         .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
         
      })

    }

  render() {
    return (
      <Modal 
        title="添加用户信息"
        okText="确定"
        cancelText="取消"
        visible={this.props.visible} 
        onCancel={this.hanldeCloseModal}
        onOk={this.handleSubmit}
      >
        <AddPerFrm ref="addPerFrm"> </AddPerFrm>
      </Modal>
    );
  }
}

export default AddPer;