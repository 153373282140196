import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import anchorCheckConfigService from '../../../Service/UserMgr/anchorCheckConfigService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class DynamicSettings extends Component {
  formRef = React.createRef();
  state={

    // ———————————————————— 通话设置 ————————————————————————————
    dynamicSettings: null,
  

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 通话设置 ————————————————————————————

    anchorCheckConfigService.getDynamicConfig()
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.state.dynamicSettings = res.retValue;

            this.formRef.current.setFieldsValue({
              authVideo: res.retValue.authVideo,
              anchorDynamicReview: res.retValue.anchorDynamicReview,
              maleDynamic: res.retValue.maleDynamic,
              femaleDynamic: res.retValue.femaleDynamic,
              nobleShowNearby: res.retValue.nobleShowNearby,
            })
          }          
        }
      )

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 通话设置 ————————————————————————————



  /**
   * 一对一
   */
   handleDynamicSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var dynamicConfigParam = values;

        anchorCheckConfigService.updateDynamicConfig(dynamicConfigParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/dynamic_setting">动态设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
            <Form
              name="dynamicSettingsForm"
              layout="horizontal"
              labelCol={{span:4}}
              wrapperCol={{span: 20}}
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 100px'}}
            >

                  <Form.Item label="发布动态认证限制" name="authVideo" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                    <Radio.Group name="authVideo" defaultValue={0}>
                      <Radio value={0}>关闭</Radio>
                      <Radio value={1}>开启</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="动态审核开关" name="anchorDynamicReview" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                    <Radio.Group name="anchorDynamicReview" defaultValue={0}>
                      <Radio value={0}>关闭</Radio>
                      <Radio value={1}>开启</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="男性发布动态" name="maleDynamic" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                    <Radio.Group name="maleDynamic" defaultValue={0}>
                      <Radio value={0}>关闭</Radio>
                      <Radio value={1}>开启</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="女性发布动态" name="femaleDynamic" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                    <Radio.Group name="femaleDynamic" defaultValue={0}>
                      <Radio value={0}>关闭</Radio>
                      <Radio value={1}>开启</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="贵族才能查看附近的人" name="nobleShowNearby" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                    <Radio.Group name="nobleShowNearby" defaultValue={0}>
                      <Radio value={0}>关闭</Radio>
                      <Radio value={1}>开启</Radio>
                    </Radio.Group>
                  </Form.Item>


            </Form>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.handleDynamicSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
              <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
            </div>

            
        </StickyContainer>
        
      </div>
    );
  }
}
export default DynamicSettings;