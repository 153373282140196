import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import usersChargeService from "../../../Service/FinanceMgr/usersChargeService";




const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class RechargeRecord extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 充值明细 ————————————————————————————

    rechargeRecordColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "单号",
        dataIndex: "orderno",
      },
      {
        key: "addtime",
        title: "充值时间",
        dataIndex: "addtime",
      },
      {
        key: "avatar",
        title: "用户头像",
        dataIndex: "avatar",
        align: 'center',
        render: (avatar, row) => {
          var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.uid}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={avatar} />
              </div>
              <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.username}</div>
              <div style={{ marginTop: '3px'}}>{row.mobile}</div>
              </div>
            </>
          );
          return info;
        }
      },
      {
        key: "phoneFirm",
        title: "手机厂商",
        dataIndex: "phoneFirm",
      },
      {
        key: "pid",
        title: "上级用户id",
        dataIndex: "pid",
      },
      {
        key: "agentId",
        title: "上级代理id",
        dataIndex: "agentId",
      },
      {
        key: "coin",
        title: "充值金币数",
        dataIndex: "coin",
      },
      {
        key: "money",
        title: "支付金额",
        dataIndex: "money",
      },
      {
        key: "type",
        title: "充值方式",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "支付宝"
          } else if(type === 2) {
            return "微信";
          } else if(type === 3) {
            return "ios内购";
          } else if(type === 4) {
            return "手动充值";
          }
        }
      },
      {
        key: "status",
        title: "充值状态",
        dataIndex: "status",
        render: (status, row) => {
          if(status === 0) {
            return "充值中"
          } else if(status === 1) {
            return "充值成功";
          } else if(status === 2) {
            return "充值失败";
          } 
        }
      },
      {
        key: "afterCoin",
        title: "余金币",
        dataIndex: "afterCoin",
      },
      {
        key: "tradeNo",
        title: "第三方订单号",
        dataIndex: "tradeNo",
      },
      {
        key: "optType",
        title: "操作类型",
        dataIndex: "optType",
        render: (optType, row) => {
          if(optType === 1) {
            return "充值金币"
          } else if(optType === 2) {
            return "扣减金币";
          }
        }
      },
      {
        key: "optUser",
        title: "操作人",
        dataIndex: "optUser",
      },
      {
        key: "remarks",
        title: "备注",
        dataIndex: "remarks",
      }
    ],
    rechargeRecordTotal: 0,
    rechargeRecordList: null,

    // ———————————————————— 手动充值 ————————————————————————————
    manualRecharge: null,
    
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 充值记录 ————————————————————————————
    
    this.getUsersCharge();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 充值记录 ————————————————————————————
  // 获取分页数据
  handleGetRechargeRecordPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var usersChargeParam = {
        orderno: values.orderno,
        uid: values.uid,
        type: values.type,
        status: values.status,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam: pageParam,
      };
      usersChargeService.getUsersChargePage(usersChargeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            rechargeRecordList: res.retValue.list,
            rechargeRecordTotal: res.retValue.total,
          });
        }
        
      });
      
    })

  }



  // 分页
  rechargeRecordChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var usersChargeParam = {
        orderno: values.orderno,
        uid: values.uid,
        type: values.type,
        status: values.status,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam: pageParam,
      };

      usersChargeService.getUsersChargePage(usersChargeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            rechargeRecordList: res.retValue.list,
            rechargeRecordTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshRechargeRecordData = () => {
    let { history } = this.props;
    console.log("refreshRechargeRecordData");
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };

    var usersChargeParam = {
      pageParam: pageParam,
    };
    usersChargeService.getUsersChargePage(usersChargeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          rechargeRecordList: res.retValue.list,
          rechargeRecordTotal: res.retValue.total,
        });
      }
      
    });
  };


  // ———————————————————— 手动充值 ————————————————————————————


  handleManualRechargeSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        let userData = GetLoginUserInfo();
        var appUsersCharge = {
          type : values.type,
          uid: values.uid,
          touid : values.uid,
          optType : values.optType,
          coin : values.coin,
          remarks : values.remarks,
          optUser : userData.userName
        };

        usersChargeService.insertUsersCharge(appUsersCharge)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // 关闭操作
  hanldeCloseModal = () => {
    // 重置表单
    this.formRef.current.resetFields();
  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getUsersCharge();
    } 
  }

  /**
   * 充值记录
   */
  getUsersCharge = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var usersChargeParam = {
      pageParam: pageParam,
    };
    usersChargeService.getUsersChargePage(usersChargeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          rechargeRecordList: res.retValue.list,
          rechargeRecordTotal: res.retValue.total,
        });
      }
      
    });
    
  }



  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/recharge_record">充值记录</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // ———————————————————— 充值明细 ————————————————————————————
            <TabPane tab="充值明细" key="1">
              <Form
                name="RechargeRecordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="单号" name="orderno">
                          <Input placeholder="单号"/>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="UID" name="uid">
                          <Input placeholder="UID"/>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                        <Form.Item label="支付状态" name="status">
                            <Select style={{ width: 220 }} defaultValue="全部">
                                <Option >全部</Option>
                                <Option value={0}>充值中</Option>
                                <Option value={1}>充值成功</Option>
                                <Option value={2}>充值失败</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                        <Form.Item label="支付方式" name="type">
                            <Select style={{ width: 220 }} defaultValue="全部">
                                <Option >全部</Option>
                                <Option value={1}>支付宝</Option>
                                <Option value={2}>微信</Option>
                                <Option value={3}>ios内购</Option>
                                <Option value={4}>手动充值</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} key={5}>
                        <Form.Item label="充值时间" name="sendTime">
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                      <Form.Item label="上级用户ID" name="pid">
                          <Input placeholder="上级用户ID"/>
                      </Form.Item>
                    </Col>
                    <Col span={6} key={7} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetRechargeRecordPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.rechargeRecordList}
                columns={this.state.rechargeRecordColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.rechargeRecordTotal,
                  defaultCurrent: 1,
                  onChange: this.rechargeRecordChangePage,
                }}
              ></Table>
            </TabPane>

            // —————————————————————— 手动充值 ————————————————————————
            <TabPane tab="手动充值" key="2">
              <Form
                  name="ManualRechargeForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.manualRecharge}
                >
                      <Form.Item label="type" name="type" initialValue={4} hidden>
                          <Input placeholder="type" />
                      </Form.Item>

                      <Form.Item label="用户UID" name="uid" labelCol={{span:10}}  style={{ width: 390 }}>
                          <Input placeholder="用户UID" />
                      </Form.Item>

                      <Form.Item label="充值方式" name="optType" labelCol={{span:10}}  style={{ width: 390 }} initialValue={0}>
                        <Radio.Group name="optType" defaultValue={0}>
                          <Radio value={1}>充值金币</Radio>
                          <Radio value={2}>扣除金币</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="金币数量" name="coin" labelCol={{span:10}}  style={{ width: 390 }}>
                          <Input placeholder="金币数量" />
                      </Form.Item>

                      <Form.Item label="备注" name="remarks" labelCol={{span:10}}  style={{ width: 390 }} >
                          <TextArea placeholder="备注" />
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleManualRechargeSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </TabPane>
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default RechargeRecord;
