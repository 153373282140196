import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import gradeService from '../../../Service/GradeMgr/gradeService';
import AddNobleLevel from './AddNobleLevel';
import UpdateNobleLevel from './UpdateNobleLevel';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class NobleLevel extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 举报列表 ————————————————————————————
    nobleLevelColumns: [{
      key: 'id',
      title: '等级ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '等级名称',
      dataIndex: 'name'
    },{
      key: 'grade',
      title: '等级值',
      dataIndex: 'grade'
    },{
      key: 'gradeIcon',
      title: '等级图片',
      dataIndex: 'gradeIcon',
      render: (gradeIcon, row) => {
        if(gradeIcon != null && gradeIcon != "") {
          return <Image
              width={50}
              height={30}
              src={gradeIcon}
            />
        }
      }
    },{
      key: 'avatarFrame',
      title: '头像框',
      dataIndex: 'avatarFrame',
      render: (avatarFrame, row) => {
        if(avatarFrame != null && avatarFrame != "") {
          return <Image
              width={50}
              height={50}
              src={avatarFrame}
            />
        }
      }
    },{
      key: 'type',
      title: '等级类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "用户等级"
        } else if(type === 2) {
          return "财富等级";
        } else if(type === 3) {
          return "主播等级";
        } else if(type === 4) {
          return "贵族等级";
        } 
      }
    },{
      key: 'rechargeDiscount',
      title: '充值折扣',
      dataIndex: 'rechargeDiscount',
    },{
      key: 'callDiscount',
      title: '通话折扣',
      dataIndex: 'callDiscount',
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateNobleLevelDialog:true, updateNobleLevelRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    nobleLevelTotal: 0,
    nobleLevelList: null,
    updateNobleLevelRow: null,
    showUpdateNobleLevelDialog: false,
    addNobleLevelRow: null,
    showAddNobleLevelDialog: false,
    nobleLevelPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 举报列表 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var gradeParam = {
      type : 4,
      pageParam : pageParam,
    }
    gradeService.getGradePage(gradeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({nobleLevelList: res.retValue.list, nobleLevelTotal: res.retValue.total});
        }    
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 举报列表 ————————————————————————————


  // 用户分页
  nobleLevelChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          nobleLevelPageParam: pageParam,
        });

        var gradeParam = {
          type : 4,
          pageParam : pageParam,
        }
        gradeService.getGradePage(gradeParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({nobleLevelList: res.retValue.list, nobleLevelTotal: res.retValue.total});
            }    
          }
        )
    })
  }

  // 刷新数据
  refreshNobleLevelData=() => {
    //console.log("refreshNobleLevelData");
    let { history } = this.props;

    var gradeParam = {
      type : 4,
      pageParam : this.state.nobleLevelPageParam,
    }
    gradeService.getGradePage(gradeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({nobleLevelList: res.retValue.list, nobleLevelTotal: res.retValue.total});
        }    
      }
    )
  }

  // 隐藏分成方案弹窗
  hideUpdateNobleLevelDialog=() => {
    this.setState({showUpdateNobleLevelDialog: false});
  }

  // 隐藏分成方案弹窗
  hideAddNobleLevelDialog=() => {
    this.setState({showAddNobleLevelDialog: false});
  }
  


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/noble_level">贵族等级</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="nobleLevelListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddNobleLevelDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.nobleLevelList}
                columns={this.state.nobleLevelColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.nobleLevelTotal, defaultCurrent: 1, onChange: this.nobleLevelChangePage}}
              >
              </Table>
              <AddNobleLevel close={this.hideAddNobleLevelDialog} visible={this.state.showAddNobleLevelDialog} refreshData={this.refreshNobleLevelData}></AddNobleLevel>
              <UpdateNobleLevel rowData={this.state.updateNobleLevelRow} close={this.hideUpdateNobleLevelDialog} visible={this.state.showUpdateNobleLevelDialog} refreshData={this.refreshNobleLevelData}></UpdateNobleLevel>

        </StickyContainer>
        
      </div>
    );
  }
}
export default NobleLevel;