import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import systemNoticeTypeService from '../../../Service/Operation/systemNoticeTypeService';
import systemNoticeService from '../../../Service/Operation/systemNoticeService';
import sysNoticService from '../../../Service/Operation/sysNoticService';
import officialNewsService from '../../../Service/Operation/officialNewsService';

import AddSystemNoticeType from './AddSystemNoticeType';
import UpdateSystemNoticeType from './UpdateSystemNoticeType';
import UpdateSystemNotice from './UpdateSystemNotice';
import AddSysNotic from './AddSysNotic';
import UpdateSysNotic from './UpdateSysNotic';
import AddOfficialNews from './AddOfficialNews';
import UpdateOfficialNews from './UpdateOfficialNews';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class SystemBulletin extends Component {
  formRef = React.createRef();
  state={
   
    // ———————————————————— 通知类型 ————————————————————————————
    
    systemNoticeTypeColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '类型标题',
      dataIndex: 'name'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getUpdateSystemNoticeTypeRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    systemNoticeTypeTotal: 0,
    systemNoticeTypeList: [],
    addSystemNoticeTypeRow: null,
    updateSystemNoticeTypeRow: null,
    showUpdateSystemNoticeTypeDialog: false,
    showAddSystemNoticeTypeDialog:false,
    systemNoticeTypePageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 系统通知 ————————————————————————————
   
    systemNoticeColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'logo',
      title: 'LOGO',
      dataIndex: 'logo',
      render: (logo, row) => {
        if(logo != null && logo != "") {
          return <Image
          width={55}
          height={55}
          src={logo}
        />
        }
      }
    },{
      key: 'showTitle',
      title: '前端标题',
      dataIndex: 'showTitle'
    },{
      key: 'title',
      title: '通知标题',
      dataIndex: 'title'
    },{
      key: 'content',
      title: '通知内容',
      dataIndex: 'content'
    },{
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "未开启"
        } else if(status === 1) {
          return "开启";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getUpdateSystemNoticeRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    systemNoticeTotal: 0,
    systemNoticeList: [],
    addSystemNoticeRow: null,
    updateSystemNoticeRow: null,
    showUpdateSystemNoticeDialog: false,
    showAddSystemNoticeDialog:false,
    systemNoticePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 系统公告 ————————————————————————————
    
    sysNoticColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'type',
      title: '公告类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "首页系统公告"
        }
      }
    },{
      key: 'shape',
      title: '公告形式',
      dataIndex: 'shape',
      render: (shape, row) => {
        if(shape === 1) {
          return "文字";
        } else if(shape === 2) {
          return "图片";
        }
      }
    },{
      key: 'title',
      title: '公告名称',
      dataIndex: 'title'
    },{
      key: 'showType',
      title: '弹出频率',
      dataIndex: 'showType',
      render: (showType, row) => {
        if(showType === 1) {
          return "启动app时";
        } else if(showType === 2) {
          return "每天弹一次";
        }
      }
    },{
      key: 'status',
      title: '是否开启',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "开启";
        } else if(status === 1) {
          return "关闭";
        }
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getUpdateSysNoticRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteSysNotic(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    sysNoticTotal: 0,
    sysNoticList: [],
    addSysNoticRow: null,
    updateSysNoticRow: null,
    showUpdateSysNoticDialog: false,
    showAddSysNoticDialog:false,
    sysNoticPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
    
    // ———————————————————— 官方消息 ————————————————————————————
    officialNewsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'title',
      title: '标题',
      dataIndex: 'title'
    },{
      key: 'logo',
      title: '图片',
      dataIndex: 'logo',
      render: (logo, row) => {
        if(logo != null && logo != "") {
          return <Image
          width={100}
          height={64}
          src={logo}
        />
        }
      }
    },{
      key: 'pushNum',
      title: '推送人数',
      dataIndex: 'pushNum'
    },{
      key: 'num',
      title: '查看人数',
      dataIndex: 'num'
    },{
      key: 'publishStatus',
      title: '发布状态',
      dataIndex: 'publishStatus',
      render: (publishStatus, row) => {
        if(publishStatus === 0) {
          return "未发布";
        } else if(publishStatus === 1) {
          return "已发布";
        }
      }
    },{
      key: 'pushStatus',
      title: '推送状态',
      dataIndex: 'pushStatus',
      render: (pushStatus, row) => {
        if(pushStatus === 0) {
          return "未推送";
        } else if(pushStatus === 1) {
          return "已推送";
        }
      }
    },{
      key: 'publishTime',
      title: '发布时间',
      dataIndex: 'publishTime'
    },{
      key: 'content',
      title: '消息内容',
      dataIndex: 'content',
      colSpan:0,
      render:()=>{return {props:{colSpan:0}};}
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getUpdateOfficialNewsRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteOfficialNews(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    officialNewsTotal: 0,
    officialNewsList: [],
    addOfficialNewsRow: null,
    updateOfficialNewsRow: null,
    showUpdateOfficialNewsDialog: false,
    showAddOfficialNewsDialog:false,
    officialNewsPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 签到奖励 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var systemNoticeTypeParam={
      pageParam: pageParam
    }

    systemNoticeTypeService.getSystemNoticeTypePage(systemNoticeTypeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({systemNoticeTypeList: res.retValue.list, systemNoticeTypeTotal: res.retValue.total});
        }                  
      }
    )
   
    // ———————————————————— 系统通知 ————————————————————————————

    var systemNoticeParam={
      pageParam: pageParam
    }

    systemNoticeService.getSystemNoticePage(systemNoticeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({systemNoticeList: res.retValue.list, systemNoticeTotal: res.retValue.total});
        }                  
      }
    )

    // ———————————————————— 系统公告 ————————————————————————————
    var sysNoticParam={
      pageParam: pageParam
    }

    sysNoticService.getSysNoticPage(sysNoticParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({sysNoticList: res.retValue.list, sysNoticTotal: res.retValue.total});
        }                  
      }
    )
    
    // ———————————————————— 官方消息 ————————————————————————————
   
    var officialNewsParam={
      pageParam: pageParam
    }

    officialNewsService.getOfficialNewsPage(officialNewsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({officialNewsList: res.retValue.list, officialNewsTotal: res.retValue.total});
        }                  
      }
    )
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }


  
  // ———————————————————— 通知类型 ————————————————————————————
    
     // 关闭更新
  hideUpdateSystemNoticeTypeDialog=() => {
    this.setState({showUpdateSystemNoticeTypeDialog: false});
  }

  // 关闭添加
  hideAddSystemNoticeTypeDialog=() => {
    this.setState({showAddSystemNoticeTypeDialog: false});
  }


  // 获取列表
  handleGetSystemNoticeTypePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var SystemNoticeTypeParam={
        pageParam: pageParam
      }
  
      systemNoticeTypeService.getSystemNoticeTypePage(SystemNoticeTypeParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({systemNoticeTypeList: res.retValue.list, systemNoticeTypeTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  systemNoticeTypeChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      systemNoticeTypePageParam: pageParam,
    });

    var systemNoticeTypeParam={
      pageParam: pageParam
    }

    systemNoticeTypeService.getSystemNoticeTypePage(systemNoticeTypeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({systemNoticeTypeList: res.retValue.list, systemNoticeTypeTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新
   */
  refreshSystemNoticeTypeData=() => {
      let { history } = this.props;

      var systemNoticeTypeParam={
        pageParam: this.state.systemNoticeTypePageParam
      }
  
      systemNoticeTypeService.getSystemNoticeTypePage(systemNoticeTypeParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({systemNoticeTypeList: res.retValue.list, systemNoticeTypeTotal: res.retValue.total});
          }                  
        }
      )
  }

   /**
   * 获取添加数据
   * @param {*} options 
   */
  getAddSystemNoticeTypeRowData = () => {
    this.setState({showAddSystemNoticeTypeDialog:true })
  };

   /**
   * 获取更新数据
   * @param {*} options 
   */
  getUpdateSystemNoticeTypeRowData = (row) => {
    this.state.updateSystemNoticeTypeRow = row;
    this.setState({showUpdateSystemNoticeTypeDialog:true })
  };
      

  // ———————————————————— 系统通知 ————————————————————————————
 
  // 关闭更新
  hideUpdateSystemNoticeDialog=() => {
    this.setState({showUpdateSystemNoticeDialog: false});
  }

  // 关闭添加
  hideAddSystemNoticeDialog=() => {
    this.setState({showAddSystemNoticeDialog: false});
  }


  // 获取列表
  handleGetSystemNoticePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var systemNoticeParam={
        pageParam: pageParam
      }
  
      systemNoticeService.getSystemNoticePage(systemNoticeParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({systemNoticeList: res.retValue.list, systemNoticeTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  systemNoticeChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      systemNoticePageParam: pageParam,
    });

    var systemNoticeParam={
      pageParam: pageParam
    }

    systemNoticeService.getSystemNoticePage(systemNoticeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({systemNoticeList: res.retValue.list, systemNoticeTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新
   */
  refreshSystemNoticeData=() => {
      let { history } = this.props;

      var systemNoticeParam={
        pageParam: this.state.systemNoticePageParam
      }
  
      systemNoticeService.getSystemNoticePage(systemNoticeParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({systemNoticeList: res.retValue.list, systemNoticeTotal: res.retValue.total});
          }                  
        }
      )
  }

    /**
   * 获取添加数据
   * @param {*} options 
   */
  getAddSystemNoticeRowData = () => {
    this.setState({showAddSystemNoticeDialog:true })
  };

    /**
   * 获取更新数据
   * @param {*} options 
   */
  getUpdateSystemNoticeRowData = (row) => {
    this.state.updateSystemNoticeRow = row;
    this.setState({showUpdateSystemNoticeDialog:true })
  };

  // ———————————————————— 系统公告 ————————————————————————————
  // 关闭更新
  hideUpdateSysNoticDialog=() => {
    this.setState({showUpdateSysNoticDialog: false});
  }

  // 关闭添加
  hideAddSysNoticDialog=() => {
    this.setState({showAddSysNoticDialog: false});
  }


  // 获取列表
  handleGetSysNoticPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var sysNoticParam={
        pageParam: pageParam
      }
  
      sysNoticService.getSysNoticPage(sysNoticParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysNoticList: res.retValue.list, sysNoticTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  sysNoticChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      sysNoticPageParam: pageParam,
    });

    var sysNoticParam={
      pageParam: pageParam
    }

    sysNoticService.getSysNoticPage(sysNoticParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({sysNoticList: res.retValue.list, sysNoticTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新
   */
  refreshSysNoticData=() => {
      let { history } = this.props;

      var sysNoticParam={
        pageParam: this.state.sysNoticPageParam
      }
  
      sysNoticService.getSysNoticPage(sysNoticParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysNoticList: res.retValue.list, sysNoticTotal: res.retValue.total});
          }                  
        }
      )
  }

    /**
   * 获取添加数据
   * @param {*} options 
   */
  getAddSysNoticRowData = () => {
    this.setState({showAddSysNoticDialog:true })
  };

    /**
   * 获取更新数据
   * @param {*} options 
   */
  getUpdateSysNoticRowData = (row) => {
    this.state.updateSysNoticRow = row;
    this.setState({showUpdateSysNoticDialog:true })
  };

  // 根据Id删除
  deleteSysNotic=(id) => {
    let { history } = this.props;
    let sysNoticParam = {
      id : id
    };
    sysNoticService.deleteSysNotic(sysNoticParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshSysNoticData();
        message.success('删除成功！');
      }                  
      
    })

  }

  // ———————————————————— 官方消息 ————————————————————————————

  // 关闭更新
  hideUpdateOfficialNewsDialog=() => {
    this.setState({showUpdateOfficialNewsDialog: false});
  }

  // 关闭添加
  hideAddOfficialNewsDialog=() => {
    this.setState({showAddOfficialNewsDialog: false});
  }


  // 获取列表
  handleGetOfficialNewsPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var officialNewsParam={
        pageParam: pageParam
      }
  
      officialNewsService.getOfficialNewsPage(officialNewsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({officialNewsList: res.retValue.list, officialNewsTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  officialNewsChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }
    
    this.setState({
      officialNewsPageParam: pageParam,
    });

    var officialNewsParam={
      pageParam: pageParam
    }

    officialNewsService.getOfficialNewsPage(officialNewsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({officialNewsList: res.retValue.list, officialNewsTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新
   */
  refreshOfficialNewsData=() => {
      let { history } = this.props;

      var officialNewsParam={
        pageParam: this.state.officialNewsPageParam
      }
  
      officialNewsService.getOfficialNewsPage(officialNewsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({officialNewsList: res.retValue.list, officialNewsTotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除
  deleteOfficialNews=(id) => {
    let { history } = this.props;
    let officialNewsParam = {
      id : id
    };
    officialNewsService.deleteOfficialNews(officialNewsParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshOfficialNewsData();
        message.success('删除成功！');
      }                  
      
    })

  }

    /**
   * 获取添加数据
   * @param {*} options 
   */
  getAddOfficialNewsRowData = () => {
    this.setState({showAddOfficialNewsDialog:true })
  };

    /**
   * 获取更新数据
   * @param {*} options 
   */
  getUpdateOfficialNewsRowData = (row) => {
    this.state.updateOfficialNewsRow = row;
    this.setState({showUpdateOfficialNewsDialog:true })
  };


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getTodayData();
    } else if(key == "2") {
      this.getYesterdayData();
    } else if(key == "3") {
      this.getWeekData();
    } else if(key == "4") {
      this.getLastweekData();
    } 
  }

  /**
   * 签到奖励
   */
  getSystemNoticeType=()=>{
    let { history } = this.props;

    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var systemNoticeTypeParam={
      pageParam: pageParam
    }

    systemNoticeTypeService.getSystemNoticeTypePage(systemNoticeTypeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({systemNoticeTypeList: res.retValue.list, systemNoticeTypeTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 系统通知
   */
  getSystemNotice=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var systemNoticeParam={
      pageParam: pageParam
    }

    systemNoticeService.getSystemNoticePage(systemNoticeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({systemNoticeList: res.retValue.list, systemNoticeTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 系统公告
   */
  getSysNotic=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var sysNoticParam={
      pageParam: pageParam
    }

    sysNoticService.getSysNoticPage(sysNoticParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({sysNoticList: res.retValue.list, sysNoticTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 官方消息
   */
  getOfficialNews=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var officialNewsParam={
      pageParam: pageParam
    }

    officialNewsService.getOfficialNewsPage(officialNewsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({officialNewsList: res.retValue.list, officialNewsTotal: res.retValue.total});
        }                  
      }
    )
  }

 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/basic_activities">系统公告</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 通知类型 ————————————————————————————
            <TabPane tab="通知类型" key="1">
              <Form
                name="SystemNoticeTypeForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.getAddSystemNoticeTypeRowData()}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.systemNoticeTypeList}
                columns={this.state.systemNoticeTypeColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.systemNoticeTypeTotal, defaultCurrent: 1, onChange: this.systemNoticeTypeChangePage}}
              >
              </Table>
              <AddSystemNoticeType close={this.hideAddSystemNoticeTypeDialog} visible={this.state.showAddSystemNoticeTypeDialog} refreshData={this.refreshSystemNoticeTypeData}></AddSystemNoticeType>
              <UpdateSystemNoticeType rowData={this.state.updateSystemNoticeTypeRow} close={this.hideUpdateSystemNoticeTypeDialog} visible={this.state.showUpdateSystemNoticeTypeDialog} refreshData={this.refreshSystemNoticeTypeData}></UpdateSystemNoticeType>
              
            
            </TabPane>
            

            // ———————————————————— 系统通知 ————————————————————————————
            <TabPane tab="系统通知" key="2">
              
              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.systemNoticeList}
                columns={this.state.systemNoticeColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.systemNoticeTotal, defaultCurrent: 1, onChange: this.systemNoticeChangePage}}
              >
              </Table>
              <UpdateSystemNotice rowData={this.state.updateSystemNoticeRow} close={this.hideUpdateSystemNoticeDialog} visible={this.state.showUpdateSystemNoticeDialog} refreshData={this.refreshSystemNoticeData}></UpdateSystemNotice>
              
            </TabPane>

            // ———————————————————— 系统公告 ————————————————————————————
            <TabPane tab="系统公告" key="3">
              <Form
                name="SysNoticForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.getAddSysNoticRowData()}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.sysNoticList}
                columns={this.state.sysNoticColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.sysNoticTotal, defaultCurrent: 1, onChange: this.sysNoticChangePage}}
              >
              </Table>
              <AddSysNotic close={this.hideAddSysNoticDialog} visible={this.state.showAddSysNoticDialog} refreshData={this.refreshSysNoticData}></AddSysNotic>
              <UpdateSysNotic rowData={this.state.updateSysNoticRow} close={this.hideUpdateSysNoticDialog} visible={this.state.showUpdateSysNoticDialog} refreshData={this.refreshSysNoticData}></UpdateSysNotic>
              
            </TabPane>

            // ———————————————————— 官方消息 ————————————————————————————
            <TabPane tab="官方消息" key="4">
              <Form
                name="OfficialNewsForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.getAddOfficialNewsRowData()}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.officialNewsList}
                columns={this.state.officialNewsColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.officialNewsTotal, defaultCurrent: 1, onChange: this.officialNewsChangePage}}
              >
              </Table>
              <AddOfficialNews close={this.hideAddOfficialNewsDialog} visible={this.state.showAddOfficialNewsDialog} refreshData={this.refreshOfficialNewsData}></AddOfficialNews>
              <UpdateOfficialNews rowData={this.state.updateOfficialNewsRow} close={this.hideUpdateOfficialNewsDialog} visible={this.state.showUpdateOfficialNewsDialog} refreshData={this.refreshOfficialNewsData}></UpdateOfficialNews>
              
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default SystemBulletin;