import React, { Component, PropTypes } from 'react';
import { Link } from 'react-router-dom';
import './login.scss';
import TextValidator from '../../Components/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import service from '../../Service/loginService';
import Background from '../../static/admin/imags/login_bg.jpg';
import axios from 'axios';
import { SaveLoginUserInfo, SaveLoginToken } from '../../Common/Auth';
import { message } from 'antd';
import { urlParam2Object } from '../../Common/Helper';

var errMsg =''

class Login extends Component {
  /**
   * 构造方法，初始化状态参数
   */
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      verCode: ''
    }
    //this.handlerChange = this.handlerChange.bind(this);
  }

  // 第一次渲染后调用
  componentDidMount() {
    // let requestMap = {
    //   sessionId: ""
    // }
    // let requestParam = JSON.stringify(requestMap);

    // let param = new URLSearchParams()
    // param.append('apiNameEn', 'com.miyun.script.client.api.admin.LoginAdminApi_getKaptchaImage')
    // param.append('requestParam', requestParam);
  }


  // 获取验证码图片
  getImageCode(e) {
    var imageCode=document.getElementById('imageCode');
    imageCode.src = "/admin/loginAdmin/getVerify?t="
          + (new Date().getTime());
  }

  // 提交操作
  handleSubmit =(event) => {
    event.preventDefault();
    let { history, location } = this.props;
    let forms = new FormData();
    forms.append('name',this.state.username);
    forms.append('password',this.state.password);
    forms.append('code',this.state.verCode);

    // 登录
    service.login(forms)
    .then(
      res => {
        if(res != null && res.retCode == 200) {
          // 保存用户登录后，后台返回的token身份信息；
          SaveLoginToken(res.retValue.token);
          SaveLoginUserInfo(res.retValue);
          // 跳转到请求之前的页面
          let url = '/home';
          history.push(url);
        } else {
          if(res != null) {
            message.error(res.message);
          } else {
            message.error("服务端错误！");
          }
        }
      }
    )
    //alert("name="+this.state.username+";" +" password="+this.state.password +" verCode="+ this.state.verCode);
  }

  // 操作改变，同步修改state参数
  handlerChange=(e) => {
    let newState = {[e.target.name]: e.target.value};
    this.setState(state => ({...this.state, ...newState}));
  }

  render() {
    return (
      <div style={{ backgroundImage: `url(${Background})` ,
           height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
           width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
           overflow: 'auto' }}>
        <form method="post" onSubmit={this.handleSubmit}>
          <div style={{ float: 'right', width: '520px', margin: '160px 210px' }}>

            <div style={{ textAlign: 'center' }}>
              <img style={{ height: '50px' }} src="https://ylcc.yilelive.com/userimg/2021/12/1_04021152616_1647.png" ></img>

            </div>

            <div className='divLogin'>

              <div style={{ paddingTop: '60px' }}>
                <section className="secInput">
                  <input className="boxInput" 
                    type="text" 
                    id="username" 
                    name="username"
                    placeholder="请输入您的账号" 
                    onChange={this.handlerChange}  
                    value={this.state.username} />
                </section>
                <section className="secInput">
                  <input className="boxInput" 
                    type="password" 
                    id="password" 
                    value={this.state.password}
                    onChange={this.handlerChange} 
                    name="password"
                    placeholder="请输入密码" />
                </section>

                <section className="secInput">
                  <div style={{ width: '230px', float: 'left' }}>
                    <input className="boxInput" 
                      style={{ width: '230px' }} 
                      type="text"
                      id="verCode" 
                      value={this.state.verCode}
                      onChange={this.handlerChange} 
                      name="verCode" 
                      placeholder="请输入验证码" />
                  </div>
                  <div style={{ display: 'inline-block', paddingLeft: '30px' }}>
                    <img onClick={e => this.getImageCode(e)} 
                        style={{cursor: 'pointer', lineHeight: '54px', width: '120px', height: '54px'}}
                        src="/admin/loginAdmin/getVerify"
                        id="imageCode" />
                  </div>

                </section>
              </div>
              <div className="secInput">

                <input className="btnLogin" name="btnLogin" id="btnLogin" value="登录" type="submit"/>

              </div>
              <div className="secInput" style={{ textAlign: 'center' }}>
                <span className="spForErr">{errMsg}</span>
              </div>


            </div>

          </div>
        </form>

        <div style={{ position: 'fixed', top: '-100px', left: '-300px', widows: '200px', overflow: 'hidden' }}>
          <a href="https://www.yile.chat.com/">广西一乐网络科技有限公司</a> <a
            href="https://www.yile.chat.com/">一乐</a>
        </div>

      </div>
    );
  }
}

export default Login;