import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import gradeRewarService from '../../../Service/GradeMgr/gradeRewarService';
import gradeRewarReService from '../../../Service/GradeMgr/gradeRewarReService';
import nobLiveGiftService from '../../../Service/GradeMgr/nobLiveGiftService';
import AddGradeGift from './AddGradeGift';
import UpdateGradeGift from './UpdateGradeGift';
import ShowGradeGiftRe from './ShowGradeGiftRe';
import AddGradeGiftRe from './AddGradeGiftRe';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class GradeGift extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 财富等级 ————————————————————————————
    gradeGiftColumns: [{
      key: 'id',
      title: '等级ID',
      dataIndex: 'id'
    },{
      key: 'gradeName',
      title: '等级礼包名称',
      dataIndex: 'gradeName'
    },{
      key: 'grade',
      title: '等级值',
      dataIndex: 'grade'
    },{
      key: 'type',
      title: '等级类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "用户等级"
        } else if(type === 2) {
          return "财富等级";
        } 
      }
    },{
      key: 'createTime',
      title: '添加时间',
      dataIndex: 'createTime'
    },{
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "正常"
        } else if(status === 1) {
          return "删除";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.showGradeGiftRe(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">查看礼包内容
              </Button>
              <Button 
                onClick={() => this.addGradeGiftRe(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">添加礼包内容
              </Button>
               <Button 
                onClick={() => this.setState({showUpdateGradeGiftDialog:true, updateGradeGiftRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    gradeGiftTotal: 0,
    gradeGiftList: null,
    updateGradeGiftRow: null,
    showUpdateGradeGiftDialog: false,
    addGradeGiftRow: null,
    showAddGradeGiftDialog: false,
    showGradeGiftReRow: null,
    showGradeGiftReDialog: false,
    showAddGradeGiftReDialog:false,
    addGradeGiftReRow: null,
    gradeGiftPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var gradeRewarParam = {
      pageParam : pageParam,
    }
    gradeRewarService.getGradeRewarPage(gradeRewarParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({gradeGiftList: res.retValue.list, gradeGiftTotal: res.retValue.total});
        }    
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 等级礼物列表 ————————————————————————————

  // 获取等级礼物
  handleGetGradeGiftPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var gradeRewarParam = {
        type: values.type,
        status: values.status,
        pageParam : pageParam,
      }
      gradeRewarService.getGradeRewarPage(gradeRewarParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({gradeGiftList: res.retValue.list, gradeGiftTotal: res.retValue.total});
          }    
        }
      )
      
    })

  }

  // 用户分页
  gradeGiftChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          gradeGiftPageParam: pageParam,
        });

        var gradeRewarParam = {
          type : values.type,
          status : values.status,
          pageParam : pageParam,
        }
        gradeRewarService.getGradeRewarPage(gradeRewarParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({gradeGiftList: res.retValue.list, gradeGiftTotal: res.retValue.total});
            }    
          }
        )
    })
  }

  // 刷新数据
  refreshGradeGiftData=() => {
    //console.log("refreshGradeGiftData");
    let { history } = this.props;

    var gradeRewarParam = {
      pageParam : this.state.gradeGiftPageParam,
    }
    gradeRewarService.getGradeRewarPage(gradeRewarParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({gradeGiftList: res.retValue.list, gradeGiftTotal: res.retValue.total});
        }    
      }
    )
  }

  // 隐藏
  hideUpdateGradeGiftDialog=() => {
    this.setState({showUpdateGradeGiftDialog: false});
  }

  // 隐藏
  hideAddGradeGiftDialog=() => {
    this.setState({showAddGradeGiftDialog: false});
  }
  
  // 隐藏
  hideGradeGiftReDialog=() => {
    this.setState({showGradeGiftReDialog: false});
  }

  showGradeGiftRe=(id) => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    }

    var gradeRewarReParam = {
      gradeId: id,
      pageParam : pageParam,
    }
    gradeRewarReService.getGradeRewarRePage(gradeRewarReParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.state.showGradeGiftReRow = {
            gradeGiftReList : res.retValue.list,
            gradeGiftReTotal: res.retValue.total
          }
          this.setState({showGradeGiftReDialog:true})
        }    

      }
    )
  }

  addGradeGiftRe=(id) => {
    let { history } = this.props;
    nobLiveGiftService.getGiftList()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.state.addGradeGiftReRow = {
            giftList : res.retValue,
            gradeId: id,
          }
  
          this.setState({showAddGradeGiftReDialog:true})
        }    
        
      }
    )
  }


  // 用户分页
  gradeGiftReChangePage=(page, pageSize, gradeId) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var gradeRewarReParam = {
          gradeId: gradeId,
          pageParam : pageParam,
        }
        gradeRewarReService.getGradeRewarRePage(gradeRewarReParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.state.showGradeGiftReRow = {
                gradeGiftReList : res.retValue.list,
                gradeGiftReTotal: res.retValue.total
              }
              this.setState({showGradeGiftReDialog:true})
            }    
    
          }
        )
    })
  }

  // 隐藏
  hideAddGradeGiftReDialog=() => {
    this.setState({showAddGradeGiftReDialog: false});
  }

 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/grade_gift">等级礼包</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="gradeGiftListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="等级类型" name="type">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>用户等级</Option>
                              <Option value={2}>财富等级</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>正常</Option>
                              <Option value={1}>删除</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetGradeGiftPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddGradeGiftDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.gradeGiftList}
                columns={this.state.gradeGiftColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.gradeGiftTotal, defaultCurrent: 1, onChange: this.gradeGiftChangePage}}
              >
              </Table>
              <AddGradeGift close={this.hideAddGradeGiftDialog} visible={this.state.showAddGradeGiftDialog} refreshData={this.refreshGradeGiftData}></AddGradeGift>
              <UpdateGradeGift rowData={this.state.updateGradeGiftRow} close={this.hideUpdateGradeGiftDialog} visible={this.state.showUpdateGradeGiftDialog} refreshData={this.refreshGradeGiftData}></UpdateGradeGift>
              <ShowGradeGiftRe rowData={this.state.showGradeGiftReRow} close={this.hideGradeGiftReDialog} visible={this.state.showGradeGiftReDialog} refreshGradeGiftReData={this.gradeGiftReChangePage} ></ShowGradeGiftRe>
              <AddGradeGiftRe rowData={this.state.addGradeGiftReRow} close={this.hideAddGradeGiftReDialog} visible={this.state.showAddGradeGiftReDialog} refreshGradeGiftReData={this.gradeGiftReChangePage}></AddGradeGiftRe>

        </StickyContainer>
        
      </div>
    );
  }
}
export default GradeGift;