import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import anchorAuthService from '../../../Service/SystemSetting/anchorAuthService';
import UpdateAuthDataSetting from './UpdateAuthDataSetting';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class AuthDataSetting extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 举报列表 ————————————————————————————
    authDataSettingColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '字段名称',
      dataIndex: 'name',
    },{
      key: 'identification',
      title: '字段标识',
      dataIndex: 'identification',
    },{
      key: 'isRequired',
      title: '是否必填',
      dataIndex: 'isRequired',
      render: (isRequired, row) => {
        if(isRequired === 0) {
          return "非必填"
        } else if(isRequired === 1) {
          return "必填";
        } 
      }
    },{
      key: 'isShow',
      title: '是否展示',
      dataIndex: 'isShow',
      render: (isShow, row) => {
        if(isShow === 0) {
          return "不显示"
        } else if(isShow === 1) {
          return "显示";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateAuthDataSettingDialog:true, updateAuthDataSettingRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    authDataSettingTotal: 0,
    authDataSettingList: null,
    updateAuthDataSettingRow: null,
    showUpdateAuthDataSettingDialog: false,
    authDataSettingPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 举报列表 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var anchorAuthParam = {
      pageParam : pageParam,
    }
    anchorAuthService.getAnchorAuthPage(anchorAuthParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({authDataSettingList: res.retValue.list, authDataSettingTotal: res.retValue.total});
        }                  
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 举报列表 ————————————————————————————


  // 用户分页
  authDataSettingChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;

        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          authDataSettingPageParam: pageParam,
        });

        var anchorAuthParam = {
          pageParam : pageParam,
        }
        anchorAuthService.getAnchorAuthPage(anchorAuthParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({authDataSettingList: res.retValue.list, authDataSettingTotal: res.retValue.total});
            }                  
          }
        )

  }

  // 刷新数据
  refreshAuthDataSettingData=() => {
    let { history } = this.props;
    //console.log("refreshAuthDataSettingData");

    var anchorAuthParam = {
      pageParam : this.state.authDataSettingPageParam,
    }
    anchorAuthService.getAnchorAuthPage(anchorAuthParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({authDataSettingList: res.retValue.list, authDataSettingTotal: res.retValue.total});
        }                  
      }
    )
  }

  // 隐藏分成方案弹窗
  hideUpdateAuthDataSettingDialog=() => {
    this.setState({showUpdateAuthDataSettingDialog: false});
  }

  // 隐藏分成方案弹窗
  hideAddAuthDataSettingDialog=() => {
    this.setState({showAddAuthDataSettingDialog: false});
  }
  


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/auth_data_setting">认证资料设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.authDataSettingList}
                columns={this.state.authDataSettingColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.authDataSettingTotal, defaultCurrent: 1, onChange: this.authDataSettingChangePage}}
              >
              </Table>
              <UpdateAuthDataSetting rowData={this.state.updateAuthDataSettingRow} close={this.hideUpdateAuthDataSettingDialog} visible={this.state.showUpdateAuthDataSettingDialog} refreshData={this.refreshAuthDataSettingData}></UpdateAuthDataSetting>

        </StickyContainer>
        
      </div>
    );
  }
}
export default AuthDataSetting;