import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import adminMusicService from '../../../Service/1vs1Mgr/adminMusicService';


import AddMusic from './AddMusic';
import UpdateMusic from './UpdateMusic';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class MusicManagement extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 背景音乐 ————————————————————————————
    musicColumns: [{
      key: 'id',
      title: '音乐ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '歌曲名称',
      dataIndex: 'name'
    },{
      key: 'author',
      title: '原创/作者',
      dataIndex: 'author'
    },{
      key: 'cover',
      title: '歌曲封面',
      dataIndex: 'cover',
      render: (cover, row) => {
        if(cover != null && cover != "") {
          return <Image
              width={50}
              height={50}
              src={cover}
            />
        }
      }
    },{
      key: 'chooseNum',
      title: '点歌次数',
      dataIndex: 'chooseNum'
    },{
      key: 'uploader',
      title: '上传者',
      dataIndex: 'uploader'
    },{
      key: 'sort',
      title: '排序',
      dataIndex: 'sort'
    },{
      key: 'state',
      title: '是否启用',
      dataIndex: 'state',
      render: (state, row) => {
        if(state === 0) {
          return "未启用"
        } else if(state === 1) {
          return "启用";
        } 
      }
    },{
      key: 'addTime',
      title: '添加时间',
      dataIndex: 'addTime',
    },{
      key: 'updateTime',
      title: '修改时间',
      dataIndex: 'updateTime',
    },{
      key: 'lastOperator',
      title: '最后操作人',
      dataIndex: 'lastOperator',
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateMusicDialog:true, updateMusicRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    musicTotal: 0,
    musicList: null,
    updateMusicRow: null,
    showUpdateMusicDialog: false,
    addMusicRow: null,
    showAddMusicDialog: false,
    musicPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 背景音乐 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var musicParam = {
      pageParam : pageParam,
    }
    adminMusicService.getMusicPage(musicParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({musicList: res.retValue.list, musicTotal: res.retValue.total});
        }
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 背景音乐 ————————————————————————————


  // 用户分页
  musicChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          musicPageParam: pageParam,
        });

        var musicParam = {
          pageParam : pageParam,
        }
        adminMusicService.getMusicPage(musicParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({musicList: res.retValue.list, musicTotal: res.retValue.total});
            }
          }
        )
    })
  }

  // 刷新数据
  refreshMusicData=() => {
    let { history } = this.props;

    //console.log("refreshMusicData");

    var musicParam = {
      pageParam : this.state.musicPageParam,
    }
    adminMusicService.getMusicPage(musicParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({musicList: res.retValue.list, musicTotal: res.retValue.total});
        }
      }
    )
  }

  // 隐藏分成方案弹窗
  hideUpdateMusicDialog=() => {
    this.setState({showUpdateMusicDialog: false});
  }

  // 隐藏分成方案弹窗
  hideAddMusicDialog=() => {
    this.setState({showAddMusicDialog: false});
  }
  


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/music_management">背景音乐管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="musicListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddMusicDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.musicList}
                columns={this.state.musicColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.musicTotal, defaultCurrent: 1, onChange: this.musicChangePage}}
              >
              </Table>
              <AddMusic close={this.hideAddMusicDialog} visible={this.state.showAddMusicDialog} refreshData={this.refreshMusicData}></AddMusic>
              <UpdateMusic rowData={this.state.updateMusicRow} close={this.hideUpdateMusicDialog} visible={this.state.showUpdateMusicDialog} refreshData={this.refreshMusicData}></UpdateMusic>

        </StickyContainer>
        
      </div>
    );
  }
}
export default MusicManagement;