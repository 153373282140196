import React, {Component, PropTypes, useRef} from 'react';
import { Modal, message} from 'antd';
import EditPerFrm  from './EditPerFrm';
import privilegeService from '../../../Service/Admin/privilegeService';
import { Logout } from '../../../Common/Auth';


class EditPer extends Component {
    // 取消操作
    hanldeCloseModal = () => {
        this.props.close();
        // 重置表单
        this.refs.editPerFrm.formRef.current.resetFields();
    }

     // 提交操作
     handleSubmit =() => {
        let { history } = this.props;
        this.refs.editPerFrm.formRef.current.validateFields()
        .then(values => {
           //console.log(JSON.stringify(values));
           let privilegeParam = values;
           privilegeService.insertOrUpdatePrivilege(privilegeParam)
           .then(res => {
                if(res == null || res.retCode != 200) {
                  if(res.retCode == 10001) {
                    // 清除登录信息
                    Logout();
                    // 跳转到登录页面
                    history.push('/login');
                    return;
                  }
                  console.log("修改失败")
                  message.info("修改失败,"+res.message);  
                  // 重置关闭对话框
                  this.hanldeCloseModal();
                } else {
                  message.info('修改成功！');
                  // 重置关闭对话框
                  this.hanldeCloseModal();
                  // 刷新数据
                  this.props.refreshData();
                }
            })
           
        })
  
    }


  render() {
    return (
      <Modal 
        destroyOnClose
        title="修改用户信息"
        okText="修改"
        cancelText="取消"
        visible={this.props.visible} 
        onCancel={this.hanldeCloseModal}
        onOk={this.handleSubmit}
      >
        <EditPerFrm rowData={this.props.rowData} ref="editPerFrm"> </EditPerFrm>
      </Modal>
    );
  }
}

export default EditPer;