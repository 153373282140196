import Password from "antd/lib/input/Password";
import axios from "axios";
import Qs from "qs";
import MD5 from 'crypto-js/md5';
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页查询用户
   * @param {*} key 
   * @param {*} pageParam 
   * @returns 
   */
  getUserPage(key, pageParam) {
    let data = {
      key: key,
      pageParam: pageParam,
    };
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/getUserPage",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

 /**
   * 插入更新用户
   * @param {*} userParam 
   * @returns 
   */
   insertOrUpdateUser(userParam) {
    //let forms = new FormData();
    let formData = new FormData();
    Object.keys(userParam).forEach((key) => {
      if(userParam[key] != null || userParam[key] != undefined) {
        if(key == 'password') {
          formData.append(key, userParam[key]);
        } else {
          formData.append(key, userParam[key]);
        }
      }
    });

    return axios({
      headers: {
        "Content-Type": "application/x-www-form-urlencodeds",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/insertOrUpdateUser",
      data: formData,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

 
  /**
   * 根据id删除用户
   * @param {*} idList 
   * @returns 
   */
   deleteUserByIdList(idList) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/deleteUserByIdList",
      data: idList,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 更新用户密码
   * @param {*} userParam 
   * @returns 
   */
  updatePasswordById(userParam) {
    //console.log("userParam=" + JSON.stringify(userParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/updatePasswordById",
      data: userParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}
