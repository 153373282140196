import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo,Logout } from '../../../Common/Auth';
import moment from 'moment'
import appUserService from '../../../Service/UserMgr/appUserService';
import userBindSuperiorRecordService from '../../../Service/UserMgr/userBindSuperiorRecordService';




const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class HostUser extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 绑定操作记录 ————————————————————————————
    recordColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'superiorId',
      title: '上级ID',
      dataIndex: 'superiorId'
    },{
      key: 'superiorType',
      title: '上级类型',
      dataIndex: 'superiorType',
      render: (superiorType, row) => {
        if(superiorType === 1) {
          return "用户上级"
        } else if(superiorType === 2) {
          return "代理上级";
        }
      }
    },{
      key: 'addDate',
      title: '记录添加时间',
      dataIndex: 'addDate'
    },{
      key: 'fromSource',
      title: '绑定来源',
      dataIndex: 'fromSource',
      render: (fromSource, row) => {
        if(fromSource === 1) {
          return "手动填写"
        } else if(fromSource === 2) {
          return "openInstall";
        } else if(fromSource === 3) {
          return "后台操作";
        }
      }
    },{
      key: 'optType',
      title: '操作类型',
      dataIndex: 'optType',
      render: (optType, row) => {
        if(optType === 1) {
          return "绑定"
        } else if(optType === 2) {
          return "解除绑定";
        }
      }
    },{
      key: 'optName',
      title: '后台操作人',
      dataIndex: 'optName',
    },{
      key: 'optAdminId',
      title: '后台操作人ID',
      dataIndex: 'optAdminId',
    }],
    recordTotal: 0,
    recordList: null,

  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 绑定 ————————————————————————————

  // 绑定操作
  bindUserSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
         //console.log(JSON.stringify(values));
         let admin = GetLoginUserInfo();
         var bindSuperiorParam = {
            superiorType: values.superiorType,
            superiorId: values.superiorId,
            userId: values.userId,
            optType: 1,
            optId: admin.userId,
            optUserName: admin.userName,
         }
         appUserService.userBindSuperior(bindSuperiorParam)
         .then(res => {
             if(res != null && res.retCode == 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info('修改成功！');
                this.formRef.current.resetFields();
              } else {
                if(res != null) {
                  message.error(res.message);
                } else {
                  message.error("服务端错误！");
                }
              } 
          })
         
      })
  }

    // 解除绑定操作
    unBindUserSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
         //console.log(JSON.stringify(values));
         let admin = GetLoginUserInfo();
         var bindSuperiorParam = {
            superiorType: values.superiorType,
            superiorId: values.superiorId,
            userId: values.userId,
            optType: 2,
            optId: admin.userId,
            optUserName: admin.userName,
         }
         appUserService.userBindSuperior(bindSuperiorParam)
         .then(res => {
            if(res != null && res.retCode == 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info('修改成功！');
              this.formRef.current.resetFields();
            } else {
              if(res != null) {
                message.error(res.message);
              } else {
                message.error("服务端错误！");
              }
            } 
          })
         
      })
  }
  
  // ———————————————————————————— 绑定操作记录 ——————————————————————————————————

  // 获取主播分成方案
  handleGetRecordPage=() =>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var userBindSuperiorRecordParam = {
        userId : values.userId,
        superiorId : values.superiorId,
        superiorType : values.superiorType,
        optType : values.optType,
        pageParam : pageParam,
      }
      userBindSuperiorRecordService.getRecordPage(userBindSuperiorRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({recordList: res.retValue.list, recordTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  }


  // 用户分页
  recordChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var userBindSuperiorRecordParam = {
          userId : values.userId,
          superiorId : values.superiorId,
          superiorType : values.superiorType,
          optType : values.optType,
          pageParam : pageParam,
        }
        userBindSuperiorRecordService.getRecordPage(userBindSuperiorRecordParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({recordList: res.retValue.list, recordTotal: res.retValue.total});
            }                  
          }
        )
    })
  }


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "2") {
      this.getUserBindSuperiorRecord();
    } 
  }
  
  /**
   * 绑定记录
   */
  getUserBindSuperiorRecord = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var userBindSuperiorRecordParam = {
      pageParam : pageParam,
    }
    userBindSuperiorRecordService.getRecordPage(userBindSuperiorRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({recordList: res.retValue.list, recordTotal: res.retValue.total});
        }                  
      }
    )
  }


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/bind_user">绑定用户</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 绑定 ——————————————————————
            <TabPane tab="绑定" key="1">
                <Form
                  name="BindUserForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                >
                      <Form.Item label="用户ID" name="userId" rules={ [
                          {
                              required: true,
                              message: '请输入用户ID!',
                          }
                          ]
                      }>
                        <Input style={{ width: 320 }} placeholder="用户ID" />
                      </Form.Item>

                      <Form.Item label="上级类型" name="superiorType" initialValue={1} rules={ [
                          {
                              required: true,
                              message: '请输入用户ID!',
                          }
                          ]
                      }>
                        <Select style={{ width: 320 }} defaultValue={1}>
                            <Option value={1}>用户上级</Option>
                            <Option value={2}>代理上级</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="上级ID" name="superiorId" rules={ [
                          {
                              required: true,
                              message: '请输入上级ID',
                          }
                          ]
                      }>
                        <Input style={{ width: 320 }} placeholder="上级ID" />
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.bindUserSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">绑定</Button></span>
                  <span onClick={ this.unBindUserSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">解绑</Button></span>
                </div>

            </TabPane>


            // ———————————————————— 绑定操作记录 ————————————————————————————
            <TabPane tab="绑定操作记录" key="2">

              <Form
                name="recordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="用户UID" name="userId">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="上级ID" name="superiorId">
                          <Input placeholder="上级ID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="上级类型" name="superiorType">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>用户上级</Option>
                              <Option value={2}>代理上级</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="操作类型" name="optType">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>绑定</Option>
                              <Option value={2}>解除绑定</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetRecordPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.recordList}
                columns={this.state.recordColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.recordTotal, defaultCurrent: 1, onChange: this.recordChangePage}}
              >
              </Table>

            </TabPane>  


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default HostUser;