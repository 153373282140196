import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import userVideoService from '../../../Service/Dynamic/userVideoService';
import usersVideoCommentsService from '../../../Service/Dynamic/usersVideoCommentsService';

import UpdateDynamic from './UpdateDynamic';
import VideoPlay from '../../UserMgr/HostUser/VideoPlay';
import ShowDynamicComment from './ShowDynamicComment';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class DynamicList extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 动态列表 ————————————————————————————
    dynamicListColumns: [{
      key: 'id',
      title: '动态ID',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 1) {
          return "男"
        } else if(sex === 2) {
          return "女";
        } 
      }
    },{
      key: 'title',
      title: '标题',
      dataIndex: 'title'
    },{
      key: 'images',
      title: '图片',
      dataIndex: 'images',
      render: (images, row) => {
        if(images != null && images != "") {
          return (
            <div style={{ display: "flex" }}>
              {images.split(",").map((items, index) => {
                return (
                  <div
                  key={index}
                  className="common-img-list"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginLeft: "4px",
                    overflow: "hidden"
                  }}
                >
                  <Image
                    width={80}
                    height={80}
                    src={items}
                  />
                </div>
                );
              })}
            </div>
          );
            
        }
      }
    },{
      key: 'href',
      title: '视频',
      dataIndex: 'href',
      render: (href, row) => {
        if(href != null && href != "") {

          return <Button type="primary" 
                          onClick={() => this.handlePlayVideo(href)}
                          style={{ marginRight: '15px' }}>
                观看
              </Button>
        }
      }
    },{
      key: 'likes',
      title: '点赞数',
      dataIndex: 'likes'
    },{
      key: 'comments',
      title: '评论数',
      dataIndex: 'comments'
    },{
      key: 'shares',
      title: '分享数',
      dataIndex: 'shares'
    },{
      key: 'isRecommend',
      title: '是否推荐',
      dataIndex: 'isRecommend',
      render: (isRecommend, row) => {
        if(isRecommend === 0) {
          return "不推荐"
        } else if(isRecommend === 1) {
          return "推荐中";
        } 
      }
    },{
      key: 'status',
      title: '上下架状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 1) {
          return "上架"
        } else if(status === 2) {
          return "下架";
        } 
      }
    },{
      key: 'isEnableMonitoring',
      title: '是否鉴黄',
      dataIndex: 'isEnableMonitoring',
      render: (isEnableMonitoring, row) => {
        if(isEnableMonitoring === 0) {
          return "未开启"
        } else if(isEnableMonitoring === 1) {
          return "鉴黄中";
        } else if(isEnableMonitoring === 2) {
          return "鉴黄通过";
        } 
      }
    },{
      key: 'addtime',
      title: '发布时间',
      dataIndex: 'addtime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.showDynamicComment(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">查看评论
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateDynamicListDialog:true, updateDynamicListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">审核
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteDynamic(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    DynamicListTotal: 0,
    DynamicListList: null,
    updateDynamicListRow: null,
    showUpdateDynamicListDialog: false,
    showDynamicCommentRow: null,
    showDynamicCommentDialog: false,

    videoRow: null,
    showVideoDialog: false,
    showVideoButton: false,
    dynamicListPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var usersVideoParam = {
      pageParam : pageParam,
    }
    userVideoService.getUserVideoPage(usersVideoParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({dynamicListList: res.retValue.list, dynamicListTotal: res.retValue.total});
        }
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 动态列表 ————————————————————————————

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
   showDynamicComment = (id) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var usersVideoCommentsParam = {
      videoid: id,
      pageParam: pageParam,
    };
    // 分页获取动态评论
    usersVideoCommentsService.getCommentPage(usersVideoCommentsParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.showDynamicCommentRow = {
          videoid: id,
          commentList : res.retValue.list,
          commentTotal: res.retValue.total
        };
  
        this.setState({ showDynamicCommentDialog: true });

      }
      
    });
  };

  /**
   * 动态评论分页
   * @param {*} page 
   * @param {*} pageSize 
   * @param {*} videoid 
   */
   dynamicCommentChangePage=(page, pageSize, videoid) => {
    //console.log("changePage 进入");

    this.formRef.current.validateFields()
    .then(values => {
        let { history } = this.props;
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var usersVideoCommentsParam = {
          videoid: videoid,
          pageParam: pageParam,
        };

        // 分页获取动态评论
        usersVideoCommentsService.getCommentPage(usersVideoCommentsParam)
        .then((res) => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if (res.retValue == null) {
              return;
            }
      
            this.state.showDynamicCommentRow = {
              videoid: videoid,
              commentList : res.retValue.list,
              commentTotal: res.retValue.total
            };
      
            this.setState({ showDynamicCommentDialog: true });
          }
          
        });
    })
  }

  // 获取动态列表
  handleGetDynamicListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var usersVideoParam = {
        title: values.title,
        userId : values.userId,
        pageParam : pageParam,
      }
      userVideoService.getUserVideoPage(usersVideoParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({dynamicListList: res.retValue.list, dynamicListTotal: res.retValue.total});
          }
        }
      )
      
    })

  }

  // 用户分页
  dynamicListChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          dynamicListPageParam: pageParam,
        });

        var usersVideoParam = {
          title: values.title,
          userId : values.userId,
          pageParam : pageParam,
        }
        userVideoService.getUserVideoPage(usersVideoParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({dynamicListList: res.retValue.list, dynamicListTotal: res.retValue.total});
            }
          }
        )
    })
  }

  // 刷新数据
  refreshDynamicListData=() => {
    let { history } = this.props;
    //console.log("refreshDynamicListData");

    var usersVideoParam = {
      pageParam : this.state.dynamicListPageParam,
    }
    userVideoService.getUserVideoPage(usersVideoParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({dynamicListList: res.retValue.list, dynamicListTotal: res.retValue.total});
        }
      }
    )
  }

  // 隐藏
  hideUpdateDynamicListDialog=() => {
    this.setState({showUpdateDynamicListDialog: false});
  }
 

  // 关闭用户审核弹框
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }

  // 隐藏动态评论弹窗
  hideDynamicCommentDialog=() => {
    this.setState({showDynamicCommentDialog: false});
  }


  /**
   * 播放视频
   */
  handlePlayVideo=(href) => {
  
    //console.log("href=" + href)
    this.setState({videoRow: {
        videoUrl : href ,
    }});
    this.setState({showVideoDialog:true })
  }
  
  // 根据Id删除标签
  deleteDynamic=(id) => {
      let { history } = this.props;
      let usersVideoParam = {
        id : id
      };
      userVideoService.deleteUserVideo(usersVideoParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshDynamicListData();
          message.success('删除成功！');
        }
       
      })

  }
 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/dynamic_list">动态列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="DynamicListListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="标题" name="title">
                          <Input placeholder="标题" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="UID" name="userId">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetDynamicListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.dynamicListList}
                columns={this.state.dynamicListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.dynamicListTotal, defaultCurrent: 1, onChange: this.dynamicListChangePage}}
              >
              </Table>
              <UpdateDynamic rowData={this.state.updateDynamicListRow} close={this.hideUpdateDynamicListDialog} visible={this.state.showUpdateDynamicListDialog} refreshData={this.refreshDynamicListData}></UpdateDynamic>
              <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>
              <ShowDynamicComment rowData={this.state.showDynamicCommentRow} close={this.hideDynamicCommentDialog} visible={this.state.showDynamicCommentDialog} refreshCommentData={this.dynamicCommentChangePage} ></ShowDynamicComment>

        </StickyContainer>
        
      </div>
    );
  }
}
export default DynamicList;