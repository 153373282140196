import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import appUserService from '../../../Service/UserMgr/appUserService';
import liveRoomRecordService from '../../../Service/1vs1Mgr/liveRoomRecordService';

import ShowAnswerRecord from './ShowAnswerRecord';
import ShowDialRecord from './ShowDialRecord';
import UpdateOnline from './UpdateOnline';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class OnevsOneUser extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state={
    // ———————————————————— 普通用户 ————————————————————————————

    userColumns: [{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '用户性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'toDayOnlineTime',
      title: '今日在线时长',
      dataIndex: 'toDayOnlineTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus',
      render: (onlineStatus, row) => {
        if(onlineStatus === 0) {
          return "离线"
        } else if(onlineStatus === 1) {
          return "在线";
        } 
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'pid',
      title: '上级推荐人',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'registerCallSecond',
      title: '剩余注册赠送通话次数',
      dataIndex: 'registerCallSecond'
    },{
      key: 'registerCallTime',
      title: '注册赠送通话时间',
      dataIndex: 'registerCallTime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.showDialRecordList(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">拨打记录 
              </Button>
              <Button 
                onClick={() => this.showAnswerRecordList(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">接听记录 
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateOnlineUserDialog:true, updateOnlineUserRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改在线状态 
              </Button>
            </div>
          )
      }
    }],
    usertotal: 0,
    userlist: null,
    answerRecordRow: null,
    showAnswerRecordDialog: false,
    dialRecordRow: null,
    showDialRecordDialog: false,
    updateOnlineUserRow: null,
    showUpdateOnlineUserDialog: false,
    userPageParam: {
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 主播用户 ————————————————————————————
  
    authorColumns: [{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '用户性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'toDayOnlineTime',
      title: '今日在线时长',
      dataIndex: 'toDayOnlineTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus',
      render: (onlineStatus, row) => {
        if(onlineStatus === 0) {
          return "离线"
        } else if(onlineStatus === 1) {
          return "在线";
        } 
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'pid',
      title: '上级推荐人',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'registerCallSecond',
      title: '剩余注册赠送通话次数',
      dataIndex: 'registerCallSecond'
    },{
      key: 'registerCallTime',
      title: '注册赠送通话时间',
      dataIndex: 'registerCallTime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.showDialRecordList(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">拨打记录 
              </Button>
              <Button 
                onClick={() => this.showAnswerRecordList(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">接听记录 
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateOnlineAuthorDialog:true, updateOnlineAuthorRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改在线状态 
              </Button>
            </div>
          )
      }
    }],
    authortotal: 0,
    authorlist: null,
    updateOnlineAuthorRow: null,
    showUpdateOnlineAuthorDialog: false,
    authorPageParam: {
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 通话记录 ————————————————————————————
    liveRoomRecordColumns: [{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'roomId',
      title: '房间号',
      dataIndex: 'roomId'
    },{
      key: 'showid',
      title: '通话标识',
      dataIndex: 'showid'
    },{
      key: 'dialAvatar',
      title: '拨打用户信息',
      dataIndex: 'dialAvatar',
      align: 'center',
      render: (dialAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.audienceId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={dialAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.dialUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.dialMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'isVideo',
      title: '通话方式',
      dataIndex: 'isVideo',
      render: (isVideo, row) => {
        if(isVideo === 0) {
          return "语音"
        } else if(isVideo === 1) {
          return "视频";
        } 
      }
    },{
      key: 'type',
      title: '通话类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 0) {
          return "私聊"
        } else if(type === 1) {
          return "群聊";
        } 
      }
    },{
      key: 'answerAvatar',
      title: '接听用户信息',
      dataIndex: 'answerAvatar',
      align: 'center',
      render: (answerAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={answerAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.answerUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.answerMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'time',
      title: '通话时长',
      dataIndex: 'time',
      render: (time, row) => {
        return time + "秒";
      }
    },{
      key: 'oooFee',
      title: '通话单价',
      dataIndex: 'oooFee',
    },{
      key: 'totalCoin',
      title: '通话收益',
      dataIndex: 'totalCoin'
    },{
      key: 'giftCoin',
      title: '打赏映票',
      dataIndex: 'giftCoin'
    },{
      key: 'total',
      title: '本次通话收益',
      dataIndex: 'total'
    },{
      key: 'startTime',
      title: '开始时间',
      dataIndex: 'startTime'
    },{
      key: 'endTime',
      title: '结束时间',
      dataIndex: 'endTime'
    }],
    authortotal: 0,
    authorlist: null,
    liveRoomRecordList: [],
    liveRoomRecordTotal: 0,
  

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 普通用户 ————————————————————————————

    this.getAppUser1();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 普通用户 ————————————————————————————


  // 获取普通用户
  handleGetUser=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
       console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var userParam = {
        userid: values.userid,
        role: 0,
        onlineStatus: values.onlineStatus,
        pageParam: pageParam,
      }

      appUserService.getOnevsOneUserPage(userParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
          }
        }
      )
       
    })
   
  }

  // 用户分页
  userChangePage=(page, pageSize) => {
      let { history } = this.props;
      //console.log("changePage 进入");
      this.formRef.current.validateFields()
      .then(values => {
          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }

          this.setState({
            userPageParam: pageParam,
          });

          var userParam = {
            userid: values.userid,
            role: 0,
            onlineStatus: values.onlineStatus,
            pageParam: pageParam,
          }
    
          appUserService.getOnevsOneUserPage(userParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("查询失败,"+res.message);  
              } else {
                this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
              }
            }
          )

      });

    }

  // 刷新数据
  refreshUserData=() => {
    //console.log("refreshSimulationMssageData");
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then(values => {
      var userParam = {
        userid: values.userid,
        role: 0,
        onlineStatus: values.onlineStatus,
        pageParam: this.state.userPageParam,
      }

      appUserService.getOnevsOneUserPage(userParam)
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
        }
      )
    });
  }

  // 关闭添加用户弹框
  hideDialRecordDialog=() => {
    this.setState({showDialRecordDialog: false});
  }

  // 关闭编辑用户弹框
  hideAnswerRecordDialog=() => {
    this.setState({showAnswerRecordDialog: false});
  }

  // 关闭弹框
  hideUpdateOnlineUserDialog=() => {
    this.setState({showUpdateOnlineUserDialog: false});
  }

  /**
   * 接听电话列表
   * @param {*} userId 
   */
  showAnswerRecordList=(userId) => {
    let { history } = this.props;

    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    }

    var liveRoomRecordParam = {
      userId: userId,
      pageParam : pageParam,
    }
    liveRoomRecordService.getAnswerRecordPage(liveRoomRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.state.answerRecordRow = {
            userId: userId,
            answerRecordList : res.retValue.list,
            answerRecordTotal: res.retValue.total
          }
          this.setState({showAnswerRecordDialog:true})
        }
      
      }
    )
  }

  /**
   * 接听电话列表分页
   * @param {*} page 
   * @param {*} pageSize 
   * @param {*} userId 
   */
  answerRecordChangePage=(page, pageSize, userId) => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var liveRoomRecordParam = {
          userId: userId,
          pageParam : pageParam,
        }
        liveRoomRecordService.getAnswerRecordPage(liveRoomRecordParam)
        .then(
          res => {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            this.state.answerRecordRow = {
              userId: userId,
              answerRecordList : res.retValue.list,
              answerRecordTotal: res.retValue.total
            }
            this.setState({showAnswerRecordDialog:true})
    
          }
        )
    })
  }


  /**
   * 拨打电话列表
   * @param {*} userId 
   */
    showDialRecordList=(userId) => {
      let { history } = this.props;

      var pageParam = {
        pageNum: 1,
        pageSize: 10,
      }
  
      var liveRoomRecordParam = {
        userId: userId,
        pageParam : pageParam,
      }
      liveRoomRecordService.getDialRecordPage(liveRoomRecordParam)
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          this.state.dialRecordRow = {
            userId: userId,
            dialRecordList : res.retValue.list,
            dialRecordTotal: res.retValue.total
          }
          this.setState({showDialRecordDialog:true})
  
        }
      )
    }


  /**
   * 拨打电话列表分页
   * @param {*} page 
   * @param {*} pageSize 
   * @param {*} userId 
   */
  dialRecordChangePage=(page, pageSize, userId) => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var liveRoomRecordParam = {
          userId: userId,
          pageParam : pageParam,
        }
        liveRoomRecordService.getDialRecordPage(liveRoomRecordParam)
        .then(
          res => {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            this.state.dialRecordRow = {
              userId: userId,
              dialRecordList : res.retValue.list,
              dialRecordTotal: res.retValue.total
            }
            this.setState({showDialRecordDialog:true})
    
          }
        )
    })
  }

  
  
  // ———————————————————————————— 主播用户 ——————————————————————————————————
 // 获取主播用户
 handleGetAuthor=() =>{
  let { history } = this.props;

  this.formRef2.current.validateFields()
  .then(values => {
     //console.log(JSON.stringify(values));
     var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var authorParam = {
      userid: values.userid,
      role: 1,
      onlineStatus: values.onlineStatus,
      pageParam: pageParam,
    }

    appUserService.getOnevsOneUserPage(authorParam)
    .then(
      res => {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        this.setState({authorlist: res.retValue.list, authortotal: res.retValue.total});
      }
    )
     
  })

}

// 用户分页
authorChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef2.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          authorPageParam: pageParam,
        });

        var authorParam = {
          userid: values.userid,
          role: 1,
          onlineStatus: values.onlineStatus,
          pageParam: pageParam,
        }
  
        appUserService.getOnevsOneUserPage(authorParam)
        .then(
          res => {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            this.setState({authorlist: res.retValue.list, authortotal: res.retValue.total});
          }
        )

    });

  }


  // 刷新数据
  refreshAuthorData=() => {
    //console.log("refreshSimulationMssageData");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      var authorParam = {
        userid: values.userid,
        role: 1,
        onlineStatus: values.onlineStatus,
        pageParam: this.state.authorPageParam,
      }

      appUserService.getOnevsOneUserPage(authorParam)
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          this.setState({authorlist: res.retValue.list, authortotal: res.retValue.total});
        }
      )
    });
  }

  // 关闭弹框
  hideUpdateOnlineAuthorDialog=() => {
    this.setState({showUpdateOnlineAuthorDialog: false});
  }



  // ———————————————————————————— 通话记录 ——————————————————————————————————

  // 获取主播用户
  handleGetLiveRoomRecord=() =>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.addTime != null) {
        timeStart = values.addTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.addTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var liveRoomRecordParam = {
        dialUserId: values.dialUserId,
        answerUserId: values.answerUserId,
        roomId: values.roomId,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam : pageParam,
      }

      liveRoomRecordService.getLiveRoomRecordPage(liveRoomRecordParam)
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          this.setState({liveRoomRecordList: res.retValue.list, liveRoomRecordTotal: res.retValue.total});
        }
      )
      
    })
 
  }

  // 用户分页
  liveRoomRecordChangePage=(page, pageSize) => {
      //console.log("changePage 进入");
      let { history } = this.props;
      this.formRef3.current.validateFields()
      .then(values => {
          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }

          var timeStart = null;
          var timeEnd = null;
          if(values.addTime != null) {
            timeStart = values.addTime[0].format('YYYY-MM-DD') + " 00:00:00";
            timeEnd = values.addTime[1].format('YYYY-MM-DD' + " 23:59:59");
          }
    
          var liveRoomRecordParam = {
            dialUserId: values.dialUserId,
            answerUserId: values.answerUserId,
            roomId: values.roomId,
            startTime: timeStart,
            endTime: timeEnd,
            pageParam : pageParam,
          }
    
          liveRoomRecordService.getLiveRoomRecordPage(liveRoomRecordParam)
          .then(
            res => {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              this.setState({liveRoomRecordList: res.retValue.list, liveRoomRecordTotal: res.retValue.total});
            }
          )

      });

    }  
  
    
  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getAppUser1();
    } else if(key == "2") {
      this.getAppUser2();
    } else if(key == "3") {
      this.getLiveRoomRecord();
    }
  }
  
  /**
   * 普通用户
   */
  getAppUser1 = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var userParam = {
      role: 0,
      pageParam: pageParam,
    }

    appUserService.getOnevsOneUserPage(userParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 主播用户
   */
  getAppUser2 = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var authorParam = {
      role: 1,
      pageParam: pageParam,
    }

    appUserService.getOnevsOneUserPage(authorParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({authorlist: res.retValue.list, authortotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 通话记录
   */
  getLiveRoomRecord = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var liveRoomRecordParam = {
      pageParam : pageParam,
    }
    liveRoomRecordService.getLiveRoomRecordPage(liveRoomRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({liveRoomRecordList: res.retValue.list, liveRoomRecordTotal: res.retValue.total});
        }
      }
    ) 
  }


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/1vs1_user">1VS1用户</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 普通用户 ——————————————————————
            <TabPane tab="普通用户" key="1">
              
              <Form
                name="userListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="真实在线状态" name="onlineStatus">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>离线</Option>
                              <Option value={1}>在线</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetUser} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userlist}
                columns={this.state.userColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.usertotal, defaultCurrent: 1, onChange: this.userChangePage}}
              >
              </Table>
              <ShowAnswerRecord rowData={this.state.answerRecordRow} close={this.hideAnswerRecordDialog} visible={this.state.showAnswerRecordDialog} refreshAnswerRecordData={this.answerRecordChangePage} ></ShowAnswerRecord>
              <ShowDialRecord rowData={this.state.dialRecordRow} close={this.hideDialRecordDialog} visible={this.state.showDialRecordDialog} refreshDialRecordData={this.dialRecordChangePage} ></ShowDialRecord>
              <UpdateOnline rowData={this.state.updateOnlineUserRow} close={this.hideUpdateOnlineUserDialog} visible={this.state.showUpdateOnlineUserDialog} refreshData={this.refreshUserData} ></UpdateOnline>

            
            </TabPane>


            // ———————————————————— 主播用户 ————————————————————————————
            <TabPane tab="主播用户" key="2">
              <Form
                name="AuthorListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={8} key={3}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="真实在线状态" name="onlineStatus">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>离线</Option>
                              <Option value={1}>在线</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetAuthor} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.authorlist}
                columns={this.state.authorColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.authortotal, defaultCurrent: 1, onChange: this.authorChangePage}}
              >
              </Table>
              <ShowAnswerRecord rowData={this.state.answerRecordRow} close={this.hideAnswerRecordDialog} visible={this.state.showAnswerRecordDialog} refreshAnswerRecordData={this.answerRecordChangePage} ></ShowAnswerRecord>
              <ShowDialRecord rowData={this.state.dialRecordRow} close={this.hideDialRecordDialog} visible={this.state.showDialRecordDialog} refreshDialRecordData={this.dialRecordChangePage} ></ShowDialRecord>
              <UpdateOnline rowData={this.state.updateOnlineAuthorRow} close={this.hideUpdateOnlineAuthorDialog} visible={this.state.showUpdateOnlineAuthorDialog} refreshData={this.refreshAuthorData} ></UpdateOnline>

              
            </TabPane>

            // —————————————————————— 通话记录 ————————————————————————
            <TabPane tab="通话记录" key="3">
              <Form
                name="LiveRoomRecordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
              >
                <Row gutter={24}>
                    <Col span={8} key={5}>
                      <Form.Item label="选择时间" name="addTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                      <Form.Item label="发起方UID" name="dialUserId">
                          <Input placeholder="发起方UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={7}>
                      <Form.Item label="接收方UID" name="answerUserId">
                          <Input placeholder="接收方UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={8}>
                      <Form.Item label="房间号" name="roomId">
                          <Input placeholder="房间号" />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetLiveRoomRecord} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.liveRoomRecordList}
                columns={this.state.liveRoomRecordColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.liveRoomRecordTotal, defaultCurrent: 1, onChange: this.liveRoomRecordChangePage}}
              >
              </Table>
            
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default OnevsOneUser;