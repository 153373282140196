import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import payRecordService from "../../../Service/FinanceMgr/payRecordService";




const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class PaymentRecord extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 用户特权 ————————————————————————————

    paymentRecordColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderNo",
        title: "订单号",
        dataIndex: "orderNo",
      },
      {
        key: "thrOrderNo",
        title: "第三方订单号",
        dataIndex: "thrOrderNo",
      },
      {
        key: "avatar",
        title: "用户信息",
        dataIndex: "avatar",
        align: 'center',
        render: (avatar, row) => {
          var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.userId}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={avatar} />
              </div>
              <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.username}</div>
              <div style={{ marginTop: '3px'}}>{row.mobile}</div>
              </div>
            </>
          );
          return info;
        }
      },
      {
        key: "amount",
        title: "支付金额",
        dataIndex: "amount",
      },
      {
        key: "type",
        title: "业务类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "充值金币"
          } else if(type === 2) {
            return "购物支付";
          } else if(type === 3) {
            return "待定";
          } else if(type === 4) {
            return "购买贵族";
          } else if(type === 5) {
            return "购买svip";
          } else if(type === 6) {
            return "购买守护";
          }
        }
      },
      {
        key: "payType",
        title: "支付类型",
        dataIndex: "payType",
        render: (payType, row) => {
          if(payType === 1) {
            return "支付宝"
          } else if(payType === 2) {
            return "微信";
          } else if(payType === 3) {
            return "苹果";
          } 
        }
      },
      {
        key: "state",
        title: "支付状态",
        dataIndex: "state",
        render: (state, row) => {
          if(state === 1) {
            return "支付处理中"
          } else if(state === 2) {
            return "支付成功";
          } else if(state === 3) {
            return "已取消";
          } 
        }
      },
      {
        key: "addTime",
        title: "支付发起时间",
        dataIndex: "addTime",
      },
      {
        key: "notifyTime",
        title: "完成时间",
        dataIndex: "notifyTime",
      }
    ],
    paymentRecordTotal: 0,
    paymentRecordList: null,

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 支付记录 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var paymentRecordParam = {
      pageParam: pageParam,
    };
    payRecordService.getPayRecordPage(paymentRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          paymentRecordList: res.retValue.list,
          paymentRecordTotal: res.retValue.total,
        });
      }
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 支付记录 ————————————————————————————
  // 获取等级礼物
  handleGetPaymentRecordPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var paymentRecordParam = {
        orderNo: values.orderNo,
        thrOrderNo: values.thrOrderNo,
        userId: values.userId,
        state: values.state,
        pageParam: pageParam,
      };

      payRecordService.getPayRecordPage(paymentRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            paymentRecordList: res.retValue.list,
            paymentRecordTotal: res.retValue.total,
          });
        }
       
      });
      
    })

  }



  // 贵族特权分页
  paymentRecordChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var paymentRecordParam = {
        orderNo: values.orderNo,
        thrOrderNo: values.thrOrderNo,
        userId: values.userId,
        state: values.state,
        pageParam: pageParam,
      };
      payRecordService.getPayRecordPage(paymentRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            paymentRecordList: res.retValue.list,
            paymentRecordTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshPaymentRecordData = () => {
    let { history } = this.props;
    console.log("refreshPaymentRecordData");
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };

    var paymentRecordParam = {
      pageParam: pageParam,
    };
    payRecordService.getPayRecordPage(paymentRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          paymentRecordList: res.retValue.list,
          paymentRecordTotal: res.retValue.total,
        });
      }
      
    });
  };



  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/payment_record">支付记录</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="PaymentRecordListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={1}>
                    <Form.Item label="订单号" name="orderNo">
                        <Input placeholder="订单号"/>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item label="第三方订单号" name="thrOrderNo">
                        <Input placeholder="第三方订单号"/>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={3}>
                    <Form.Item label="用户UID" name="userId">
                        <Input placeholder="用户UID"/>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={4}>
                      <Form.Item label="支付状态" name="state">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>支付处理中</Option>
                              <Option value={2}>支付成功</Option>
                              <Option value={3}>已取消</Option>
                          </Select>
                      </Form.Item>
                  </Col>
                  <Col span={6} key={5} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetPaymentRecordPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.paymentRecordList}
              columns={this.state.paymentRecordColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.paymentRecordTotal,
                defaultCurrent: 1,
                onChange: this.paymentRecordChangePage,
              }}
            ></Table>

        </StickyContainer>
      </div>
    );
  }
}
export default PaymentRecord;
