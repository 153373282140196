import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import vipService from "../../../Service/NobleMgr/vipService";
import gradeService from "../../../Service/GradeMgr/gradeService";
import textDescriptionService from "../../../Service/NobleMgr/textDescriptionService";


import UpdateNoblePrice from './UpdateNoblePrice';
import AddNoblePrice from './AddNoblePrice';
import UpdateAndroidText from './UpdateAndroidText';
import UpdateIOSText from './UpdateIOSText';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class NoblePrice extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 用户特权 ————————————————————————————

    noblePriceColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "排序",
        dataIndex: "orderno",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "coin",
        title: "金币价格",
        dataIndex: "coin",
      },
      {
        key: "androidCoin",
        title: "安卓价格",
        dataIndex: "androidCoin",
      },
      {
        key: "iosCoin",
        title: "ios价格",
        dataIndex: "iosCoin",
      },
      {
        key: "length",
        title: "时长（月）",
        dataIndex: "length",
      },
      {
        key: "payType",
        title: "赠送金币",
        dataIndex: "payType",
      },
      {
        key: "payType",
        title: "支付方式",
        dataIndex: "payType",
        render: (payType, row) => {
          if(payType === 1) {
            return "金币"
          } else if(payType === 2) {
            return "RMB";
          } else if(payType === 3) {
            return "人民币/金币";
          }
        }
      },
      {
        key: "grade",
        title: "贵族等级",
        dataIndex: "grade",
        render: (grade, row) => {
          if(grade === 1) {
            return "骑士贵族"
          } else if(grade === 2) {
            return "青铜贵族";
          } else if(grade === 3) {
            return "白银贵族";
          } else if(grade === 4) {
            return "黄金贵族";
          } else if(grade === 5) {
            return "至尊贵族";
          }
        }
      },
      {
        key: "isTip",
        title: "是否启用",
        dataIndex: "isTip",
        render: (isTip, row) => {
          if(isTip === 0) {
            return "不启用"
          } else if(isTip === 1) {
            return "启用";
          } 
        }
      },
      {
        key: "iosId",
        title: "苹果项目ID",
        dataIndex: "iosId",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateNoblePriceRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    noblePriceTotal: 0,
    noblePriceList: null,
    updateNoblePriceRow: null,
    showUpdateNoblePriceDialog: false,
    addNoblePriceRow: null,
    showAddNoblePriceDialog: false,
    nobleGradeList: null,
    updateAndroidTextRow: null,
    showUpdateAndroidTextDialog: false,
    updateIOSTextRow: null,
    showUpdateIOSTextDialog: false,
    noblePricePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // 等级列表
    var gradeParam = {
      type: 4
    }

    gradeService.getGradeList(gradeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          nobleGradeList: res.retValue
        });
      }                  
      
    });

    // ———————————————————— 贵族特权 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var vipParam = {
      pageParam: pageParam,
    };
    vipService.getVipPage(vipParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          noblePriceList: res.retValue.list,
          noblePriceTotal: res.retValue.total,
        });
      }                  
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 贵族特权 ————————————————————————————
  // 分页获取贵族价格
  handleGetNoblePricePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var vipParam = {
        grade: values.grade,
        name: values.name,
        pageParam : pageParam,
      }
      vipService.getVipPage(vipParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            noblePriceList: res.retValue.list,
            noblePriceTotal: res.retValue.total,
          });
        }                  
        
      });
      
    })

  }



  // 贵族特权分页
  noblePriceChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        noblePricePageParam: pageParam,
      });

      var vipParam = {
        grade: values.grade,
        name: values.name,
        pageParam : pageParam,
      }
      vipService.getVipPage(vipParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            noblePriceList: res.retValue.list,
            noblePriceTotal: res.retValue.total,
          });
        }                  
        
      });
    });
  };

  // 刷新数据
  refreshNoblePriceData = () => {
    let { history } = this.props;
    //console.log("refreshNoblePriceData");
  
    var vipParam = {
      pageParam : this.state.noblePricePageParam,
    }
    vipService.getVipPage(vipParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          noblePriceList: res.retValue.list,
          noblePriceTotal: res.retValue.total,
        });
      }                  

    });
  };

  // 隐藏
  hideUpdateNoblePriceDialog = () => {
    this.setState({ showUpdateNoblePriceDialog: false });
  };

  // 隐藏
  hideAddNoblePriceDialog = () => {
    this.setState({ showAddNoblePriceDialog: false });
  };

  // 隐藏
  hideUpdateAndroidTextDialog = () => {
    this.setState({ showUpdateAndroidTextDialog: false });
  };

    // 隐藏
    hideUpdateIOSTextDialog = () => {
      this.setState({ showUpdateIOSTextDialog: false });
    };

  /**
   * 获取更新贵族价格数据
   * @param {*} options
   */
  getUpdateNoblePriceRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 4,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateNoblePriceRow = {
          nobleGradeList: res.retValue,
          id: row.id,
          orderno: row.orderno,
          name: row.name,
          coin: row.coin,
          androidCoin: row.androidCoin,
          iosCoin: row.iosCoin,
          length: row.length,
          sendCoin: row.sendCoin,
          payType: row.payType,
          grade: row.grade,
          isTip: row.isTip,
          iosId: row.iosId,
        };
  
        this.setState({ showUpdateNoblePriceDialog: true });
      }                  
     
    });
  };

    /**
   * 获取添加贵族价格数据
   * @param {*} options
   */
  getAddNoblePriceRowData = () => {
      let { history } = this.props;
      var gradeParam = {
        type: 4,
      };
      // 获取分配比例列表
      gradeService.getGradeList(gradeParam).then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if (res.retValue == null) {
            return;
          }
    
          this.state.addNoblePriceRow = {
            nobleGradeList: res.retValue,
          };
    
          this.setState({ showAddNoblePriceDialog: true });
        }                  
        
      });
  };


  /**
   * 获取安卓贵族页面文字说明
   */
  getUpdateAndroidTextRowData = () => {
        let { history } = this.props;
        var textDescription = {
          promptType: 2,
          osType: 1
        };
        // 获取文字说明
        textDescriptionService.getText(textDescription)
        .then((res) => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if (res.retValue == null) {
              return;
            }
      
            this.state.updateAndroidTextRow = res.retValue;
      
            this.setState({ showUpdateAndroidTextDialog: true });
          }                  
          
        });
  };

  /**
   * 获取IOS贵族页面文字说明
   */
   getUpdateIOSTextRowData = () => {
      let { history } = this.props;
      var textDescription = {
        promptType: 2,
        osType: 2
      };
      // 获取文字说明
      textDescriptionService.getText(textDescription)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if (res.retValue == null) {
            return;
          }
    
          this.state.updateIOSTextRow = res.retValue;
    
          this.setState({ showUpdateIOSTextDialog: true });
        }                  
        
      });
};


  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/noble_price">贵族价格</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="NoblePriceListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={1}>

                    <Form.Item label="贵族等级" name="grade" initialValue="">
                      <Select style={{ width: 220 }} defaultValue="">
                          <Option value="">全部</Option>

                          {this.state.nobleGradeList != null ? this.state.nobleGradeList.map((item)=>
                              <Option value={item.grade}>{item.name}</Option>
                          ) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item label="名称" name="name">
                        <Input placeholder="名称"/>
                    </Form.Item>
                  </Col>
                  <Col span={6} key={3} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetNoblePricePage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button type="primary" onClick={this.getAddNoblePriceRowData}  >
                        + 新增
                    </Button>
                  </Col>
              </Row>

              <Row gutter={24}>
                  <Col span={8} key={4} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.getUpdateAndroidTextRowData}  >
                        安卓贵族页面文字说明
                    </Button>
                  </Col>
                  <Col span={8} key={5} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.getUpdateIOSTextRowData}  >
                        IOS贵族页面文字说明
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.noblePriceList}
              columns={this.state.noblePriceColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.noblePriceTotal,
                defaultCurrent: 1,
                onChange: this.noblePriceChangePage,
              }}
            ></Table>
            <AddNoblePrice
              rowData={this.state.addNoblePriceRow}
              close={this.hideAddNoblePriceDialog}
              visible={this.state.showAddNoblePriceDialog}
              refreshData={this.refreshNoblePriceData}
            ></AddNoblePrice>

            <UpdateNoblePrice
              rowData={this.state.updateNoblePriceRow}
              close={this.hideUpdateNoblePriceDialog}
              visible={this.state.showUpdateNoblePriceDialog}
              refreshData={this.refreshNoblePriceData}
            ></UpdateNoblePrice>
            <UpdateAndroidText
              rowData={this.state.updateAndroidTextRow}
              close={this.hideUpdateAndroidTextDialog}
              visible={this.state.showUpdateAndroidTextDialog}
            ></UpdateAndroidText>
            <UpdateIOSText
              rowData={this.state.updateIOSTextRow}
              close={this.hideUpdateIOSTextDialog}
              visible={this.state.showUpdateIOSTextDialog}
            ></UpdateIOSText>


        </StickyContainer>
      </div>
    );
  }
}
export default NoblePrice;
