import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import medalService from "../../../Service/GradeMgr/medalService";
import gradeService from "../../../Service/GradeMgr/gradeService";
import UpdateNobleMedal from './UpdateNobleMedal';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class MedalMgr extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 贵族勋章 ————————————————————————————

    nobleMedalColumns: [
      {
        key: "id",
        title: "勋章ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "勋章名称",
        dataIndex: "name",
      },
      {
        key: "medalLogo",
        title: "勋章图片",
        dataIndex: "medalLogo",
        render: (medalLogo, row) => {
          if (medalLogo != null && medalLogo != "") {
            return <Image width={80} height={50} src={medalLogo} />;
          }
        },
      },
      {
        key: "lv",
        title: "勋章级别",
        dataIndex: "lv",
      },
      {
        key: "type",
        title: "勋章类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户勋章"
          } else if(type === 2) {
            return "财富勋章";
          } else if(type === 4) {
            return "贵族勋章";
          } else if(type === 5) {
            return "守护勋章";
          } 
        }
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateNobleMedalRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    nobleMedalTotal: 0,
    nobleMedalList: null,
    updateNobleMedalRow: null,
    showUpdateNobleMedalDialog: false,
    addNobleMedalRow: null,
    showAddNobleMedalDialog: false,
    nobleMedalPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
   

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 贵族勋章 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var medalParam = {
      type: 4,
      pageParam: pageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          nobleMedalList: res.retValue.list,
          nobleMedalTotal: res.retValue.total,
        });
      }    
      
    });

 
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 用户勋章 ————————————————————————————
  // 用户勋章分页
  nobleMedalChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        nobleMedalPageParam: pageParam,
      });

      var medalParam = {
        type: 4,
        pageParam: pageParam,
      };
      medalService.getMedalPage(medalParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            nobleMedalList: res.retValue.list,
            nobleMedalTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshNobleMedalData = () => {
    //console.log("refreshNobleMedalData");
    let { history } = this.props;

    var medalParam = {
      type: 4,
      pageParam: this.state.nobleMedalPageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          nobleMedalList: res.retValue.list,
          nobleMedalTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateNobleMedalDialog = () => {
    this.setState({ showUpdateNobleMedalDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddNobleMedalDialog = () => {
    this.setState({ showAddNobleMedalDialog: false });
  };


  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateNobleMedalRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 4,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateNobleMedalRow = {
          id: row.id,
          userGradeList: res.retValue,
          name: row.name,
          medalLogo: row.medalLogo,
          type: row.type,
          lv: row.lv,
        };
  
        this.setState({ showUpdateNobleMedalDialog: true });
      }    
      
    });
  };


  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/noble_medal">贵族勋章</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.nobleMedalList}
                columns={this.state.nobleMedalColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.nobleMedalTotal,
                  defaultCurrent: 1,
                  onChange: this.nobleMedalChangePage,
                }}
              ></Table>
              <UpdateNobleMedal
                rowData={this.state.updateNobleMedalRow}
                close={this.hideUpdateNobleMedalDialog}
                visible={this.state.showUpdateNobleMedalDialog}
                refreshData={this.refreshUserMedalData}
              ></UpdateNobleMedal>

        </StickyContainer>
      </div>
    );
  }
}
export default MedalMgr;
