import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 获取守护列表
   * @param {*} appGuardUsersParam
   * @returns
   */
  getGuardUsersPage(appGuardUsersParam) {
      //console.log("appGuardUsersParam=" + JSON.stringify(appGuardUsersParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/guardUsers/getGuardUsersPage",
        data: appGuardUsersParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}