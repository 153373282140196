import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfom, Logout } from '../../../Common/Auth';
import adsService from '../../../Service/Operation/adsService';


const { Option } = Select;
const { TextArea } = Input;

/**
 * 添加用户任务
 */
class AddAds extends Component {
  formRef = React.createRef();

  state={
    loading: false,
    sidList: [],
    sidOption: [],
    one:[{
        key: 7,
        value: '启动图'
    }],
    two:[{
        key: 8,
        value: '直播' 
    }],

    three:[{
        key: 9,
        value: '推荐' 
    }],
    four:[{
        key: 10,
        value: '附近' 
    }],
    five:[{
        key: 11,
        value: '推荐' 
    }],
    six:[{
        key: 14,
        value: '看点' 
    }],
    fifteen:[{
        key: 18,
        value: '派对首页' 
    }],
    seventeen:[{
        key: 20,
        value: '直播购' 
    }],
    twentyOne:[{
        key: 22,
        value: '动态' 
    }],
    thirtyOne:[{
        key: 32,
        value: '关注' 
    }],
    thirtyThree:[{
        key: 34,
        value: '电视剧' 
    }],
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var adsParam = values;
          adsService.insertOrUpdateAds(adsParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                    // 清除登录信息
                    Logout();
                    // 跳转到登录页面
                    history.push('/login');
                    return;
                  }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
  };

  selectChange =(value) => {
    console.log("value="+JSON.stringify(value));
    if(value == 1) {
        this.state.sidList = this.state.one;
    } else if(value == 2) {
        this.state.sidList = this.state.two;
    } else if(value == 3) {
        this.state.sidList = this.state.three;
    } else if(value == 4) {
        this.state.sidList = this.state.four;
    } else if(value == 5) {
        this.state.sidList = this.state.five;
    } else if(value == 6) {
        this.state.sidList = this.state.six;
    } else if(value == 15) {
        this.state.sidList = this.state.fifteen;
    } else if(value == 17) {
        this.state.sidList = this.state.seventeen;
    } else if(value == 21) {
        this.state.sidList = this.state.twentyOne;
    } else if(value == 31) {
        this.state.sidList = this.state.thirtyOne; 
    } else if(value == 33) {
        this.state.sidList = this.state.thirtyThree;
    }
    this.formRef.current.setFieldsValue({
        'sid': this.state.sidList[0].key,
    });
    console.log("sidList="+JSON.stringify(this.state.sidList));


  }



  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="排序" name="orderno" labelCol={{span:10}} style={{ width: 420 }}>
                <InputNumber />
            </Form.Item>

            <Form.Item label="标题" name="name" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="标题" />
            </Form.Item>

            <Form.Item label="链接" name="url" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="链接" />
            </Form.Item>

            <Form.Item label="图片(1080*360)" name="thumb" 
                valuePropName="file"
                labelCol={{span:10}} 
                style={{ width: 420 }}
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>
  
            <Form.Item label="一级分类" labelCol={{span:10}} name="pid" style={{ width: 420 }} initialValue={1} >
                <Select style={{ width: 220 }} defaultValue={1} onChange={this.selectChange}>
                    <Option value={1}>app启动广告页面</Option>
                    <Option value={2}>直播</Option>
                    <Option value={3}>推荐</Option>
                    <Option value={4}>附近</Option>
                    <Option value={5}>一对一</Option>
                    <Option value={6}>短视频</Option>
                    <Option value={15}>派对</Option>
                    <Option value={17}>直播购</Option>
                    <Option value={21}>动态</Option>
                    <Option value={31}>关注</Option>
                    <Option value={33}>电视剧</Option>
                </Select>
            </Form.Item>

            <Form.Item label="二级分类" labelCol={{span:10}} name="sid" style={{ width: 420 }} initialValue={7}>
                <Select style={{ width: 220 }} defaultValue={7}>
                    <Option value={7}>启动图</Option>
                    <Option value={8}>直播</Option>
                    <Option value={9}>推荐</Option>
                    <Option value={10}>附近</Option>
                    <Option value={11}>推荐</Option>
                    <Option value={14}>看点</Option>
                    <Option value={18}>派对首页</Option>
                    <Option value={20}>直播购</Option>
                    <Option value={22}>动态</Option>
                    <Option value={32}>关注</Option>
                    <Option value={34}>电视剧</Option>
                </Select>
            </Form.Item>

        


        </Form>
      </Modal>
    )
  }
}

export default AddAds