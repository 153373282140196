import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment';
import BraftEditor from 'braft-editor'


import postService from '../../../Service/BasicInformation/postService';
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';

import UpdatePost from './UpdatePost';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class ProtocolSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state={
   
    // ———————————————————— 协议列表 ————————————————————————————
    
    postColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'postTitle',
      title: '标题',
      dataIndex: 'postTitle'
    },{
      key: 'createtime',
      title: '发布时间',
      dataIndex: 'createtime',
    },{
      key: 'upTime',
      title: '修改时间',
      dataIndex: 'upTime'
    },{
      key: 'status',
      title: '启用状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "关闭"
        } else if(status === 1) {
          return "启用";
        } 
      }
    },{
      key: 'postUrl',
      title: '协议地址',
      dataIndex: 'postUrl'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getUpdatePostRowData(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    postTotal: 0,
    postList: [],
    addPostRow: null,
    updatePostRow: null,
    showUpdatePostDialog: false,
    showAddPostDialog:false,
    postPageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 用户注销 ————————————————————————————
    userCancel: null,


    // ———————————————————— 隐私协议提示 ————————————————————————————
    xieyiRule: null,
    
    // ———————————————————— 平台使用许可条约 ———————————————————————————— 
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 协议列表 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var postParam={
      pageParam: pageParam
    }

    postService.getPostPage(postParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({postList: res.retValue.list, postTotal: res.retValue.total});
        }
      }
    )
   
    // ———————————————————— 用户注销 ————————————————————————————

    adminLiveConfigService.getUserCancel()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userCancel: res.retValue.userCancel})
        }
      }
    )   

    // ———————————————————— 隐私协议提示 ————————————————————————————

    adminLiveConfigService.getXieyiRule()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({xieyiRule: res.retValue.xieyiRule})
        }
      }
    )   

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }


  
  // ———————————————————— 协议列表 ————————————————————————————
    
     // 关闭更新
  hideUpdatePostDialog=() => {
    this.setState({showUpdatePostDialog: false});
  }

  // 关闭添加
  hideAddPostDialog=() => {
    this.setState({showAddPostDialog: false});
  }


  // 获取列表
  handleGetPostPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var postParam={
        pageParam: pageParam
      }
  
      postService.getPostPage(postParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({postList: res.retValue.list, postTotal: res.retValue.total});
          }
        }
      )
      
    })
  
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  postChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      postPageParam: pageParam,
    });

    var postParam={
      pageParam: pageParam
    }

    postService.getPostPage(postParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({postList: res.retValue.list, postTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新
   */
  refreshPostData=() => {
      let { history } = this.props;

      var postParam={
        pageParam: this.state.postPageParam
      }
  
      postService.getPostPage(postParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({postList: res.retValue.list, postTotal: res.retValue.total});
          }
        }
      )
  }

  /**
   * 获取更新数据
   * @param {*} id 
   */
  getUpdatePostRowData = (id) => {
    let { history } = this.props;
    var postParam = {
      id: id,
    };
    // 分页获取动态评论
    postService.getConfig(postParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.setState({ updatePostRow: res.retValue });
        this.setState({ showUpdatePostDialog: true });
      }
      
    });
 };


  // ———————————————————— 用户注销 ————————————————————————————
  handleUserCancelSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var userCancelParam = {
          userCancel: this.state.userCancel
        };
        adminLiveConfigService.updateUserCancel(userCancelParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  handleEditorUserCancelChange = (content) => {
    console.log("content="+JSON.stringify(content.toHTML()));

    this.setState({
      userCancel: content.toHTML()
    })
  }
  
  // ———————————————————— 隐私协议提示 ————————————————————————————
  handleXieyiRuleSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {

        var xieyiRuleParam = {
          xieyiRule: this.state.xieyiRule
        };
        adminLiveConfigService.updateXieyiRule(xieyiRuleParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  handleEditorXieyiRuleChange = (content) => {
    console.log("content="+JSON.stringify(content.toHTML()));

    this.setState({
      xieyiRule: content.toHTML()
    })
  }



  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getPost();
    } else if(key == "2") {
      this.getAdminLiveConfig1();
    } else if(key == "3") {
      this.getAdminLiveConfig2();
    } 
  }

  /**
   * 协议列表
   */
  getPost = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var postParam={
      pageParam: pageParam
    }

    postService.getPostPage(postParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({postList: res.retValue.list, postTotal: res.retValue.total});
        }
      }
    )
  }
  
  /**
   * 用户注销
   */
  getAdminLiveConfig1 = () => {
    let { history } = this.props;
    adminLiveConfigService.getUserCancel()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            userCancel: res.retValue.userCancel,
          });
          this.setState({userCancel: res.retValue.userCancel})
        }
      }
    )  
  }
  
  /**
   * 隐私协议提示
   */
  getAdminLiveConfig2 = () => {
    let { history } = this.props;
    adminLiveConfigService.getXieyiRule()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            xieyiRule: res.retValue.xieyiRule,
          });
          this.setState({xieyiRule: res.retValue.xieyiRule})
        }
      }
    )  
  }
  

 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/protocol_settings">协议设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 协议列表 ————————————————————————————
            <TabPane tab="协议列表" key="1">

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.postList}
                columns={this.state.postColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.postTotal, defaultCurrent: 1, onChange: this.postChangePage}}
              >
              </Table>
              <UpdatePost rowData={this.state.updatePostRow} close={this.hideUpdatePostDialog} visible={this.state.showUpdatePostDialog} refreshData={this.refreshPostData}></UpdatePost>

            
            </TabPane>
            

            // ———————————————————— 用户注销 ————————————————————————————
            <TabPane tab="用户注销" key="2">
              <Form 
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  initialValues={this.state.userCancel}
                  
              >

                  <Form.Item label="内容" name="userCancel" labelCol={{span:4}} style={{ width: 1000 }} >
                    <BraftEditor
                        defaultValue={BraftEditor.createEditorState(this.state.userCancel != null ? this.state.userCancel : null)}
                        value={this.state.userCancel}
                        onChange={this.handleEditorUserCancelChange}
                        style={{ border: '1px solid #dedede', marginTop: 10 }}
                        placeholder="请输入正文内容"
                    />
                  </Form.Item>
              </Form>

              <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                <span onClick={ this.handleUserCancelSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
              </div>
                  
            </TabPane>

            // ———————————————————— 隐私协议提示 ————————————————————————————
            <TabPane tab="隐私协议提示" key="3">
              <Form 
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  initialValues={this.state.xieyiRule}
                  
              >

                  <Form.Item label="内容" name="xieyiRule" labelCol={{span:4}} style={{ width: 1000 }} >
                    <BraftEditor
                        defaultValue={BraftEditor.createEditorState(this.state.xieyiRule != null ? this.state.xieyiRule : null)}
                        value={this.state.xieyiRule}
                        onChange={this.handleEditorXieyiRuleChange}
                        style={{ border: '1px solid #dedede', marginTop: 10 }}
                        placeholder="请输入正文内容"
                    />
                  </Form.Item>
              </Form>

              <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                <span onClick={ this.handleXieyiRuleSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
              </div>
              
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default ProtocolSettings;