import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal, Avatar, Popconfirm, Input } from 'antd';
import { Link } from 'react-router-dom';
import AddUser from './AddUser';
import EditUser from './EditUser';
import SetRole from './SetRole';
import userService from '../../../Service/Admin/userService';
import { Logout } from '../../../Common/Auth';


class UserMgr extends Component {
  state={
    setRoleUser: null,          // 当前设置角色的用户
    showSetRoleDialog: false,   // 设置角色
    editUserRow: null,          // 当前编辑用户信息 
    showEditUserDialog: false,  // 修改用户对话框
    selectRowKeys:[],
    showAddUserDialog: false,  // 添加用户对话框
    unsubscribe: null,
    total: 0,
    datalist: [],
    searchKey: "",
    params: {pageNum:1, pageSize: 6},
    columns: [{
      key: 'id',
      title: '编号',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '姓名',
      dataIndex: 'name'
    },{
      key: 'phone',
      title: '电话',
      dataIndex: 'phone'
    },{
      key: 'avatar',
      title: '头像',
      dataIndex: 'avatar',
      render: (avatar) => <Avatar shape="square" size={55} src={avatar}></Avatar>
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        return (
          <div>
            <Button 
              onClick={() => this.setState({showEditUserDialog:true, editUserRow: row })}
              style={{marginRight:'5px'}} 
              type="primary">编辑 
            </Button>
            <Popconfirm
              onConfirm={() => {
                //message.info(row.id);
                this.deleteUser(row.id);
              }}
              onText="确认"
              cancelText="取消"
              title="您确认要删除吗？"
            >
                <Button type="danger">删除</Button>
            </Popconfirm>
          </div>
        )
      }
    }],
    userPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  /**
   * 行选择操作
   */
  userRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows);
      this.setState({selectRowKeys: selectedRowKeys});
    }
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // 发送请求后端，获取当前用户的列表数据
    // 方法一：axios直接请求后端进行封装
    let pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    let key = '';
    userService.getUserPage(key, pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({datalist: res.retValue.list, total: res.retValue.total});
        }
      }
    )

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }
  

  // 分页
  changePage=(page, pageSize) => {
    let { history } = this.props;
    let key = this.state.searchKey;
    // 修改参数
    this.setState(preState => {
      return {...preState, ...{params:{pageNum: page, pageSize: pageSize}, key: key}}
    }, 
    // 回调函数
    () => {
      let pageParam = this.state.params;
      this.setState({
        userPageParam: pageParam,
      });
      userService.getUserPage(key, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({datalist: res.retValue.list, total: res.retValue.total});
          }
        }
      )
    });
  }

  // button样式
  buttonStyle={margin: '5px'};

  // 关闭添加用户弹框
  hideAddUserDialog=() => {
    this.setState({showAddUserDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditUserDialog=() => {
    this.setState({showEditUserDialog: false});
  }

  // 关闭设置角色弹窗
  hideSetRoleDialog = () => {
    this.setState({showSetRoleDialog: false});
  }

  // 刷新数据
  refreshData=() => {
      let { history } = this.props;
      let pageParam = this.state.userPageParam;
      let key = ''
      userService.getUserPage(key, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({datalist: res.retValue.list, total: res.retValue.total});
          }
        }
      )
  }

  // 删除数据
  handleDelete=()=> {
      let { history } = this.props;
      // 拿到所有要删除的数据
      //console.log(JSON.stringify(this.state.selectRowKeys));
      // 确认是否需要删除
      if(this.state.selectRowKeys.length <= 0 ) {
        message.warn('请选择要删除的数据！');
        return ;
      }
      Modal.confirm({
        title: '您确认要删除吗？',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          // 调用后端删除
          let userIdList = this.state.selectRowKeys;
          userService.deleteUserByIdList(userIdList)
          .then(res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("删除失败,"+res.message);  
            } else {
              // 刷新数据
              this.refreshData();
              message.success('删除成功！');
            }
            
          })
  
        }
      });

  }

  // 根据Id删除用户
  deleteUser=(userId) => {
      let { history } = this.props;
      let userIdList = [userId];
      userService.deleteUserByIdList(userIdList)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshData();
          message.success('删除成功！');
        }
        
      })
  
  }

  // 设置角色
  handleSetRole=() => {
    if(this.state.selectRowKeys.length > 1 || this.state.selectRowKeys.length <=0) {
      message.error('请选中一个用户进行设置角色');
      return;
    }
    let setRoleUserId = this.state.selectRowKeys[0];
    let setRoleUser = this.state.datalist.find(item => item.id === setRoleUserId);
    this.setState({showSetRoleDialog: true, setRoleUser: setRoleUser});
  }


  render() {
    return (
      <div className="admin-usermgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/user_mgr">用户管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
        <Button onClick={() => this.setState({showAddUserDialog:true})} style={this.buttonStyle} type="primary">添加</Button>
        <Button onClick={ this.handleDelete } style={this.buttonStyle} type="danger">删除</Button>
        <Button onClick={ this.handleSetRole } style={this.buttonStyle} type="primary">设置角色</Button>


        <Input.Search 
          placeholder="搜索"
          onSearch={(value) => {
            // 第一步： 先把搜索的参数放到 state中
            this.setState(preState => {
              preState.searchKey = value
            },() => {
              // 第二步： 从新请求数据
              this.changePage(1,10);
            });

          }}
          enterButton
          style={{margin: '5px', width: '300px'}}
        /> 

        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE'}}
          dataSource={this.state.datalist}
          columns={this.state.columns}
          rowSelection={this.userRowSelection}
          rowKey="id"
          pagination = {{ total: this.state.total, pageSize: 10, defaultCurrent: 1, onChange: this.changePage}}
        >
        </Table>
        <AddUser close={this.hideAddUserDialog} visible={this.state.showAddUserDialog} refreshData={this.refreshData}></AddUser>
        <EditUser rowData={this.state.editUserRow} close={this.hideEditUserDialog} visible={this.state.showEditUserDialog} refreshData={this.refreshData}></EditUser>
        {
          this.state.showSetRoleDialog ?
            <SetRole data={this.state.setRoleUser} close={this.hideSetRoleDialog} visible={this.state.showSetRoleDialog} />
            :
            null
        }
      </div>
    );
  }
}

export default UserMgr;