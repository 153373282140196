import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import userTaskService from '../../../Service/Operation/userTaskService';
import adsService from '../../../Service/Operation/adsService';
import awwrdConfigService from '../../../Service/Operation/awwrdConfigService';
import finSysTranRecordService from '../../../Service/Operation/finSysTranRecordService';
import feedBackService from '../../../Service/Operation/feedBackService';


import AddUserTask from './AddUserTask';
import UpdateUserTask from './UpdateUserTask';
import AddAnchorTask from './AddAnchorTask';
import UpdateAnchorTask from './UpdateAnchorTask';
import AddAds from './AddAds';
import UpdateAds from './UpdateAds';
import UpdateAwwrdConfig from './UpdateAwwrdConfig';
import AddFeedBack from './AddFeedBack';
import UpdateFeedBack from './UpdateFeedBack';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class OperationalBasis extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={
    // ———————————————————— 用户任务 ————————————————————————————
    userTaskColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '任务名称',
      dataIndex: 'name'
    },{
      key: 'image',
      title: '礼物图标',
      dataIndex: 'image',
      render: (image, row) => {
        if(image != null && image != "") {
          return <Image
          width={55}
          height={55}
          src={image}
        />
        }
      }
    },{
      key: 'typeCode',
      title: '任务类型',
      dataIndex: 'typeCode',
      render: (typeCode, row) => {
        if(typeCode === '1001') {
          return "签到"
        } else if(typeCode === '1002') {
          return "送出礼物";
        } else if(typeCode === '1104') {
          return "关注用户";
        } else if(typeCode === '1005') {
          return "关注主播";
        } else if(typeCode === '1105') {
          return "私信任务";
        } else if(typeCode === '1108') {
          return "送出礼物次数";
        } else if(typeCode === '1003') {
          return "观看直播间";
        } else if(typeCode === '1004') {
          return "分享直播间";
        } else if(typeCode === '1006') {
          return "付费视频通话次数";
        } else if(typeCode === '1007') {
          return "付费语音通话次数";
        } else if(typeCode === '1008') {
          return "付费视频通话分钟数";
        } else if(typeCode === '1009') {
          return "付费语音通话分钟数";
        } else if(typeCode === '1101') {
          return "观看视图";
        } else if(typeCode === '1102') {
          return "点赞视图";
        } else if(typeCode === '1103') {
          return "分享视图";
        } else if(typeCode === '1107') {
          return "发布视图";
        } else {
          return typeCode;
        }
      }
    },{
      key: 'point',
      title: '经验积分',
      dataIndex: 'point'
    },{
      key: 'val',
      title: '标准值',
      dataIndex: 'val'
    },{
      key: 'unit',
      title: '标准值单位',
      dataIndex: 'unit'
    },{
      key: 'taskType',
      title: '任务分类',
      dataIndex: 'taskType',
      render: (taskType, row) => {
        if(taskType === 0) {
          return "普通任务"
        } else if(taskType === 1) {
          return "直播任务";
        } else if(taskType === 2) {
          return "交友任务";
        } else if(taskType === 3) {
          return "短视频任务";
        } 
      }
    },{
      key: 'isStart',
      title: '是否开启',
      dataIndex: 'isStart',
      render: (isStart, row) => {
        if(isStart === 0) {
          return "关闭"
        } else if(isStart === 1) {
          return "开启";
        } 
      }
    },{
      key: 'taskCompletionType',
      title: '任务有效期',
      dataIndex: 'taskCompletionType',
      render: (taskCompletionType, row) => {
        if(taskCompletionType === 1) {
          return "每日任务"
        } else if(taskCompletionType === 2) {
          return "累计任务";
        } 
      }
    },{
      key: 'startTime',
      title: '添加时间',
      dataIndex: 'startTime'
    },{
      key: 'sort',
      title: '排序',
      dataIndex: 'sort'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateUserTaskDialog:true, updateUserTaskRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteUserTask(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    userTaskTotal: 0,
    userTaskList: [],
    updateUserTaskRow: null,
    showUpdateUserTaskDialog: false,
    showAddUserTaskDialog:false,
    userTaskPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 主播任务 ————————————————————————————
    
    anchorTaskColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '任务名称',
      dataIndex: 'name'
    },{
      key: 'image',
      title: '礼物图标',
      dataIndex: 'image',
      render: (image, row) => {
        if(image != null && image != "") {
          return <Image
          width={55}
          height={55}
          src={image}
        />
        }
      }
    },{
      key: 'typeCode',
      title: '任务类型',
      dataIndex: 'typeCode',
      render: (typeCode, row) => {
        if(typeCode === '2003') {
          return "收到礼物"
        } else if(typeCode === '2004') {
          return "关注人数";
        } else if(typeCode === '2005') {
          return "送礼人数";
        } else if(typeCode === '2104') {
          return "私信任务";
        } else if(typeCode === '2107') {
          return "收到礼物次数";
        } else if(typeCode === '2001') {
          return "直播时长";
        } else if(typeCode === '2002') {
          return "直播天数";
        } else if(typeCode === '2006') {
          return "PK胜利次数";
        } else if(typeCode === '2007') {
          return "付费视频通话次数";
        } else if(typeCode === '2008') {
          return "付费语音通话次数";
        } else if(typeCode === '2009') {
          return "付费视频通话分钟数";
        } else if(typeCode === '2010') {
          return "付费语音通话分钟数";
        } else if(typeCode === '2101') {
          return "点赞量";
        } else if(typeCode === '2102') {
          return "观看量";
        } else if(typeCode === '2103') {
          return "被分享视图";
        } else if(typeCode === '2105') {
          return "发布私密视图";
        } else if(typeCode === '2106') {
          return "被购买次数";
        } else {
          return typeCode;
        }
      }
    },{
      key: 'point',
      title: '经验积分',
      dataIndex: 'point'
    },{
      key: 'val',
      title: '标准值',
      dataIndex: 'val'
    },{
      key: 'unit',
      title: '标准值单位',
      dataIndex: 'unit'
    },{
      key: 'taskType',
      title: '任务分类',
      dataIndex: 'taskType',
      render: (taskType, row) => {
        if(taskType === 0) {
          return "普通任务"
        } else if(taskType === 1) {
          return "直播任务";
        } else if(taskType === 2) {
          return "交友任务";
        } else if(taskType === 3) {
          return "短视频任务";
        } 
      }
    },{
      key: 'isStart',
      title: '是否开启',
      dataIndex: 'isStart',
      render: (isStart, row) => {
        if(isStart === 0) {
          return "关闭"
        } else if(isStart === 1) {
          return "开启";
        } 
      }
    },{
      key: 'taskCompletionType',
      title: '任务有效期',
      dataIndex: 'taskCompletionType',
      render: (taskCompletionType, row) => {
        if(taskCompletionType === 1) {
          return "每日任务"
        } else if(taskCompletionType === 2) {
          return "累计任务";
        } 
      }
    },{
      key: 'startTime',
      title: '添加时间',
      dataIndex: 'startTime'
    },{
      key: 'sort',
      title: '排序',
      dataIndex: 'sort'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateAnchorTaskDialog:true, updateAnchorTaskRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteAnchorTask(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    anchorTaskTotal: 0,
    anchorTaskList: [],
    updateAnchorTaskRow: null,
    showUpdateAnchorTaskDialog: false,
    showAddAnchorTaskDialog:false,
    anchorTaskPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
    
    
    // ———————————————————— 轮播替换 ————————————————————————————

    adsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'orderno',
      title: '排序',
      dataIndex: 'orderno'
    },{
      key: 'name',
      title: '标题',
      dataIndex: 'name'
    },{
      key: 'url',
      title: '链接',
      dataIndex: 'url'
    },{
      key: 'thumb',
      title: '图片',
      dataIndex: 'thumb',
      render: (thumb, row) => {
        if(thumb != null && thumb != "") {
          return <Image
          width={55}
          height={55}
          src={thumb}
        />
        }
      }
    },{
      key: 'pid',
      title: '一级分类',
      dataIndex: 'pid',
      render: (pid, row) => {
        if(pid === 1) {
          return "app启动广告页面"
        } else if(pid === 2) {
          return "直播";
        } else if(pid === 3) {
          return "推荐";
        } else if(pid === 4) {
          return "附近";
        } else if(pid === 5) {
          return "一对一";
        } else if(pid === 6) {
          return "短视频";
        } else if(pid === 15) {
          return "派对";
        } else if(pid === 17) {
          return "直播购";
        } else if(pid === 21) {
          return "动态";
        } else if(pid === 31) {
          return "关注";
        } else if(pid === 33) {
          return "电视剧";
        } else {
          return pid;
        }
      }
    },{
      key: 'sid',
      title: '二级分类',
      dataIndex: 'sid',
      render: (sid, row) => {
        if(sid === 7) {
          return "启动图"
        } else if(sid === 8) {
          return "直播";
        } else if(sid === 9) {
          return "推荐";
        } else if(sid === 10) {
          return "附近";
        } else if(sid === 11) {
          return "推荐";
        } else if(sid === 14) {
          return "看点";
        } else if(sid === 18) {
          return "派对首页";
        } else if(sid === 20) {
          return "直播购";
        } else if(sid === 22) {
          return "动态";
        } else if(sid === 32) {
          return "关注";
        } else if(sid === 34) {
          return "电视剧";
        } else {
          return sid;
        }
      }
    },{
      key: 'addtime',
      title: '发布时间',
      dataIndex: 'addtime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateAdsDialog:true, updateAdsRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteAds(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    adsTotal: 0,
    adsList: [],
    updateAdsRow: null,
    showUpdateAdsDialog: false,
    showAddAdsDialog:false,


    // ———————————————————— 推荐佣金设置 ————————————————————————————

    awwrdConfigColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'projectName',
      title: '推荐项目',
      dataIndex: 'projectName'
    },{
      key: 'content',
      title: '佣金内容',
      dataIndex: 'content'
    },{
      key: 'type',
      title: '业务类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "用户注册"
        } else if(type === 2) {
          return "主播认证";
        } else if(type === 3) {
          return "用户充值";
        } else if(type === 4) {
          return "主播收益";
        } else if(type === 5) {
          return "通话收益";
        } else if(type === 6) {
          return "开通贵族";
        } else if(type === 7) {
          return "开通svip";
        }
      }
    },{
      key: 'valType',
      title: '单位',
      dataIndex: 'valType',
      render: (valType, row) => {
        if(valType === 1) {
          return "佣金"
        } else if(valType === 2) {
          return "比例%";
        } 
      }
    },{
      key: 'distribut1',
      title: '一级分成',
      dataIndex: 'distribut1'
    },{
      key: 'distribut2',
      title: '二级分成',
      dataIndex: 'distribut2'
    },{
      key: 'distribut3',
      title: '三级分成',
      dataIndex: 'distribut3'
    },{
      key: 'isEnable',
      title: '是否启用',
      dataIndex: 'isEnable',
      render: (isEnable, row) => {
        if(isEnable === 0) {
          return "关闭"
        } else if(isEnable === 1) {
          return "启用";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateAwwrdConfigDialog:true, updateAwwrdConfigRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    awwrdConfigTotal: 0,
    awwrdConfigList: [],
    updateAwwrdConfigRow: null,
    showUpdateAwwrdConfigDialog: false,  
    awwrdConfigPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 运营支出 ————————————————————————————

    operatingExpensesColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'uidAvatar',
      title: '用户信息',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'tranType1',
      title: '交易类别1',
      dataIndex: 'tranType1',
      render: (tranType1, row) => {
        if(tranType1 === 52) {
          return "支出金币"
        } else {
          return tranType1;
        }
      }
    },{
      key: 'tranType2',
      title: '交易类别2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 === 12) {
          return "奖励收益"
        } else {
          return tranType2;
        }
      }
    },{
      key: 'tranType3',
      title: '交易类别3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 === 1201) {
          return "首充奖励"
        } else if(tranType3 === 1202) {
          return "新手大礼包";
        } else if(tranType3 === 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 === 1204) {
          return "等级礼包";
        } else if(tranType3 === 1205) {
          return "连续登录奖励";
        } else if(tranType3 === 1206) {
          return "签到奖励";
        } else if(tranType3 === 1207) {
          return "充值奖励";
        } else {
          return tranType3;
        }
      }
    },{
      key: 'coinChange',
      title: '支出金币/数量',
      dataIndex: 'coinChange'
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    operatingExpensesTotal: 0,
    operatingExpensesList: [],
    operatingExpensesPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 用户反馈 ————————————————————————————

    feedBackColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'uidAvatar',
      title: '礼物图标',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'content',
      title: '反馈信息',
      dataIndex: 'content'
    },{
      key: 'thumb',
      title: '礼物图标',
      dataIndex: 'thumb',
      render: (thumb, row) => {
        if(thumb != null && thumb != "") {
          return <Image
          width={55}
          height={55}
          src={thumb}
        />
        }
      }
    },{
      key: 'status',
      title: '是否开启',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "未处理"
        } else if(status === 1) {
          return "已处理";
        } 
      }
    },{
      key: 'createtime',
      title: '创建时间',
      dataIndex: 'createtime'
    },{
      key: 'uptime',
      title: '处理时间',
      dataIndex: 'uptime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateFeedBackDialog:true, updateFeedBackRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteFeedBack(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    feedBackTotal: 0,
    feedBackList: [],
    updateFeedBackRow: null,
    showUpdateFeedBackDialog: false,
    showAddFeedBackDialog:false,
    feedBackPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 用户任务 ————————————————————————————
    this.getUserTask1();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 用户任务 ————————————————————————————

  // 关闭更新
  hideUpdateUserTaskDialog=() => {
    this.setState({showUpdateUserTaskDialog: false});
  }

  // 关闭添加
  hideAddUserTaskDialog=() => {
    this.setState({showAddUserTaskDialog: false});
  }


  // 获取列表
  handleGetUserTaskPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var userTaskParam={
        role: 0,
        name: values.name,
        taskType: values.taskType,
        pageParam: pageParam
      }

      userTaskService.getUserTaskPage(userTaskParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userTaskList: res.retValue.list, userTaskTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  userTaskChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      userTaskPageParam: pageParam,
    });

    var userTaskParam={
      role: 0,
      pageParam: pageParam
    }

    userTaskService.getUserTaskPage(userTaskParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userTaskList: res.retValue.list, userTaskTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshUserTaskData=() => {
      let { history } = this.props;

      var userTaskParam={
        role: 0,
        pageParam: this.state.userTaskPageParam
      }

      userTaskService.getUserTaskPage(userTaskParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userTaskList: res.retValue.list, userTaskTotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除礼物
  deleteUserTask=(id) => {
    let { history } = this.props;
    let userTaskParam = {
      id : id
    };
    userTaskService.deleteUserTask(userTaskParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("删除失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshUserTaskData();
        message.success('删除成功！');
      }                  
      
    })

  }

    // ———————————————————— 主播任务 ————————————————————————————
    
     // 关闭更新
  hideUpdateAnchorTaskDialog=() => {
    this.setState({showUpdateAnchorTaskDialog: false});
  }

  // 关闭添加
  hideAddAnchorTaskDialog=() => {
    this.setState({showAddAnchorTaskDialog: false});
  }


  // 获取列表
  handleGetAnchorTaskPage=() =>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var anchorTaskParam={
        role: 1,
        name: values.name,
        taskType: values.taskType,
        pageParam: pageParam
      }

      userTaskService.getUserTaskPage(anchorTaskParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anchorTaskList: res.retValue.list, anchorTaskTotal: res.retValue.total});
          }                  
        }
      )
      
    })
   
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  anchorTaskChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      anchorTaskPageParam: pageParam,
    });

    var anchorTaskParam={
      role: 1,
      pageParam: pageParam
    }

    userTaskService.getUserTaskPage(anchorTaskParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({anchorTaskList: res.retValue.list, anchorTaskTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshAnchorTaskData=() => {
      let { history } = this.props;

      var anchorTaskParam={
        role: 1,
        pageParam: this.state.anchorTaskPageParam
      }

      userTaskService.getUserTaskPage(anchorTaskParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anchorTaskList: res.retValue.list, anchorTaskTotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除礼物
  deleteAnchorTask=(id) => {
    let { history } = this.props;
    let anchorTaskParam = {
      id : id
    };
    userTaskService.deleteUserTask(anchorTaskParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("删除失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshAnchorTaskData();
        message.success('删除成功！');
      }                  
      
    })

  }
    
    
  // ———————————————————— 轮播替换 ————————————————————————————
  // 关闭更新
  hideUpdateAdsDialog=() => {
    this.setState({showUpdateAdsDialog: false});
  }

  // 关闭添加
  hideAddAdsDialog=() => {
    this.setState({showAddAdsDialog: false});
  }


  // 获取列表
  handleGetAdsPage=() =>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var adsParam={
        pageParam: pageParam
      }
  
      adsService.getAdsPage(adsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({adsList: res.retValue.list, adsTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  adsChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      adsPageParam: pageParam,
    });

    var adsParam={
      pageParam: pageParam
    }

    adsService.getAdsPage(adsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({adsList: res.retValue.list, adsTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshAdsData=() => {
      let { history } = this.props;

      var adsParam={
        pageParam: this.state.adsPageParam
      }
  
      adsService.getAdsPage(adsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({adsList: res.retValue.list, adsTotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除礼物
  deleteAds=(id) => {
    let { history } = this.props;
    let adsParam = {
      id : id
    };
    adsService.deleteAds(adsParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshAdsData();
        message.success('删除成功！');
      }                  
      
    })

  }


    // ———————————————————— 推荐佣金设置 ————————————————————————————
    // 关闭更新
    hideUpdateAwwrdConfigDialog=() => {
      this.setState({showUpdateAwwrdConfigDialog: false});
    }
  
    /**
     * 分页查询
     * @param {*} page 
     * @param {*} pageSize 
     */
    awwrdConfigChangePage=(page, pageSize) => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      this.setState({
        awwrdConfigPageParam: pageParam,
      });
  
      var awwrdConfigParam={
        pageParam: pageParam
      }
  
      awwrdConfigService.getAwwrdConfigPage(awwrdConfigParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({awwrdConfigList: res.retValue.list, awwrdConfigTotal: res.retValue.total});
          }                  
        }
      )
    }
  
    /**
     * 刷新举报列表
     */
    refreshAwwrdConfigData=() => {
        let { history } = this.props;

        var awwrdConfigParam={
          pageParam: this.state.awwrdConfigPageParam
        }
    
        awwrdConfigService.getAwwrdConfigPage(awwrdConfigParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({awwrdConfigList: res.retValue.list, awwrdConfigTotal: res.retValue.total});
            }                  
          }
        )
    }

  

    // ———————————————————— 运营支出 ————————————————————————————
 
     // 关闭更新
     hideUpdateOperatingExpensesDialog=() => {
      this.setState({showUpdateOperatingExpensesDialog: false});
    }
  
    // 关闭添加
    hideAddOperatingExpensesDialog=() => {
      this.setState({showAddOperatingExpensesDialog: false});
    }
  
  
    // 获取列表
    handleGetOperatingExpensesPage=() =>{
      let { history } = this.props;
      this.formRef5.current.validateFields()
      .then(values => {
        //console.log(JSON.stringify(values));
        var pageParam = { 
          pageNum: 1,
          pageSize: 10
        }

        var timeStart = null;
        var timeEnd = null;
        if(values.sendTime != null) {
          timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
          timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }
  
        var operatingExpensesParam={
          startTime: timeStart,
          endTime: timeEnd,
          uid: values.uid,
          tranType1: 52,
          tranType3: values.tranType3,
          pageParam: pageParam
        }
    
        finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({operatingExpensesList: res.retValue.list, operatingExpensesTotal: res.retValue.total});
            }                  
          }
        )
        
      })

    }
  
  
    /**
     * 分页查询
     * @param {*} page 
     * @param {*} pageSize 
     */
    operatingExpensesChangePage=(page, pageSize) => {
      let { history } = this.props;
      this.formRef5.current.validateFields()
      .then(values => {
        //console.log(JSON.stringify(values));
        var pageParam = { 
          pageNum: page,
          pageSize: pageSize
        }

        this.setState({
          operatingExpensesPageParam: pageParam,
        });

        var timeStart = null;
        var timeEnd = null;
        if(values.sendTime != null) {
          timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
          timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }
  
        var operatingExpensesParam={
          startTime: timeStart,
          endTime: timeEnd,
          uid: values.uid,
          tranType1: 52,
          tranType3: values.tranType3,
          pageParam: pageParam
        }
    
        finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({operatingExpensesList: res.retValue.list, operatingExpensesTotal: res.retValue.total});
            }                  
          }
        )
        
      })
 
  
    }
  
    /**
     * 刷新列表
     */
    refreshOperatingExpensesData=() => {
        let { history } = this.props;
  
        var operatingExpensesParam={
          tranType1: 52,
          pageParam: this.state.operatingExpensesPageParam
        }
    
        finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({operatingExpensesList: res.retValue.list, operatingExpensesTotal: res.retValue.total});
            }                  
          }
        )
    }
  


    // ———————————————————— 用户反馈 ————————————————————————————
   // 关闭更新
   hideUpdateFeedBackDialog=() => {
    this.setState({showUpdateFeedBackDialog: false});
  }

  // 关闭添加
  hideAddFeedBackDialog=() => {
    this.setState({showAddFeedBackDialog: false});
  }


  // 获取列表
  handleGetFeedBackPage=() =>{
    let { history } = this.props;
    this.formRef6.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var feedBackParam={
        status: values.status,
        pageParam: pageParam
      }
  
      feedBackService.getFeedBackPage(feedBackParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({feedBackList: res.retValue.list, feedBackTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  feedBackChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      feedBackPageParam: pageParam,
    });

    var feedBackParam={
      pageParam: pageParam
    }

    feedBackService.getFeedBackPage(feedBackParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({feedBackList: res.retValue.list, feedBackTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshFeedBackData=() => {
      let { history } = this.props;

      var feedBackParam={
        pageParam: this.state.feedBackPageParam
      }
  
      feedBackService.getFeedBackPage(feedBackParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({feedBackList: res.retValue.list, feedBackTotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除
  deleteFeedBack=(id) => {
    let { history } = this.props;
    let feedBackParam = {
      id : id
    };
    feedBackService.deleteFeedBack(feedBackParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshFeedBackData();
        message.success('删除成功！');
      }                  

    })

  }


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getUserTask1();
    } else if(key == "2") {
      this.getUserTask2();
    } else if(key == "3") {
      this.getAds();
    } else if(key == "4") {
      this.getAwwrdConfig();
    } else if(key == "5") {
      this.getOperatingExpenses();
    } else if(key == "6") {
      this.getFeedBack();
    }
  }

  /**
   * 用户任务
   */
  getUserTask1=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var userTaskParam={
      role: 0,
      pageParam: pageParam
    }

    userTaskService.getUserTaskPage(userTaskParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userTaskList: res.retValue.list, userTaskTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 主播任务
   */
  getUserTask2=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var anchorTaskParam={
      role: 1,
      pageParam: pageParam
    }

    userTaskService.getUserTaskPage(anchorTaskParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({anchorTaskList: res.retValue.list, anchorTaskTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 轮播替换
   */
  getAds=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var adsParam={
      pageParam: pageParam
    }

    adsService.getAdsPage(adsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({adsList: res.retValue.list, adsTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 推荐佣金设置
   */
  getAwwrdConfig=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var awwrdConfigParam={
      pageParam: pageParam
    }

    awwrdConfigService.getAwwrdConfigPage(awwrdConfigParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({awwrdConfigList: res.retValue.list, awwrdConfigTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 运营支出
   */
  getOperatingExpenses=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var operatingExpensesParam={
      tranType1: 52,
      pageParam: pageParam
    }

    finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({operatingExpensesList: res.retValue.list, operatingExpensesTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 用户反馈
   */
  getFeedBack=()=>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var feedBackParam={
      pageParam: pageParam
    }

    feedBackService.getFeedBackPage(feedBackParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({feedBackList: res.retValue.list, feedBackTotal: res.retValue.total});
        }                  
      }
    )
  }
  

 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/operational_basis">运营基础</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 用户任务 ————————————————————————————
            <TabPane tab="用户任务" key="1">
              <Form
                name="UserTaskForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="任务名称" name="name">
                          <Input placeholder="任务名称" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="任务分类" name="taskType">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>普通任务</Option>
                              <Option value={2}>交友任务</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetUserTaskPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddUserTaskDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userTaskList}
                columns={this.state.userTaskColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userTaskTotal, defaultCurrent: 1, onChange: this.userTaskChangePage}}
              >
              </Table>
              <AddUserTask close={this.hideAddUserTaskDialog} visible={this.state.showAddUserTaskDialog} refreshData={this.refreshUserTaskData}></AddUserTask>
              <UpdateUserTask rowData={this.state.updateUserTaskRow} close={this.hideUpdateUserTaskDialog} visible={this.state.showUpdateUserTaskDialog} refreshData={this.refreshUserTaskData}></UpdateUserTask>
              
            </TabPane>
            
            
            // ———————————————————— 主播任务 ————————————————————————————
            <TabPane tab="主播任务" key="2">
              <Form
                name="AnchorTaskForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="任务名称" name="name">
                          <Input placeholder="任务名称" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="任务分类" name="taskType">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>普通任务</Option>
                              <Option value={2}>交友任务</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetAnchorTaskPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddAnchorTaskDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.anchorTaskList}
                columns={this.state.anchorTaskColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.anchorTaskTotal, defaultCurrent: 1, onChange: this.anchorTaskChangePage}}
              >
              </Table>
              <AddAnchorTask close={this.hideAddAnchorTaskDialog} visible={this.state.showAddAnchorTaskDialog} refreshData={this.refreshAnchorTaskData}></AddAnchorTask>
              <UpdateAnchorTask rowData={this.state.updateAnchorTaskRow} close={this.hideUpdateAnchorTaskDialog} visible={this.state.showUpdateAnchorTaskDialog} refreshData={this.refreshAnchorTaskData}></UpdateAnchorTask>
              
            
            </TabPane>

            // ———————————————————— 轮播替换 ————————————————————————————
            <TabPane tab="轮播替换" key="3">
              <Form
                name="AdsForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
              >
                <Row gutter={24}>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddAdsDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.adsList}
                columns={this.state.adsColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.adsTotal, defaultCurrent: 1, onChange: this.adsChangePage}}
              >
              </Table>
              <AddAds close={this.hideAddAdsDialog} visible={this.state.showAddAdsDialog} refreshData={this.refreshAdsData}></AddAds>
              <UpdateAds rowData={this.state.updateAdsRow} close={this.hideUpdateAdsDialog} visible={this.state.showUpdateAdsDialog} refreshData={this.refreshAdsData}></UpdateAds>
              
            </TabPane>

            // ———————————————————— 推荐佣金设置 ————————————————————————————
            <TabPane tab="推荐佣金设置" key="4">

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.awwrdConfigList}
                columns={this.state.awwrdConfigColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.awwrdConfigTotal, defaultCurrent: 1, onChange: this.awwrdConfigChangePage}}
              >
              </Table>
              <UpdateAwwrdConfig rowData={this.state.updateAwwrdConfigRow} close={this.hideUpdateAwwrdConfigDialog} visible={this.state.showUpdateAwwrdConfigDialog} refreshData={this.refreshAwwrdConfigData}></UpdateAwwrdConfig>
              
            </TabPane>

            // ———————————————————— 运营支出 ————————————————————————————
            <TabPane tab="运营支出" key="5">
              <Form
                name="OperatingExpensesForm"
                className="ant-advanced-search-form"
                ref={this.formRef5}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="发送时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="项目" name="tranType3">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1201}>首充奖励</Option>
                              <Option value={1202}>新手大礼包</Option>
                              <Option value={1203}>购买贵族赠送金币收益</Option>
                              <Option value={1204}>等级礼包</Option>
                              <Option value={1205}>连续登录奖励</Option>
                              <Option value={1206}>签到奖励</Option>
                              <Option value={1207}>充值奖励</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetOperatingExpensesPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.operatingExpensesList}
                columns={this.state.operatingExpensesColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.operatingExpensesTotal, defaultCurrent: 1, onChange: this.operatingExpensesChangePage}}
              >
              </Table>
            </TabPane>

            // ———————————————————— 用户反馈 ————————————————————————————
            <TabPane tab="用户反馈" key="6">
              <Form
                name="FeedBackForm"
                className="ant-advanced-search-form"
                ref={this.formRef6}
              >
                <Row gutter={24}>
                    <Col span={6} key={2}>
                      <Form.Item label="状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>未处理</Option>
                              <Option value={1}>已处理</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetFeedBackPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddFeedBackDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.feedBackList}
                columns={this.state.feedBackColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.feedBackTotal, defaultCurrent: 1, onChange: this.feedBackChangePage}}
              >
              </Table>
              <AddFeedBack close={this.hideAddFeedBackDialog} visible={this.state.showAddFeedBackDialog} refreshData={this.refreshFeedBackData}></AddFeedBack>
              <UpdateFeedBack rowData={this.state.updateFeedBackRow} close={this.hideUpdateFeedBackDialog} visible={this.state.showUpdateFeedBackDialog} refreshData={this.refreshFeedBackData}></UpdateFeedBack>
              
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default OperationalBasis;