import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import Service from '../../../Service/index';
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';
import loginSwitchService from '../../../Service/BasicInformation/loginSwitchService';
import shareConfigService from '../../../Service/BasicInformation/shareConfigService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class BasicSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={

    // ———————————————————— 认证开关 ————————————————————————————
    certificationSwitch: null,

    // ———————————————————— 个人中心功能配置 ————————————————————————————
    personalCenter: null,


    // ———————————————————— 安卓上架开关 ————————————————————————————
    androidShelfSwitch: null,
      
    // ———————————————————— 登录开关 ————————————————————————————
    loginSwitch: null,
    defaultUserAvatarList: null,

    // ———————————————————— 分享配置 ————————————————————————————
    shareConfig: null,
    logoList: null,
  
    // ———————————————————— 注销开关 ————————————————————————————
    logoffSwitch: null,
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 认证开关 ————————————————————————————

    this.getAdminLiveConfig1();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 认证开关 ————————————————————————————
  handleCertificationSwitchSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var certificationSwitchParam = values;
        adminLiveConfigService.updateCertificationSwitch(certificationSwitchParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
 
  }

 


  // ———————————————————— 个人中心功能配置 ————————————————————————————

  handlePersonalCenterSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var personalCenterParam = values;
        adminLiveConfigService.updatePersonalCenter(personalCenterParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


  // ———————————————————— 安卓上架开关 ————————————————————————————
  
  handleAndroidShelfSwitchSubmit=() => {
      //console.log("changePage 进入");
      let { history } = this.props;
      this.formRef3.current.validateFields()
      .then(values => {
  
          var androidShelfSwitchParam = values;
          adminLiveConfigService.updateAndroidShelfSwitch(androidShelfSwitchParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                //this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                //this.hanldeCloseModal();
              }
      
            }
          )
      })
   
    }
      
  // ———————————————————— 登录开关 ————————————————————————————
  
  handleLoginSwitchSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
        var loginTypeList = values.loginType;
        var loginTypeString = "";
        if(loginTypeList != null) {
          loginTypeList.forEach((item, index)=>{
            //console.log("length="+loginTypeList.length + ", index="+ index)
            if(index >= loginTypeList.length-1) {
              loginTypeString = loginTypeString + item;
            } else {
              loginTypeString = loginTypeString + item + ",";
            }
          })
        }

        var shareTypeList = values.shareType;
        var shareTypeString = "";
        if(shareTypeList != null) {
          shareTypeList.forEach((item, index)=>{
            //console.log("length="+loginTypeList.length + ", index="+ index)
            if(index >= shareTypeList.length-1) {
              shareTypeString = shareTypeString + item;
            } else {
              shareTypeString = shareTypeString + item + ",";
            }
          })
        }


        var adminLoginSwitch = {
          id: values.id,
          loginType: loginTypeString,
          shareType: shareTypeString,
          defaultSignature: values.defaultSignature,
          defaultUserAvatar: values.defaultUserAvatar,
          invitationBindingMethod: values.invitationBindingMethod,
          openInstallAppKey: values.openInstallAppKey,
          androidOneClickAuthorization: values.androidOneClickAuthorization,
        };

        loginSwitchService.updateConfig(adminLoginSwitch)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // 图片修改
  handleChangeFileImage=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        console.log("handleChangeFileImage="+e.file.response);
        this.state.loading=false;

        return e.file.response;
      }
  } 

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表

  };

  handleDefaultUserAvatarChange = ({ file, fileList }) => {
    this.setState({ defaultUserAvatarList: [...fileList] });
  };

  // ———————————————————— 分享配置 ————————————————————————————
 
  handleShareConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef5.current.validateFields()
    .then(values => {

        var appShareConfig = values;
        shareConfigService.updateConfig(appShareConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }
  
  handleLogoChange = ({ file, fileList }) => {
    this.setState({ logoList: [...fileList] });
  };

  // ———————————————————— 注销开关 ————————————————————————————


  handleLogoffSwitchSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef6.current.validateFields()
    .then(values => {

        var logoffSwitchParam = values;
        adminLiveConfigService.updateLogoffSwitch(logoffSwitchParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getAdminLiveConfig1();
    } else if(key == "2") {
      this.getAdminLiveConfig2();
    } else if(key == "3") {
      this.getAdminLiveConfig3();
    } else if(key == "4") {
      this.getLoginSwitch();
    } else if(key == "5") {
      this.getShareConfig();
    } else if(key == "6") {
      this.getAdminLiveConfig4();
    }
  }

  /**
   * 认证开关
   */
  getAdminLiveConfig1=() => {
    let { history } = this.props;
    adminLiveConfigService.getCertificationSwitch()
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef.current.setFieldsValue({
              authIsSex: res.retValue.authIsSex,
              jumpMode: res.retValue.jumpMode,
              isBindPhone: res.retValue.isBindPhone,
              isFunctionTurnOn: res.retValue.isFunctionTurnOn,
            });
          }
          
        }
      )   
  }

  /**
   * 个人中心功能配置
   */
  getAdminLiveConfig2=() => {
    let { history } = this.props;
    adminLiveConfigService.getPersonalCenter()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            isVipSee: res.retValue.isVipSee,
            showWealthAndCharm: res.retValue.showWealthAndCharm,
            showCommonService: res.retValue.showCommonService,
            showOnlineService: res.retValue.showOnlineService,
            onlineServiceUrl: res.retValue.onlineServiceUrl,
          });

          this.setState({personalCenter: res.retValue})
        }
      }
    )  
  }

  /**
   * 安卓上架开关
   */
  getAdminLiveConfig3=() => {
    let { history } = this.props;
    adminLiveConfigService.getAndroidShelfSwitch()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            showInviteToMakeMoney: res.retValue.showInviteToMakeMoney,
            showTaskCenter: res.retValue.showTaskCenter,
            freeMessage: res.retValue.freeMessage,
            adFlag: res.retValue.adFlag,
          });

          this.setState({androidShelfSwitch: res.retValue});
        }
      }
    )  
  }

  /**
   * 登录开关
   */
  getLoginSwitch=() => {
    let { history } = this.props;
    loginSwitchService.getConfig()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          var loginTypeList = res.retValue.loginType;
          var loginType = loginTypeList.split(',');
          //console.log("loginType=" + JSON.stringify(loginType));

          var shareTypeList = res.retValue.shareType;
          var shareType = shareTypeList.split(',');
          //console.log("shareType=" + JSON.stringify(shareType));

          this.formRef4.current.setFieldsValue({
            id: res.retValue.id,
            loginType: loginType,
            shareType: shareType,
            defaultSignature: res.retValue.defaultSignature,
            defaultUserAvatar: res.retValue.defaultUserAvatar,
            withdrawalAmountManner: res.retValue.withdrawalAmountManner,
            invitationBindingMethod:  res.retValue.invitationBindingMethod,
            openInstallAppKey:  res.retValue.openInstallAppKey,
            androidOneClickAuthorization: res.retValue.androidOneClickAuthorization,
          });

          this.setState({defaultUserAvatarList: [{
            url : res.retValue.defaultUserAvatar,
            thumbUrl: res.retValue.defaultUserAvatar,
          }]});

          var loginSwitch = {
            loginType: loginType,
            shareType: shareType,
            defaultSignature: res.retValue.defaultSignature,
            withdrawalAmountManner: res.retValue.withdrawalAmountManner,
            defaultUserAvatar:  res.retValue.defaultUserAvatar,
            invitationBindingMethod:  res.retValue.invitationBindingMethod,
            openInstallAppKey:  res.retValue.openInstallAppKey,
            androidOneClickAuthorization: res.retValue.androidOneClickAuthorization,
          }
          this.setState({loginSwitch: loginSwitch})

        }
      
      }
    ) 
  }

  /**
   * 分享配置
   */
  getShareConfig=() => {
    let { history } = this.props;
    shareConfigService.getConfig()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            id: res.retValue.id,
            logo: res.retValue.logo,
            shareTitle: res.retValue.shareTitle,
            shareDes: res.retValue.shareDes,
            videoShareTitle:  res.retValue.videoShareTitle,
            videoShareDes:  res.retValue.videoShareDes,
          });

          this.setState({logoList: [{
            url : res.retValue.logo,
            thumbUrl: res.retValue.logo,
          }]});
          
          this.setState({shareConfig: res.retValue})
        }
      }
    )  
  }

  /**
   * 注销开关
   */
  getAdminLiveConfig4=() => {
    let { history } = this.props;
    adminLiveConfigService.getLogoffSwitch()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef6.current.setFieldsValue({
            logOffSwitch: res.retValue.logOffSwitch,
            logOffDay: res.retValue.logOffDay,
            logOffInheritSupper: res.retValue.logOffInheritSupper,
          });
          this.setState({logoffSwitch: res.retValue})
        }
      }
    )  
  }



 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/login_sharing_settings">登录分享设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // —————————————————————— 认证开关 ————————————————————————
            <TabPane tab="认证开关" key="1">
              <Form
                  name="CertificationSwitchForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.certificationSwitch}
                >

                      <Form.Item label="只支持女性认证" name="authIsSex" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="authIsSex" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="banner跳转方式" name="jumpMode" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="jumpMode" defaultValue={0}>
                            <Radio value={0}>APP内</Radio>
                            <Radio value={1}>外部浏览器</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="必须绑定手机" name="isBindPhone" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="isBindPhone" defaultValue={0}>
                            <Radio value={0}>开启</Radio>
                            <Radio value={1}>关闭</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="iOS隐藏功能" name="isFunctionTurnOn" labelCol={{span:10}} style={{ width: 420 }}  initialValue={0}>
                          <Radio.Group name="isFunctionTurnOn" defaultValue={0}>
                            <Radio value={0}>不开启</Radio>
                            <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>
                  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleCertificationSwitchSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 个人中心功能配置 ————————————————————————
            <TabPane tab="个人中心功能配置" key="2">
              <Form
                  name="PersonalCenterForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.personalCenter}
                >

                      <Form.Item label="谁看过我贵族开关" name="isVipSee" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="authIsSex" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="财富等级/魅力等级" name="showWealthAndCharm" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="showWealthAndCharm" defaultValue={0}>
                            <Radio value={0}>显示</Radio>
                            <Radio value={1}>隐藏</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="普通客服" name="showCommonService" labelCol={{span:10}} style={{ width: 420 }} initialValue={0} >
                          <Radio.Group name="showCommonService" defaultValue={0}>
                            <Radio value={0}>隐藏</Radio>
                            <Radio value={1}>显示</Radio>
                          </Radio.Group>
                      </Form.Item>
  
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handlePersonalCenterSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 安卓上架开关 ————————————————————————
            <TabPane tab="安卓上架开关" key="3">
              <Form
                  name="AndroidShelfSwitchForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.androidShelfSwitch}
                >

                      <Form.Item label="邀请赚钱" name="showInviteToMakeMoney" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="showInviteToMakeMoney" defaultValue={0}>
                          <Radio value={0}>隐藏</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="任务中心" name="showTaskCenter" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="showTaskCenter" defaultValue={0}>
                            <Radio value={0}>隐藏</Radio>
                            <Radio value={1}>显示</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="私信免费(阿里市场)" name="freeMessage" labelCol={{span:10}} style={{ width: 420 }} initialValue={0} >
                          <Radio.Group name="freeMessage" defaultValue={0}>
                            <Radio value={0}>关闭</Radio>
                            <Radio value={1}>打开</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="广告赚钱(华为)" name="adFlag" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="adFlag" defaultValue={0}>
                            <Radio value={0}>关闭</Radio>
                            <Radio value={1}>打开</Radio>
                          </Radio.Group>
                      </Form.Item>    
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAndroidShelfSwitchSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 登录开关 ————————————————————————
            <TabPane tab="登录开关" key="4">
              <Form
                  name="LoginSwitchForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.loginSwitch}
                >
                    <Form.Item label="id" name="id" initialValue={1} hidden>
                        <Input placeholder="id" />
                    </Form.Item>

                      <Form.Item label="登录方式" name="loginType" labelCol={{span:5}}  style={{ width: 840 }}>
                        <Checkbox.Group>
                          <Row gutter={10}>
                            <Col span={5}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 190 }}>
                                QQ
                              </Checkbox>
                            </Col>
                            <Col span={5}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 190 }}>
                                微信
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>

                      <Form.Item label="分享方式" name="shareType" labelCol={{span:5}} style={{ width: 840 }}>
                        <Checkbox.Group>
                          <Row gutter={20}>
                            <Col span={3}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 150  }}>
                                QQ
                              </Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 150  }}>
                                qq空间
                              </Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="3" style={{ lineHeight: '32px', width: 150  }}>
                                微信
                              </Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="4" style={{ lineHeight: '32px', width: 150  }}>
                                微信朋友圈
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>

                      <Form.Item label="默认个性签名" name="defaultSignature" labelCol={{span:6}} style={{ width: 700 }} >
                          <Input placeholder="默认个性签名"  />
                      </Form.Item>    

                       <Form.Item label="默认用户头像" name="defaultUserAvatar" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}
                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleDefaultUserAvatarChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.defaultUserAvatarList}
                              fileList={this.state.defaultUserAvatarList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>

                      <Form.Item label="邀请绑定方式" name="invitationBindingMethod" labelCol={{span:5}} style={{ width: 840 }} initialValue={0} >
                          <Radio.Group name="freeMessage" defaultValue={0}>
                            <Radio value={1}>邀请码绑定</Radio>
                            <Radio value={2}>OpenInstall自动绑定</Radio>
                            <Radio value={3}>关闭</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="KEY" name="openInstallAppKey" labelCol={{span:6}} style={{ width: 700 }} >
                          <Input placeholder="KEY"  />
                      </Form.Item>  

                      <Form.Item label="安卓一键授权" name="androidOneClickAuthorization" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="androidOneClickAuthorization" defaultValue={0}>
                            <Radio value={0}>关闭</Radio>
                            <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>    
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleLoginSwitchSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 分享配置 ————————————————————————
            <TabPane tab="分享配置" key="5">
              <Form
                  name="ShareConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef5}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.shareConfig}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="分享logo" name="logo" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}
                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleLogoChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.logoList}
                              fileList={this.state.logoList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>

                      <Form.Item label="直播分享标题" name="shareTitle" labelCol={{span:10}} style={{ width: 420 }}>
                          <Input placeholder="直播分享标题"  />
                      </Form.Item>

                      <Form.Item label="直播分享话术" name="shareDes" labelCol={{span:10}} style={{ width: 420 }}>
                          <Input placeholder="直播分享话术"  />
                      </Form.Item>

                      <Form.Item label="视图分享标题" name="videoShareTitle" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="视图分享标题"  />
                      </Form.Item>

                      <Form.Item label="视图分享话术" name="videoShareDes" labelCol={{span:10}} style={{ width: 420 }}>
                          <Input placeholder="视图分享话术"  />
                      </Form.Item>    
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleShareConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 注销开关 ————————————————————————
            <TabPane tab="注销开关" key="6">
              <Form
                  name="LogoffSwitchForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef6}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.logoffSwitch}
                >

                      <Form.Item label="注销开关" name="logOffSwitch" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="logOffSwitch" defaultValue={1}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>
                      
                      <Row>
                        <Form.Item label="注销间隔（天）" name="logOffDay" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                            <InputNumber  />
                        </Form.Item>
                        为0时注销后可以马上注册
                      </Row>

                      <Row>
                        <Form.Item label="注销继承上级" name="logOffInheritSupper" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                            <Radio.Group name="logOffInheritSupper" defaultValue={0}>
                              <Radio value={1}>开启</Radio>
                              <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        </Form.Item>    
                        注销后，再次注册，继承上级
                      </Row>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleLogoffSwitchSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>
          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default BasicSettings;