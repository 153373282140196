import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import BraftEditor from 'braft-editor'
import sysBoxConfigService from '../../../Service/BasicInformation/sysBoxConfigService';
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';

import UpdateSysBoxConfig from './UpdateSysBoxConfig';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class GlobalPopSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 全局弹窗设置 ————————————————————————————
    sysBoxConfigColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'title',
      title: '名称',
      dataIndex: 'title'
    },{
      key: 'status',
      title: '系统虚拟弹窗开关',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "关闭"
        } else if(status === 1) {
          return "开启";
        } 
      }
    },{
      key: 'intervals',
      title: '虚拟弹窗间隔时间',
      dataIndex: 'intervals'
    },{
      key: 'realStatus',
      title: '真实弹窗开关',
      dataIndex: 'realStatus',
      render: (realStatus, row) => {
        if(realStatus === 0) {
          return "关闭"
        } else if(realStatus === 1) {
          return "开启";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateSysBoxConfigDialog:true, updateSysBoxConfigRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    sysBoxConfigTotal: 0,
    sysBoxConfigList: [],
    updateSysBoxConfigRow: null,
    showUpdateSysBoxConfigDialog: false,
    sysBoxConfigPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 世界礼物价格 ————————————————————————————
    
    worldGift: null,
    
    
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 全局弹窗设置 ————————————————————————————
    this.getSysBoxConfig();
   
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 全局弹窗设置 ————————————————————————————

  // 关闭更新
  hideUpdateSysBoxConfigDialog=() => {
    this.setState({showUpdateSysBoxConfigDialog: false});
  }

  // 关闭添加
  hideAddSysBoxConfigDialog=() => {
    this.setState({showAddSysBoxConfigDialog: false});
  }


  // 获取列表
  handleGetSysBoxConfigPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var sysBoxConfigParam={
        pageParam: pageParam
      }

      sysBoxConfigService.getSysBoxConfigPage(sysBoxConfigParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysBoxConfigList: res.retValue.list, sysBoxConfigTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  sysBoxConfigChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      sysBoxConfigPageParam: pageParam,
    });

    var sysBoxConfigParam={
      pageParam: pageParam
    }

    sysBoxConfigService.getSysBoxConfigPage(sysBoxConfigParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({sysBoxConfigList: res.retValue.list, sysBoxConfigTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshSysBoxConfigData=() => {
    let { history } = this.props;

      var sysBoxConfigParam={
        pageParam: this.state.sysBoxConfigPageParam
      }

      sysBoxConfigService.getSysBoxConfigPage(sysBoxConfigParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysBoxConfigList: res.retValue.list, sysBoxConfigTotal: res.retValue.total});
          }
        }
      )
  }



  // ———————————————————— 世界礼物价格 ————————————————————————————
    
  handleWorldGiftSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var worldGiftParam = values;
        adminLiveConfigService.updateWorldGift(worldGiftParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }
    
  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getSysBoxConfig();
    } else if(key == "2") {
      this.getAdminLiveConfig();
    }
  }

  /**
   * 全局弹窗设置
   */
  getSysBoxConfig =() =>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var sysBoxConfigParam={
      pageParam: pageParam
    }

    sysBoxConfigService.getSysBoxConfigPage(sysBoxConfigParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({sysBoxConfigList: res.retValue.list, sysBoxConfigTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 世界礼物价格
   */
  getAdminLiveConfig =() =>{
    let { history } = this.props;
    adminLiveConfigService.getWorldGift()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            bidFee: res.retValue.bidFee,
          });

        }
      }
    )  
  }


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/global_pop_settings">全局弹窗设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 全局弹窗设置 ————————————————————————————
            <TabPane tab="全局弹窗设置" key="1">
              

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.sysBoxConfigList}
                columns={this.state.sysBoxConfigColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.sysBoxConfigTotal, defaultCurrent: 1, onChange: this.sysBoxConfigChangePage}}
              >
              </Table>
              <UpdateSysBoxConfig rowData={this.state.updateSysBoxConfigRow} close={this.hideUpdateSysBoxConfigDialog} visible={this.state.showUpdateSysBoxConfigDialog} refreshData={this.refreshSysBoxConfigData}></UpdateSysBoxConfig>
              
            </TabPane>
            
            
            // ———————————————————— 世界礼物价格 ————————————————————————————
            <TabPane tab="世界礼物价格" key="2">
              <Form
                  name="WorldGiftForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.worldGift}
                >
                      <Form.Item label="全局飘屏礼物金额" name="bidFee" labelCol={{span:10}} style={{ width: 420 }}>
                          <InputNumber  />
                      </Form.Item>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleWorldGiftSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
              
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default GlobalPopSettings;