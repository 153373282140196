import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import userLastChatService from '../../../Service/PrivateLetter/userLastChatService';
import userChatRecordService from '../../../Service/PrivateLetter/userChatRecordService';

import ShowUserChatRecord from './ShowUserChatRecord';

import VideoPlay from '../../UserMgr/HostUser/VideoPlay';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class MessageLogging extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 财富等级 ————————————————————————————
    userLastChatColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'uidAvatar',
      title: '发送用户头像',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'msgType',
      title: '消息类型',
      dataIndex: 'msgType',
      render: (msgType, row) => {
        if(msgType === 1) {
          return "文本"
        } else if(msgType === 2) {
          return "图片"
        } else if(msgType === 3) {
          return "语音"
        } else if(msgType === 4) {
          return "视频通话"
        }
      }
    },{
      key: 'content',
      title: '消息',
      dataIndex: 'content'
    },{
      key: 'toUidAvatar',
      title: '接收用户头像',
      dataIndex: 'toUidAvatar',
      align: 'center',
      render: (toUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={toUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'isCostCoin',
      title: '消息类型',
      dataIndex: 'isCostCoin',
      render: (isCostCoin, row) => {
        if(isCostCoin === 0) {
          return "免费"
        } else if(isCostCoin === 1) {
          return "付费"
        } 
      }
    },{
      key: 'costCoin',
      title: '消费金币',
      dataIndex: 'costCoin'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.showUserChatRecord(row.uid)}
                style={{marginRight:'5px'}} 
                type="primary">发送者消息记录
              </Button>
              <Button 
                onClick={() => this.showUserChatRecord(row.toUid)}
                style={{marginRight:'5px'}} 
                type="primary">接受者消息记录
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteUserLastChat(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    userLastChatTotal: 0,
    userLastChatList: null,
    updateUserLastChatRow: null,
    showUpdateUserLastChatDialog: false,
    addUserLastChatRow: null,
    showAddUserLastChatDialog: false,
    showUserChatRecordRow: null,
    showUserChatRecordDialog: false,
    videoRow: null,
    showVideoDialog: false,
    userLastChatPageParam:{
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var userLastChatParam = {
      pageParam : pageParam,
    }
    userLastChatService.getUserLastChatPage(userLastChatParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userLastChatList: res.retValue.list, userLastChatTotal: res.retValue.total});
        }                  
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 消息列表 ————————————————————————————

  // 获取消息记录
  handleGetUserLastChatPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var userLastChatParam = {
        uid: values.uid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        msgType: values.msgType,
        content: values.content,
        pageParam : pageParam,
      }
      userLastChatService.getUserLastChatPage(userLastChatParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userLastChatList: res.retValue.list, userLastChatTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }

  // 用户分页
  userLastChatChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var timeStart = null;
        var timeEnd = null;
        if(values.sendTime != null) {
          timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
          timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }

        var userLastChatParam = {
          uid: values.uid,
          toUid: values.toUid,
          startTime: timeStart,
          endTime: timeEnd,
          msgType: values.msgType,
          content: values.content,
          pageParam : pageParam,
        }

        this.setState({
          userLastChatPageParam: userLastChatParam,
        });

        userLastChatService.getUserLastChatPage(userLastChatParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({userLastChatList: res.retValue.list, userLastChatTotal: res.retValue.total});
            }                  
          }
        )
    })
  }

  // 刷新数据
  refreshUserLastChatData=() => {
    let { history } = this.props;
    //console.log("refreshUserLastChatData");

    var userLastChatParam = {
      pageParam : this.state.userLastChatPageParam,
    }
    userLastChatService.getUserLastChatPage(userLastChatParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userLastChatList: res.retValue.list, userLastChatTotal: res.retValue.total});
        }                  
      }
    )
  }

  // 隐藏
  hideUpdateUserLastChatDialog=() => {
    this.setState({showUpdateUserLastChatDialog: false});
  }

  // 隐藏
  hideAddUserLastChatDialog=() => {
    this.setState({showAddUserLastChatDialog: false});
  }

  // 根据Id删除标签
  deleteUserLastChat=(id) => {
      let { history } = this.props;
      let userLastChatParam = {
        id : id
      };
      userLastChatService.deleteUserLastChat(userLastChatParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshUserLastChatData();
          message.success('删除成功！');
        }                  
        
      })
  }

  // —————————————————— 消息数据 ————————————————————————
  
  /**
   * 获取用户聊天记录
   * @param {*} options
   */
  showUserChatRecord = (userId) => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      var userChatRecordParam = {
        userId: userId,
        pageParam: pageParam,
      };
      // 分页获取动态评论
      userChatRecordService.getUserChatRecordPage(userChatRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if (res.retValue == null) {
            return;
          }
    
          this.state.showUserChatRecordRow = {
            userId: userId,
            recordList : res.retValue.list,
            recordTotal: res.retValue.total
          };
    
          this.setState({ showUserChatRecordDialog: true });
        }                  
        
      });
    };

  // 隐藏
  hideUserChatRecordDialog=() => {
    this.setState({showUserChatRecordDialog: false});
  }

  /**
   * 消息记录分页
   * @param {*} page 
   * @param {*} pageSize 
   * @param {*} videoid 
   */
  userChatRecordChangePage=(page, pageSize, userId) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var userChatRecordParam = {
          userId: userId,
          pageParam: pageParam,
        };

        // 分页获取消息记录
        userChatRecordService.getUserChatRecordPage(userChatRecordParam)
        .then((res) => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if (res.retValue == null) {
              return;
            }
      
            this.state.showUserChatRecordRow = {
              userId: userId,
              recordList : res.retValue.list,
              recordTotal: res.retValue.total
            };
      
            this.setState({ showUserChatRecordDialog: true });
          }                  
          
        });
    })
  }


  /**
   * 播放视频
   */
  handlePlayVideo=() => {
    console.log("videoRow=" + JSON.stringify(this.state.videoRow))
    this.setState({showVideoDialog:true })
  }

  // 关闭弹窗
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }
 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/message_logging">消息记录</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="UserLastChatListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="发送者" name="uid">
                        <Input placeholder="发送者" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="接受者" name="toUid">
                        <Input placeholder="接受者" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="发送时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="消息类型" name="msgType">
                        <Select style={{ width: 220 }} defaultValue="全部">
                          <Option >全部</Option>
                          <Option value={1}>文本</Option>
                          <Option value={2}>图片</Option>
                          <Option value={3}>语音</Option>
                          <Option value={4}>视频通话</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={5}>
                      <Form.Item label="内容" name="content">
                        <Input placeholder="内容" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={6} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetUserLastChatPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userLastChatList}
                columns={this.state.userLastChatColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userLastChatTotal, defaultCurrent: 1, onChange: this.userLastChatChangePage}}
              >
              </Table>
              <ShowUserChatRecord rowData={this.state.showUserChatRecordRow} close={this.hideUserChatRecordDialog} visible={this.state.showUserChatRecordDialog} refreshRecordData={this.userChatRecordChangePage} ></ShowUserChatRecord>
              <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>

        </StickyContainer>
        
      </div>
    );
  }
}
export default MessageLogging;