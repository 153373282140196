import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import simulationMssageService from '../../../Service/PrivateLetter/simulationMssageService';

import AddSimulationMssage from './AddSimulationMssage';
import UpdateSimulationMssage from './UpdateSimulationMssage';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class SimulateMessage extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 财富等级 ————————————————————————————
    simulationMssageColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'content',
      title: '内容',
      dataIndex: 'content'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateSimulationMssageDialog:true, updateSimulationMssageRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteSimulateMessage(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    simulationMssageTotal: 0,
    simulationMssageList: null,
    updateSimulationMssageRow: null,
    showUpdateSimulationMssageDialog: false,
    addSimulationMssageRow: null,
    showAddSimulationMssageDialog: false,
    simulationMssagePageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var simulationMssageParam = {
      pageParam : pageParam,
    }
    simulationMssageService.getSimulationMssagePage(simulationMssageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({simulationMssageList: res.retValue.list, simulationMssageTotal: res.retValue.total});
        }                  
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 等级礼物列表 ————————————————————————————

  // 获取等级礼物
  handleGetSimulationMssagePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var simulationMssageParam = {
        content : values.content,
        pageParam : pageParam,
      }
      simulationMssageService.getSimulationMssagePage(simulationMssageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({simulationMssageList: res.retValue.list, simulationMssageTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  }

  // 用户分页
  simulationMssageChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          simulationMssagePageParam: pageParam,
        });

        var simulationMssageParam = {
          pageParam : pageParam,
        }
        simulationMssageService.getSimulationMssagePage(simulationMssageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({simulationMssageList: res.retValue.list, simulationMssageTotal: res.retValue.total});
            }                  
          }
        )
    })
  }

  // 刷新数据
  refreshSimulationMssageData=() => {
    //console.log("refreshSimulationMssageData");
    let { history } = this.props;

    var simulationMssageParam = {
      pageParam : this.state.simulationMssagePageParam,
    }
    simulationMssageService.getSimulationMssagePage(simulationMssageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({simulationMssageList: res.retValue.list, simulationMssageTotal: res.retValue.total});
        }                  
      }
    )
  }

  // 隐藏
  hideUpdateSimulationMssageDialog=() => {
    this.setState({showUpdateSimulationMssageDialog: false});
  }

  // 隐藏
  hideAddSimulationMssageDialog=() => {
    this.setState({showAddSimulationMssageDialog: false});
  }

  // 根据Id删除标签
  deleteSimulateMessage=(id) => {
      let { history } = this.props;
      let simulationMssageParam = {
        id : id
      };
      simulationMssageService.deleteSimulationMssage(simulationMssageParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshSimulationMssageData();
          message.success('删除成功！');
        }                  
        
      })
  }
  
  
 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/simulate_message">模拟消息</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="SimulationMssageListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="内容" name="content">
                        <Input placeholder="内容" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetSimulationMssagePage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddSimulationMssageDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.simulationMssageList}
                columns={this.state.simulationMssageColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.simulationMssageTotal, defaultCurrent: 1, onChange: this.simulationMssageChangePage}}
              >
              </Table>
              <AddSimulationMssage close={this.hideAddSimulationMssageDialog} visible={this.state.showAddSimulationMssageDialog} refreshData={this.refreshSimulationMssageData}></AddSimulationMssage>
              <UpdateSimulationMssage rowData={this.state.updateSimulationMssageRow} close={this.hideUpdateSimulationMssageDialog} visible={this.state.showUpdateSimulationMssageDialog} refreshData={this.refreshSimulationMssageData}></UpdateSimulationMssage>

        </StickyContainer>
        
      </div>
    );
  }
}
export default SimulateMessage;