import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 获取nginx配置
   * @param {*} 
   * @returns
   */
  getNginxConfig() {
      //console.log("agentDataStatisticsParam=" + JSON.stringify(agentDataStatisticsParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/nginx/getNginxConfig",
        data: null,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


}