import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo } from "../../../Common/Auth";
import moment from "moment";
import medalService from "../../../Service/GradeMgr/medalService";
import gradeService from "../../../Service/GradeMgr/gradeService";
import AddUserMedal from './AddUserMedal';
import UpdateUserMedal from './UpdateUserMedal';
import AddWealthMedal from './AddWealthMedal';
import UpdateWealthMedal from './UpdateWealthMedal';
import AddGuardMedal from './AddGuardMedal';
import UpdateGuardMedal from './UpdateGuardMedal';
import { Logout } from '../../../Common/Auth';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class MedalMgr extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state = {
    // ———————————————————— 用户勋章 ————————————————————————————

    userMedalColumns: [
      {
        key: "id",
        title: "勋章ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "勋章名称",
        dataIndex: "name",
      },
      {
        key: "medalLogo",
        title: "勋章图片",
        dataIndex: "medalLogo",
        render: (medalLogo, row) => {
          if (medalLogo != null && medalLogo != "") {
            return <Image width={50} height={50} src={medalLogo} />;
          }
        },
      },
      {
        key: "lv",
        title: "勋章级别",
        dataIndex: "lv",
      },
      {
        key: "type",
        title: "勋章类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户勋章"
          } else if(type === 2) {
            return "财富勋章";
          } else if(type === 4) {
            return "贵族勋章";
          } else if(type === 5) {
            return "守护勋章";
          } 
        }
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateUserMedalRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    userMedalTotal: 0,
    userMedalList: null,
    updateUserMedalRow: null,
    showUpdateUserMedalDialog: false,
    addUserMedalRow: null,
    showAddUserMedalDialog: false,
    userMedalPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 财富勋章 ————————————————————————————

    wealthMedalColumns: [
      {
        key: "id",
        title: "勋章ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "勋章名称",
        dataIndex: "name",
      },
      {
        key: "medalLogo",
        title: "勋章图片",
        dataIndex: "medalLogo",
        render: (medalLogo, row) => {
          if (medalLogo != null && medalLogo != "") {
            return <Image width={50} height={50} src={medalLogo} />;
          }
        },
      },
      {
        key: "lv",
        title: "勋章级别",
        dataIndex: "lv",
      },
      {
        key: "type",
        title: "勋章类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户勋章"
          } else if(type === 2) {
            return "财富勋章";
          } else if(type === 4) {
            return "贵族勋章";
          } else if(type === 5) {
            return "守护勋章";
          } 
        }
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateWealthMedalRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    wealthMedalTotal: 0,
    wealthMedalList: null,
    updateWealthMedalRow: null,
    showUpdateWealthMedalDialog: false,
    addWealthMedalRow: null,
    showAddWealthMedalDialog: false,
    wealthMedalPageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 守护勋章 ————————————————————————————
    guardMedalColumns: [
      {
        key: "id",
        title: "勋章ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "勋章名称",
        dataIndex: "name",
      },
      {
        key: "medalLogo",
        title: "勋章图片",
        dataIndex: "medalLogo",
        render: (medalLogo, row) => {
          if (medalLogo != null && medalLogo != "") {
            return <Image width={50} height={50} src={medalLogo} />;
          }
        },
      },
      {
        key: "lv",
        title: "勋章级别",
        dataIndex: "lv",
      },
      {
        key: "type",
        title: "勋章类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户勋章"
          } else if(type === 2) {
            return "财富勋章";
          } else if(type === 4) {
            return "贵族勋章";
          } else if(type === 5) {
            return "守护勋章";
          } 
        }
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateGuardMedalRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    guardMedalTotal: 0,
    guardMedalList: null,
    updateGuardMedalRow: null,
    showUpdateGuardMedalDialog: false,
    addGuardMedalRow: null,
    showAddGuardMedalDialog: false,
    guardMedalPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 用户勋章 ————————————————————————————
    this.getMedal1();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 用户勋章 ————————————————————————————
  // 用户勋章分页
  userMedalChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        userMedalPageParam: pageParam,
      });

      var medalParam = {
        type: 1,
        pageParam: pageParam,
      };
      medalService.getMedalPage(medalParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            userMedalList: res.retValue.list,
            userMedalTotal: res.retValue.total,
          });
        }    

      });
    });
  };

  // 刷新数据
  refreshUserMedalData = () => {
    //console.log("refreshUserMedalData");
    let { history } = this.props;

    var medalParam = {
      type: 1,
      pageParam: this.state.userMedalPageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          userMedalList: res.retValue.list,
          userMedalTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateUserMedalDialog = () => {
    this.setState({ showUpdateUserMedalDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddUserMedalDialog = () => {
    this.setState({ showAddUserMedalDialog: false });
  };

  /**
   * 获取添加用户勋章数据
   * @param {*} options
   */
  getAddUserMedalRowData = () => {
    let { history } = this.props;
    var gradeParam = {
      type: 1,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.addUserMedalRow = {
          userGradeList: res.retValue,
        };
  
        this.setState({ showAddUserMedalDialog: true });
      }    
      
    });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateUserMedalRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 1,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateUserMedalRow = {
          id: row.id,
          userGradeList: res.retValue,
          name: row.name,
          medalLogo: row.medalLogo,
          type: row.type,
          lv: row.lv,
        };
  
        this.setState({ showUpdateUserMedalDialog: true });
      }    
      
    });
  };

  // ———————————————————— 财富勋章 ————————————————————————————
  // 财富勋章分页
  wealthMedalChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        wealthMedalPageParam: pageParam,
      });

      var medalParam = {
        type: 2,
        pageParam: pageParam,
      };
      medalService.getMedalPage(medalParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            wealthMedalList: res.retValue.list,
            wealthMedalTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshWealthMedalData = () => {
    //console.log("refreshWealthMedalData");
    let { history } = this.props;

    var medalParam = {
      type: 2,
      pageParam: this.state.wealthMedalPageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          wealthMedalList: res.retValue.list,
          wealthMedalTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateWealthMedalDialog = () => {
    this.setState({ showUpdateWealthMedalDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddWealthMedalDialog = () => {
    this.setState({ showAddWealthMedalDialog: false });
  };

  /**
   * 获取添加用户勋章数据
   * @param {*} options
   */
  getAddWealthMedalRowData = () => {
    let { history } = this.props;
    var gradeParam = {
      type: 2,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.addWealthMedalRow = {
          wealthGradeList: res.retValue,
        };
  
        this.setState({ showAddWealthMedalDialog: true });
      }    
      
    });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateWealthMedalRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 2,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateWealthMedalRow = {
          id: row.id,
          wealthGradeList: res.retValue,
          name: row.name,
          medalLogo: row.medalLogo,
          type: row.type,
          lv: row.lv,
        };
  
        this.setState({ showUpdateWealthMedalDialog: true });
      }    
      
    });
  };

  // ———————————————————— 守护勋章 ————————————————————————————
  // 守护勋章分页
  guardMedalChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        guardMedalPageParam: pageParam,
      });

      var medalParam = {
        type: 5,
        pageParam: pageParam,
      };
      medalService.getMedalPage(medalParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            guardMedalList: res.retValue.list,
            guardMedalTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshGuardMedalData = () => {
    //console.log("refreshUserMedalData");
    let { history } = this.props;

    var medalParam = {
      type: 5,
      pageParam: this.state.guardMedalPageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          guardMedalList: res.retValue.list,
          guardMedalTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateGuardMedalDialog = () => {
    this.setState({ showUpdateGuardMedalDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddGuardMedalDialog = () => {
    this.setState({ showAddGuardMedalDialog: false });
  };

  /**
   * 获取添加用户勋章数据
   * @param {*} options
   */
  getAddGuardMedalRowData = () => {
    this.setState({ showAddGuardMedalDialog: true });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateGuardMedalRowData = (row) => {

    this.state.updateGuardMedalRow = {
      id: row.id,
      name: row.name,
      medalLogo: row.medalLogo,
      type: row.type,
      lv: row.lv,
    };

    this.setState({ showUpdateGuardMedalDialog: true });
  };


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getMedal1();
    } else if(key == "2") {
      this.getMedal2();
    } else if(key == "3") {
      this.getMedal3();
    }
  }

  /**
   * 用户勋章
   */
  getMedal1= () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var medalParam = {
      type: 1,
      pageParam: pageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          userMedalList: res.retValue.list,
          userMedalTotal: res.retValue.total,
        });
      }    
    
    });
  }

  /**
   * 财富勋章
   */
  getMedal2= () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var wealthMedalParam = {
      type: 2,
      pageParam: pageParam,
    };
    medalService.getMedalPage(wealthMedalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          wealthMedalList: res.retValue.list,
          wealthMedalTotal: res.retValue.total,
        });
      }    
      
    });
  }

  /**
   * 守护勋章
   */
  getMedal3= () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var medalParam = {
      type: 5,
      pageParam: pageParam,
    };
    medalService.getMedalPage(medalParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          guardMedalList: res.retValue.list,
          guardMedalTotal: res.retValue.total,
        });
      }    
      
    });
  }


  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/medal_mgr">勋章管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            // ———————————————————— 用户勋章 ——————————————————————
            <TabPane tab="用户勋章" key="1">
              <Form
                name="userMedalListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: "20px 0px 0px 100px" }}
              >
                <Row gutter={24}>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.getAddUserMedalRowData} >
                      + 新增
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.userMedalList}
                columns={this.state.userMedalColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.userMedalTotal,
                  defaultCurrent: 1,
                  onChange: this.userMedalChangePage,
                }}
              ></Table>
              <AddUserMedal
                rowData={this.state.addUserMedalRow}
                close={this.hideAddUserMedalDialog}
                visible={this.state.showAddUserMedalDialog}
                refreshData={this.refreshUserMedalData}
              ></AddUserMedal>
              <UpdateUserMedal
                rowData={this.state.updateUserMedalRow}
                close={this.hideUpdateUserMedalDialog}
                visible={this.state.showUpdateUserMedalDialog}
                refreshData={this.refreshUserMedalData}
              ></UpdateUserMedal>
            </TabPane>
            
            // ———————————————————— 财富勋章 ————————————————————————————
            <TabPane tab="财富勋章" key="2">
              <Form
                name="wealthMedalListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: "20px 0px 0px 100px" }}
              >
                <Row gutter={24}>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.getAddWealthMedalRowData} >
                      + 新增
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.wealthMedalList}
                columns={this.state.wealthMedalColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.wealthMedalTotal,
                  defaultCurrent: 1,
                  onChange: this.wealthMedalChangePage,
                }}
              ></Table>
              <AddWealthMedal
                rowData={this.state.addWealthMedalRow}
                close={this.hideAddWealthMedalDialog}
                visible={this.state.showAddWealthMedalDialog}
                refreshData={this.refreshWealthMedalData}
              ></AddWealthMedal>
              <UpdateWealthMedal
                rowData={this.state.updateWealthMedalRow}
                close={this.hideUpdateWealthMedalDialog}
                visible={this.state.showUpdateWealthMedalDialog}
                refreshData={this.refreshWealthMedalData}
              ></UpdateWealthMedal>


            </TabPane>
            
            // —————————————————————— 守护勋章 ————————————————————————
            <TabPane tab="守护勋章" key="3">
              <Form
                name="guardMedalListForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: "20px 0px 0px 100px" }}
              >
                <Row gutter={24}>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.getAddGuardMedalRowData} >
                      + 新增
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.guardMedalList}
                columns={this.state.guardMedalColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.guardMedalTotal,
                  defaultCurrent: 1,
                  onChange: this.guardMedalChangePage,
                }}
              ></Table>
              <AddGuardMedal
                rowData={this.state.addGuardMedalRow}
                close={this.hideAddGuardMedalDialog}
                visible={this.state.showAddGuardMedalDialog}
                refreshData={this.refreshGuardMedalData}
              ></AddGuardMedal>
              <UpdateGuardMedal
                rowData={this.state.updateGuardMedalRow}
                close={this.hideUpdateGuardMedalDialog}
                visible={this.state.showUpdateGuardMedalDialog}
                refreshData={this.refreshGuardMedalData}
              ></UpdateGuardMedal>

            </TabPane>
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default MedalMgr;
