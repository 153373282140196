import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import finTranRecordService from '../../../Service/FinanceMgr/finTranRecordService';
import vipService from '../../../Service/NobleMgr/vipService';

import UpdateNoble from './UpdateNoble';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class NoblePurchaseRecord extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 贵族购买记录 ————————————————————————————

    noblePurchaseRecordColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'uidAvatar',
      title: '用户图片',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'consumptionCoin',
      title: '消费金币',
      dataIndex: 'consumptionCoin'
    },{
      key: 'consumptionMoney',
      title: '消费人民币',
      dataIndex: 'consumptionMoney'
    },{
      key: 'afterCoin',
      title: '余金币',
      dataIndex: 'afterCoin'
    },{
      key: 'proId',
      title: '贵族id',
      dataIndex: 'proId'
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    noblePurchaseRecordTotal: 0,
    noblePurchaseRecordList: null,

    vipRow: null,
    showUpdateNobleDialog: false,
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 支付记录 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var finTranRecordParam = {
      tranType2: 27,
      pageParam: pageParam,
    };
    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          noblePurchaseRecordList: res.retValue.list,
          noblePurchaseRecordTotal: res.retValue.total,
        });
      }
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 支付记录 ————————————————————————————
  // 获取等级礼物
  handleGetNoblePurchaseRecordPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var finTranRecordParam = {
        tranType2: 27,
        pageParam: pageParam,
      };
      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            noblePurchaseRecordList: res.retValue.list,
            noblePurchaseRecordTotal: res.retValue.total,
          });
        }
        
      });
      
    })

  }



  // 贵族特权分页
  noblePurchaseRecordChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var finTranRecordParam = {
        tranType2: 27,
        pageParam: pageParam,
      };
      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            noblePurchaseRecordList: res.retValue.list,
            noblePurchaseRecordTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshNoblePurchaseRecordData = () => {
    //console.log("refreshNoblePurchaseRecordData");
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };

    var finTranRecordParam = {
      tranType2: 27,
      pageParam: pageParam,
    };
    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          noblePurchaseRecordList: res.retValue.list,
          noblePurchaseRecordTotal: res.retValue.total,
        });
      }
      
    });
  };


  getVipRowByUpdateVideoPrice = () => {
    let { history } = this.props;

    // 获取vip列表
    vipService.getOnlineVipList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.vipRow = {
          vipList: res.retValue,
        };
        this.setState({ showUpdateNobleDialog: true });
      }    
      
    });
  };

  // 关闭更新
  hideUpdateNobleDialog=() => {
    this.setState({showUpdateNobleDialog: false});
  }




  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/noble_purchase_record">贵族购买记录</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="NoblePurchaseRecordListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={3}>
                    <Form.Item label="用户UID" name="uid">
                        <Input placeholder="用户UID"/>
                    </Form.Item>
                  </Col>

                  <Col span={6} key={5} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetNoblePurchaseRecordPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button type="primary" onClick={() => {this.getVipRowByUpdateVideoPrice()}}  >
                      赠送贵族
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.noblePurchaseRecordList}
              columns={this.state.noblePurchaseRecordColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.noblePurchaseRecordTotal,
                defaultCurrent: 1,
                onChange: this.noblePurchaseRecordChangePage,
              }}
            ></Table>
            <UpdateNoble rowData={this.state.vipRow} close={this.hideUpdateNobleDialog} visible={this.state.showUpdateNobleDialog} refreshData={this.refreshNoblePurchaseRecordData}></UpdateNoble>

        </StickyContainer>
      </div>
    );
  }
}
export default NoblePurchaseRecord;
