import React, { Component } from 'react'
import { Form, Input, Upload, Button, message, Select, Avatar ,Radio, InputNumber} from 'antd';
import { GetLoginToken } from '../../../Common/Auth';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';



const { Option } = Select;

/**
 * 添加权限model
 */
class AddAuthorFrm extends Component {
    formRef = React.createRef();

    state={
        loading: false,
        imageUrl: null,
        fileList: [],
        previewOpen: null,
        previewImage: null,
        previewTitle: null,
        previewVisible: false,
        dealScalePlanList: this.props.rowData.dealScalePlanList,
        anchorStarList: this.props.rowData.anchorStarList,
        liveChannelList: this.props.rowData.liveChannelList,
        posterUrl: null,
        posterList:[],
    }

    // 页面第一次渲染时执行
    componentDidMount=() => {
        this.state.imageUrl = null;
        this.state.loading = false;
        this.formRef.current.setFieldsValue({
            type: "menu"
        });
    }

    // 图片修改
    handleChangeFileImage=(e) => {
        if (e.file.status === 'uploading') {
            this.state.loading=true;
            return;
        }
        if (e.file.status === 'done') {
            //console.log("test="+e.file.response);
            this.state.loading=false;
            //this.state.imageUrl=e.file.response;
            return e.file.response;
          }
    }

    // 上传图片前置校验
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file,1)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  };
    /**
     * 上传按钮
     */
    uploadButton = (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );



    handleChange = ({ file, fileList }) => {
        //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
        //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
        

      };

    handlePreview = (file) => {
        const imageUrl = file.url || file.thumbUrl || '';

        this.state.previewImage = imageUrl;
        this.state.previewVisible = true;
    };

    handleCancel = () => {
        this.state.previewVisible = false;
    };

    normFile = (e) => {
        //console.log("normFile e=" + JSON.stringify(e)); // file 是当前正在上传的 单个 img
        var fileList = e.fileList;
        var fileListString = "";
        if(fileList.length <= 0) {
            return fileListString;
        }
        for(var i=0; i< fileList.length;i++) {
            var file = fileList[i];
            fileListString = fileListString + file.response;
            if(i < fileList.length-1) {
                fileListString = fileListString + ",";
            }
        }
        return fileListString;
    };

    // 上传视频前置校验
    beforeVideoUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('video must smaller than 5MB!');
        }
        return isLt5M;
    }
    
    // 上传语音前置校验
    beforeAudioUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('viaudiodeo must smaller than 2MB!');
        }
        return isLt2M;
    }



    render () {
        return (
            <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            
        >
            <Form.Item label="账号类型" name="role" initialValue={1} rules={ [
                    {
                        required: true,
                        message: '请选择账号类型!',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={1}>
                  <Option value={1}>主播用户</Option>
                </Select>
            </Form.Item>

            <Form.Item label="用户LOGO" name="avatar" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="用户昵称" name="username" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入用户昵称',
                    }
                    ]
                }>
                <Input placeholder="用户昵称" />
            </Form.Item>

            <Form.Item label="手机号" name="mobile" rules={ [
                    {
                        pattern: /\d{11}/gi,
                        message: '请输入11位电话数字!',
                    },
                    {
                        required: true,
                        message: '请输入手机号!',
                    }
                    ]
                }>
                <Input  placeholder="手机号" />
            </Form.Item>

            <Form.Item label="用户密码" name="password" rules={ [
                    {
                        pattern: /\w{6,20}/gi,
                        message: '请输入6-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入密码!',
                    }
                    ]
                }>
                <Input.Password placeholder="密码"/>
            </Form.Item>


            <Form.Item label="用户性别" name="sex" initialValue={2} rules={ [
                    {
                        required: true,
                        message: '请选择权限类型',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={2}>
                  <Option value={2}>女</Option>
                </Select>
            </Form.Item>

            <Form.Item label="个性签名" name="signature">
                <Input placeholder="个性签名" />
            </Form.Item>

            <Form.Item label="账号状态" name="status" initialValue={0} rules={ [
                    {
                        required: true,
                        message: '请选择账号状态!',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={0}>
                  <Option value={0}>正常</Option>
                </Select>
            </Form.Item>

            <Form.Item label="展示图片" name="portrait" 
                valuePropName="filelist"
                getValueFromEvent={this.normFile}
                >
                <Upload
                    accept="image/*"
                    data={file => ({
                        image_file: file
                    })}
                    listType="picture-card"
                    fileList={this.state.filesList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                    {
                        this.state.fileList.length >= 6 ? null : <Button icon={<UploadOutlined />}> upload </Button>
                    }
                </Upload>
            </Form.Item>

            <Form.Item label="分成方案" name="dealScalePlan" initialValue={this.state.dealScalePlanList[0].id} rules={ [
                    {
                        required: true,
                        message: '请选择分成方案!',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={this.state.dealScalePlanList[0].id}>
                    { this.state.dealScalePlanList.map((item)=>
                        <Option value={item.id}>{item.planName}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item label="是否推荐" name="isRecommend" >
                <Radio.Group name="isRecommend" defaultValue={0}>
                    <Radio value={0}>未推荐</Radio>
                    <Radio value={1}>推荐中</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="主播星级" name="starId" initialValue={this.state.anchorStarList[0].id} rules={ [
                    {
                        required: true,
                        message: '请选择主播星级!',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={this.state.anchorStarList[0].id}>
                    { this.state.anchorStarList.map((item)=>
                        <Option value={item.id}>{item.starName}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item label="虚拟位置" name="isOOOAccount" >
                <Radio.Group name="isOOOAccount" defaultValue={0}>
                    <Radio value={0}>否</Radio>
                    <Radio value={1}>是</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="展示在首页" name="isShowHomePage" >
                <Radio.Group name="isShowHomePage" defaultValue={0}>
                    <Radio value={0}>不展示</Radio>
                    <Radio value={1}>展示</Radio>
                </Radio.Group>
            </Form.Item>


            <Form.Item label="一对一分类" name="headNo" initialValue={this.state.liveChannelList[0].id} >
                <Select style={{ width: 220 }} defaultValue={this.state.liveChannelList[0].id}>
                    { this.state.liveChannelList.map((item)=>
                        <Option value={item.id}>{item.title}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item label="分类排序" name="oooHomePageSortNo">
                <InputNumber placeholder="分类排序" />
            </Form.Item>

            <Form.Item label="视频费用" name="videoCoin" initialValue={20}>
                <Select style={{ width: 220 }} defaultValue={20}>
                    <Option value={20}>20金币/分钟</Option>
                </Select>
            </Form.Item>

            <Form.Item label="语音费用" name="voiceCoin" initialValue={20}>
                <Select style={{ width: 220 }} defaultValue={20}>
                    <Option value={20}>20金币/分钟</Option>
                </Select>
            </Form.Item>

            <Form.Item label="一对一海报" name="poster" 
                valuePropName="filelist"
                getValueFromEvent={this.normFile}
                >
                <Upload
                    accept="image/*"
                    data={file => ({
                        image_file: file
                    })}
                    listType="picture-card"
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                    {
                        this.state.posterList.length >= 8 ? null : <Button icon={<UploadOutlined />}> upload </Button>
                    }
                </Upload>
            </Form.Item>

            <Form.Item label="一对一展示视频" name="video" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="video/*"
                    name="fiavatarle"
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeVideoUpload}
                >
                     <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="展示视频封面" name="videoImg" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                     {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="一对一展示声音" name="voice" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="audio/*"
                    name="fiavatarle"
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeAudioUpload}
                >
                     <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>
        </Form>
        )
    }
}

export default AddAuthorFrm