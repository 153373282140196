import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import gradeService from '../../../Service/GradeMgr/gradeService';
import AddCharmGrade from './AddCharmGrade';
import UpdateCharmGrade from './UpdateCharmGrade';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class UserReports extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 魅力等级 ————————————————————————————
    charmGradeColumns: [{
      key: 'id',
      title: '等级ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '等级名称',
      dataIndex: 'name'
    },{
      key: 'grade',
      title: '等级值',
      dataIndex: 'grade'
    },{
      key: 'gradeIcon',
      title: '等级图片',
      dataIndex: 'gradeIcon',
      render: (gradeIcon, row) => {
        if(gradeIcon != null && gradeIcon != "") {
          return <Image
              width={50}
              height={30}
              src={gradeIcon}
            />
        }
      }
    },{
      key: 'startVal',
      title: '范围起始值',
      dataIndex: 'startVal'
    },{
      key: 'unit',
      title: '范围值单位描述',
      dataIndex: 'unit'
    },{
      key: 'type',
      title: '等级类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "用户等级"
        } else if(type === 2) {
          return "财富等级";
        } else if(type === 3) {
          return "主播等级";
        } else if(type === 4) {
          return "贵族等级";
        } 
      }
    },{
      key: 'addTime',
      title: '添加时间',
      dataIndex: 'addTime',
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateCharmGradeDialog:true, updateCharmGradeRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    charmGradeTotal: 0,
    charmGradeList: null,
    updateCharmGradeRow: null,
    showUpdateCharmGradeDialog: false,
    addCharmGradeRow: null,
    showAddCharmGradeDialog: false,
    charmGradePageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 魅力等级 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var gradeParam = {
      type : 5,
      pageParam : pageParam,
    }
    gradeService.getGradePage(gradeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({charmGradeList: res.retValue.list, charmGradeTotal: res.retValue.total});
        }    
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 魅力等级 ————————————————————————————


  // 用户分页
  charmGradeChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          charmGradePageParam: pageParam,
        });

        var gradeParam = {
          type : 5,
          pageParam : pageParam,
        }
        gradeService.getGradePage(gradeParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({charmGradeList: res.retValue.list, charmGradeTotal: res.retValue.total});
            }    
          }
        )
    })
  }

  // 刷新数据
  refreshCharmGradeData=() => {
    let { history } = this.props;
    //console.log("refreshCharmGradeData");

    var gradeParam = {
      type : 5,
      pageParam : this.state.charmGradePageParam,
    }
    gradeService.getGradePage(gradeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({charmGradeList: res.retValue.list, charmGradeTotal: res.retValue.total});
        }    
      }
    )
  }

  // 隐藏分成方案弹窗
  hideUpdateCharmGradeDialog=() => {
    this.setState({showUpdateCharmGradeDialog: false});
  }

  // 隐藏分成方案弹窗
  hideAddCharmGradeDialog=() => {
    this.setState({showAddCharmGradeDialog: false});
  }
  


 
  render() {
    return (
      <div className="admin-sitemgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/charm_level">魅力等级</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="recordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddCharmGradeDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.charmGradeList}
                columns={this.state.charmGradeColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.charmGradeTotal, defaultCurrent: 1, onChange: this.charmGradeChangePage}}
              >
              </Table>
              <AddCharmGrade close={this.hideAddCharmGradeDialog} visible={this.state.showAddCharmGradeDialog} refreshData={this.refreshCharmGradeData}></AddCharmGrade>
              <UpdateCharmGrade rowData={this.state.updateCharmGradeRow} close={this.hideUpdateCharmGradeDialog} visible={this.state.showUpdateCharmGradeDialog} refreshData={this.refreshCharmGradeData}></UpdateCharmGrade>

        </StickyContainer>
        
      </div>
    );
  }
}
export default UserReports;