import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取等级
   * @param {*} gradePrivilegeParam
   * @returns
   */
   getGradePrivilegePage(gradePrivilegeParam) {
      //console.log("gradePrivilegeParam=" + JSON.stringify(gradePrivilegeParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradePrivilegeAdmin/getGradePrivilegePage",
        data: gradePrivilegeParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 更新举报信息
   * @param {*} userAppeal
   * @returns
   */
   updateGradePrivilege(appGradePrivilege) {
        //console.log("appGradePrivilege=" + JSON.stringify(appGradePrivilege));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradePrivilegeAdmin/updateGradePrivilege",
        data: appGradePrivilege,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}